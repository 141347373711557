import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Route } from "react-router-dom";

import EventComponent from '../eventcomponent/EventComponent';
import EventComponentStore from '../eventcomponent/EventComponentStore';
import { liveMatchRefreshTime } from '../../sharedfiles/helper';
import dataLoader from '../../assets/images/loader-orange.svg';

const eventComponentStore = new EventComponentStore();
class Highlights extends Component {
  componentWillMount() {
    this.props.highlightsStore.pageId = this.props.pageId;
    this.props.highlightsStore.getHighlightsSports();
    this.props.highlightsStore.mounted = true;
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.isLoggedIn !== nextProps.isLoggedIn) {
      this.props.highlightsStore.getHighlightsSports();
    }
  }
  componentDidMount() {
    var self = this;
    setTimeout(function reCallService() {
      if (self.props.highlightsStore.mounted) {
        self.props.highlightsStore.getHighlightsSports();
        setTimeout(reCallService, liveMatchRefreshTime);
      }
    }, liveMatchRefreshTime);
  }
  componentWillUnmount() {
    this.props.highlightsStore.mounted = false;
    this.props.highlightsStore.resetData();
  }
  render() {
    const { highlightsStore, appStore, betSlipStatusChange, componentType } = this.props;
    return (
      <div className="RDE-highlightsSection">
        <div className="RDE-sportsList">
          <ul className="">
            {
              highlightsStore.sports.map((sport, index) => {
                return <li key={"high_sport_" + index} onClick={highlightsStore.selectSport.bind(highlightsStore, sport)}>
                  <div className={highlightsStore.selectedSport.id === sport.id ? "RDE-sportsList-item active" : "RDE-sportsList-item"}>
                    <i className={"RDE-icon-" + sport.name.toLowerCase().replace(" ", "-")}></i>
                    <div className="RDE-sportsList-item__details"><span
                      className="RDE-sportsList-item__sportName">{sport.name}</span> </div>
                  </div>
                </li>
              })
            }
          </ul>
        </div>
        <div className="RDE-singleLineEventMarketHeader">
          <div className="RDE-singleLineEventMarketHeader__left">
            <div className="RDE-singleLineEventMarketHeader-title">
              {highlightsStore.selectedSport.name}
            </div>
          </div>
          <div className="RDE-singleLineEventMarketHeader__right">
            <div className="RDE-singleLineEventMarketHeader__marketSection">
              {!highlightsStore.showErrorMsg && !highlightsStore.showLoader ? <ul>
                {highlightsStore.selections.map((selection, index) => {
                  return <li key={"selection_header_" + index}>
                    <div className="RDE-singleLineEventMarketHeader__marketItem">{selection.name}</div>
                  </li>
                })}
              </ul> : ''}
            </div>
          </div>
        </div>
        <div className="RDE-eventItemGroup">
          {
            highlightsStore.highlightsData.map((event, index) => {
              if (index < highlightsStore.itemsShow) {
                return <Route key={"event_highlightshome_" + index + "_" + highlightsStore.selectedSport.id} render={(props) => <EventComponent {...props} event={event}
                  appStore={appStore}
                  eventComponentStore={eventComponentStore}
                  componentType={componentType}
                  sportId={highlightsStore.selectedSport.id}
                  sportName={highlightsStore.selectedSport.name}
                  betSlipStatusChange={betSlipStatusChange} />} />
              }
              return ''
            })
          }
          {/*<div className="RDE-singleLineEventItem">
                  <div className="RDE-singleLineEventItem__eventDetails">
                    <div className="RDE-singleLineEventItem__scoreAndKickOff">
                      <div className="RDE-singleLineEventItem__liveScore">0 - 1</div>
                      <div className="RDE-singleLineEventItem__pin">
                        <i className="RDE-icon-pin"></i>
                      </div>
                    </div>
                    <div className="RDE-singleLineEventItem__fixtureDetails">
                      <div className="RDE-singleLineEventItem__fixture">Hull City v Leicester City
                        Hull City v Leicester City</div>
                      <div className="RDE-singleLineEventItem__matchMinute">
                        <div>21'</div>
                      </div>

                    </div>
                  </div>
                  <div className="RDE-singleLineEventItem__outcomeDetails">
                    <ul>
                      <li>
                        <div className="RDE-backAndLayOddsGroup">
                          <button
                            className="btn RDE-btnOdds RDE-btnBackOdds RDE-btnOddsDisabled">4.60</button>
                          <button
                            className="btn RDE-btnOdds RDE-btnLayOdds RDE-btnOddsDisabled">4.60</button>
                        </div>
                      </li>
                      <li>
                        <div className="RDE-backAndLayOddsGroup">
                          <button className="btn RDE-btnOdds RDE-btnBackOdds">4.60</button>
                          <button className="btn RDE-btnOdds RDE-btnLayOdds">4.60</button>
                        </div>
                      </li>
                      <li>
                        <div className="RDE-backAndLayOddsGroup">
                          <button className="btn RDE-btnOdds RDE-btnBackOdds">4.60</button>
                          <button className="btn RDE-btnOdds RDE-btnLayOdds">4.60</button>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="RDE-singleLineEventItem">
                  <div className="RDE-singleLineEventItem__eventDetails">
                    <div className="RDE-singleLineEventItem__scoreAndKickOff">
                      <span>Tomorrow 21:30</span>
                      <div className="RDE-singleLineEventItem__pin active">
                        <i className="RDE-icon-pin"></i>
                      </div>
                    </div>
                    <div className="RDE-singleLineEventItem__fixtureDetails">
                      <div className="RDE-singleLineEventItem__fixture">Hull City v Leicester City
                      </div>
                      <div className="RDE-singleLineEventItem__fancy">FANCY</div>
                    </div>
                  </div>
                  <div className="RDE-singleLineEventItem__outcomeDetails">
                    <ul>
                      <li>
                        <div className="RDE-backAndLayOddsGroup">
                          <button className="btn RDE-btnOdds RDE-btnBackOdds active">4.60</button>
                          <button className="btn RDE-btnOdds RDE-btnLayOdds">4.60</button>
                        </div>
                      </li>
                      <li>
                        <div className="RDE-btnOddsSuspended">
                          <button className="btn RDE-btnOdds">Suspended</button>
                        </div>
                      </li>
                      <li>
                        <div className="RDE-backAndLayOddsGroup">
                          <button className="btn RDE-btnOdds RDE-btnBackOdds">4.60</button>
                          <button className="btn RDE-btnOdds RDE-btnLayOdds">4.60</button>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="RDE-singleLineEventItem">
                  <div className="RDE-singleLineEventItem__eventDetails">
                    <div className="RDE-singleLineEventItem__scoreAndKickOff">
                      <div className="RDE-singleLineEventItem__liveScore RDE-cricketScore"><span
                          className="RDE-cricketScore__liveScore"> NZ 265/2 </span> <span
                          className="RDE-cricketScore__overs">(19.4)</span></div>
                      <div className="RDE-singleLineEventItem__pin">
                        <i className="RDE-icon-pin"></i>
                      </div>
                    </div>
                    <div className="RDE-singleLineEventItem__fixtureDetails">
                      <div className="RDE-singleLineEventItem__fixture">New Zealand v India </div>
                    </div>
                  </div>
                  <div className="RDE-singleLineEventItem__outcomeDetails">
                    <ul>
                      <li>
                        <div className="RDE-backAndLayOddsGroup">
                          <button className="btn RDE-btnOdds RDE-btnBackOdds">4.60</button>
                          <button className="btn RDE-btnOdds RDE-btnLayOdds">4.60</button>
                        </div>
                      </li>
                      <li>
                        <div className="RDE-backAndLayOddsGroup">
                          <button className="btn RDE-btnOdds RDE-btnBackOdds">4.60</button>
                          <button className="btn RDE-btnOdds RDE-btnLayOdds">4.60</button>
                        </div>
                      </li>
                      <li>
                        <div className="RDE-backAndLayOddsGroup">
                          <button className="btn RDE-btnOdds RDE-btnBackOdds">4.60</button>
                          <button className="btn RDE-btnOdds RDE-btnLayOdds">4.60</button>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="RDE-singleLineEventItem">
                  <div className="RDE-singleLineEventItem__eventDetails">
                    <div className="RDE-singleLineEventItem__scoreAndKickOff">
                      <div className="RDE-singleLineEventItem__liveScore">0 - 1</div>
                      <div className="RDE-singleLineEventItem__pin">
                        <i className="RDE-icon-pin"></i>
                      </div>
                    </div>
                    <div className="RDE-singleLineEventItem__fixtureDetails">
                      <div className="RDE-singleLineEventItem__fixture">
                        <span className="RDE-playerName">Brydan Klein </span>
                        <span>v</span>
                        <span className="RDE-playerName RDE-servingAfter"> Maverick Banes</span>
                      </div>
                      <div className="RDE-singleLineEventItem__tennisLiveSecondScore">
                        <div>1st set <span className="RDE-score">0-0</span> <span
                            className="RDE-points">A-40</span></div>
                      </div>
                      <div className="RDE-singleLineEventItem__fancy">FANCY</div>
                    </div>
                  </div>
                  <div className="RDE-singleLineEventItem__outcomeDetails">
                    <ul>
                      <li>
                        <div className="RDE-backAndLayOddsGroup">
                          <button className="btn RDE-btnOdds RDE-btnBackOdds">4.60</button>
                          <button className="btn RDE-btnOdds RDE-btnLayOdds">4.60</button>
                        </div>
                      </li>
                      <li>
                        <div className="RDE-backAndLayOddsGroup">
                          <button className="btn RDE-btnOdds RDE-btnBackOdds">4.60</button>
                          <button className="btn RDE-btnOdds RDE-btnLayOdds">4.60</button>
                        </div>
                      </li>
                      <li>
                        <div className="RDE-backAndLayOddsGroup">
                          <button className="btn RDE-btnOdds RDE-btnBackOdds">4.60</button>
                          <button className="btn RDE-btnOdds RDE-btnLayOdds">4.60</button>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="RDE-singleLineEventItem">
                  <div className="RDE-singleLineEventItem__eventDetails">
                    <div className="RDE-singleLineEventItem__scoreAndKickOff">
                      <span>09-02-2019 00:30</span>
                      <div className="RDE-singleLineEventItem__pin">
                        <i className="RDE-icon-pin"></i>
                      </div>
                    </div>
                    <div className="RDE-singleLineEventItem__fixtureDetails">
                      <div className="RDE-singleLineEventItem__fixture">Hull City v Leicester City
                      </div>
                      <div className="RDE-singleLineEventItem__fancy">FANCY</div>
                    </div>
                  </div>
                  <div className="RDE-singleLineEventItem__outcomeDetails">
                    <ul>
                      <li>
                        <div className="RDE-backAndLayOddsGroup">
                          <button className="btn RDE-btnOdds RDE-btnBackOdds">4.60</button>
                          <button className="btn RDE-btnOdds RDE-btnLayOdds">4.60</button>
                        </div>
                      </li>
                      <li>
                        <div className="RDE-backAndLayOddsGroup">
                          <button className="btn RDE-btnOdds RDE-btnBackOdds">4.60</button>
                          <button className="btn RDE-btnOdds RDE-btnLayOdds">4.60</button>
                        </div>
                      </li>
                      <li>
                        <div className="RDE-backAndLayOddsGroup">
                          <button className="btn RDE-btnOdds RDE-btnBackOdds">4.60</button>
                          <button className="btn RDE-btnOdds RDE-btnLayOdds">4.60</button>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>*/}
        </div>
        {highlightsStore.showErrorMsg ? <div className="RDE-noDataMessageBox">
          Currently data is not available for this Sport
        </div> : ''}
        {highlightsStore.showLoader ? <div className="RDE-loader">
          <img src={dataLoader} alt="loading...!" />
        </div> : ''}

        {highlightsStore.highlightsData.length > highlightsStore.initialItemShow ? <div className="RDE-showMore">
          {highlightsStore.itemsShow < highlightsStore.highlightsData.length ? <div onClick={highlightsStore.showMore.bind(highlightsStore)}><span>Show more </span><i className="RDE-icon-down-arrow"></i></div> :
            <div onClick={highlightsStore.showLess.bind(highlightsStore)}><span>Show less </span><i className="RDE-icon-up-arrow"></i></div>}
        </div> : ''}
      </div>

    )
  }
}
export default observer(Highlights);