import React, { Component } from 'react';
import { observer } from 'mobx-react';

import Banner from "./banner/Banner";
import BannerStore from "./banner/BannerStore";
import HighlightsUpcoming from "./highlightsupcoming/HighlightsUpcoming";
import HighlightsUpcomingStore from "./highlightsupcoming/HighlightsUpcomingStore";

const bannerStore = new BannerStore();
const highlightsUpcomingStore = new HighlightsUpcomingStore();
class HomepageLayout extends Component {
  componentWillMount() {
    this.props.appStore.currentPath = "home";
    this.props.appStore.clearBetslip();
  }
  componentDidMount() {

  }

  componentWillUnmount() {
  }

  onInitialFetch(groups) { }

  componentWillReceiveProps(nextProps) {
  }

  render() {
    const { appStore } = this.props;
    return (
      <div className="RDE-mainContent">
        <Banner bannerStore={bannerStore} appStore={appStore} pageId={1} isLoggedIn={appStore.isLoggedIn} />
        <HighlightsUpcoming highlightsUpcomingStore={highlightsUpcomingStore} appStore={appStore}
          isLoggedIn={appStore.isLoggedIn}
          betSlipStatusChange={appStore.betSlipStatusChange} />
      </div>
    );
  }
}

export default observer(HomepageLayout);
