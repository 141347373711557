import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Route, NavLink, Switch } from "react-router-dom";
import cookie from 'react-cookies';

import MyProfile from './myprofile/MyProfile';
import MyProfileStore from './myprofile/MyProfileStore';
import ChangePassword from './changepassword/ChangePassword';
import ChangePasswordStore from './changepassword/ChangePasswordStore';
import BalanceOverview from './balanceoverview/BalanceOverview';
import BalanceOverviewStore from './balanceoverview/BalanceOverviewStore';
import AccountStatement from './accountstatement/AccountStatement';
import AccountStatementStore from './accountstatement/AccountStatementStore';
import MyBets from './mybets/MyBets';
import MyBetsStore from './mybets/MyBetsStore';

const myProfileStore = new MyProfileStore();
const changePasswordStore = new ChangePasswordStore();
const balanceOverviewStore = new BalanceOverviewStore();
const accountStatementStore = new AccountStatementStore();
const myBetsStore = new MyBetsStore();

class Account extends Component {
  componentWillMount() {
    this.props.appStore.currentPath = 'profile';
    if (!this.props.appStore.isLoggedIn || cookie.load('UTocken') === null) {
      window.location.href = '/';
    }
  }
  componentWillReceiveProps(nextProps) { }
  render() {
    const { appStore } = this.props
    return (
      <div className="RDE-dashboard-container">
        <div className="RDE-dashboard-content">
          <div className="RDE-dashboard-leftSideBar">
            <div className="RDE-DB-leftSideBar-title">My Account</div>
            <ul className="RDE-DB-leftMenu">
              <li><NavLink className='no-link' to={'/myaccount/myprofile'} activeClassName="active">My Profile</NavLink></li>
              <li><NavLink className='no-link' to={'/myaccount/changepassword'} activeClassName="active">Change Password</NavLink></li>
              {/* <li><NavLink  className='no-link' to={'/myaccount/balance'} activeClassName="active">Balance Overview</NavLink></li> */}
              <li><NavLink className='no-link' to={'/myaccount/account'} activeClassName="active">Account Statement</NavLink></li>
              <li><NavLink className='no-link' to={'/myaccount/mybets'} activeClassName="active">My Bets</NavLink></li>
            </ul>
          </div>
          <div className="RDE-dashboard-mainContent">
            <Switch>
              <Route exact path="/myaccount/myprofile" render={(props) => <MyProfile {...props}
                appStore={appStore}
                myProfileStore={myProfileStore} />} />
              <Route exact path="/myaccount/changepassword" render={(props) => <ChangePassword {...props}
                appStore={appStore}
                changePasswordStore={changePasswordStore} />} />
              <Route exact path="/myaccount/balance" render={(props) => <BalanceOverview {...props}
                appStore={appStore}
                balanceOverviewStore={balanceOverviewStore} />} />
              <Route exact path="/myaccount/account" render={(props) => <AccountStatement {...props}
                appStore={appStore}
                accountStatementStore={accountStatementStore} />} />
              <Route path="/myaccount/mybets" render={(props) => <MyBets {...props}
                appStore={appStore}
                myBetsStore={myBetsStore} />} />
            </Switch>
          </div>
        </div>
      </div>
    )
  }
}
export default observer(Account);
