import { extendObservable } from "mobx";

class MyBetsStore {
  constructor(props) {
    extendObservable(this, {
      stakes: [],
      fromDate: new Date()
    });
  }
  /**
  *	This method is called when user selects the date.
  */
  selectDate(selectedDate) {
    this.fromDate = selectedDate;
  }
}

export default MyBetsStore;
