import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Route, Switch } from "react-router-dom";
import { BrowserRouter as Router } from 'react-router-dom';

import MultiViewComponent from './multiviewcomponent/MultiViewComponent';
import MultiViewComponentStore from './multiviewcomponent/MultiViewComponentStore';

const multiViewComponentStore = new MultiViewComponentStore();
class MultiViewLayout extends Component {
  componentWillMount() {
    this.props.appStore.currentPath = "multimarkets";
    this.props.appStore.clearBetslip();
  }
  componentDidMount() {

  }

  componentWillUnmount() {
  }

  onInitialFetch(groups) { }

  componentWillReceiveProps(nextProps) {
  }

  render() {
    const { appStore, isLoggedIn, betslipComponentStore } = this.props;
    var betSlipStatusChange = appStore.betSlipStatusChange;
    return (
      <Router>
        <Switch>
          <Route path="/multimarkets" exact render={(props) => <MultiViewComponent {...props}
            appStore={appStore}
            isLoggedIn={isLoggedIn}
            betslipComponentStore={betslipComponentStore}
            pLStatusChanged={appStore.pLStatusChanged}
            betSlipStatusChange={betSlipStatusChange}
            multiViewComponentStore={multiViewComponentStore} />} />
        </Switch>
      </Router>
    );
  }
}

export default observer(MultiViewLayout);
