import { extendObservable } from "mobx";
import { number } from "prop-types";

const re = /^\d+\.?\d{0,2}$/;
class BetSlipEventComponentStore {
  constructor(props) {
    extendObservable(this, {
      data: [],
      appStore:{},
      userOdds:'',
      userStake:"",
      returns:"0.00",
      stakeCoins:[],
      appStore:{}
    });
  }
  /**
  * This method is called when user changes the odds in betslip
  * @param {JSObject} e - this variable contains the JS element values.
  * @param {Object} betslipComponentStore- conibtains betslip component store
  * @param {object} item- contains the selection information.
  */
  oddsChanged(item, betslipComponentStore, e) {
    item.isOddsChange = true;
    if ((re.test(e.target.value) && e.target.value >= 1 && e.target.value <= 1000) || e.target.value === "") {
       item.userOdds = this.checkEnteredOdds(e.target.value);
	      this.calculateReturns(item);
	      betslipComponentStore.calStakeReturns();
        this.appStore.calProfitLoss();
	   }
  }

  checkEnteredOdds(odds) {
    var enteredOdds = odds;
    var stepResult = odds;
    if (Number(enteredOdds) >= 1 && Number(enteredOdds) < 2) {
      //incRange = 0.01;
      stepResult=this.checkStepByRange(enteredOdds,0.01);
    } else if (Number(enteredOdds) >= 2 && Number(enteredOdds) < 3) {
      //incRange = 0.02;
      stepResult=this.checkStepByRange(enteredOdds,0.02);
    } else if (Number(enteredOdds) >= 3 && Number(enteredOdds) < 4) {
      //incRange = 0.05;
      stepResult=this.checkStepByRange(enteredOdds,0.05);
    } else if (Number(enteredOdds) >= 4 && Number(enteredOdds) < 6) {
      //incRange = 0.10;
      stepResult=this.checkStepByRange(enteredOdds,0.10);
    } else if (Number(enteredOdds) >= 6 && Number(enteredOdds) < 10) {
      //incRange = 0.20;
      stepResult=this.checkStepByRange(enteredOdds,0.20);
    } else if (Number(enteredOdds) >= 10 && Number(enteredOdds) < 20) {
      //incRange = 0.50;
      stepResult=this.checkStepByRange(enteredOdds,0.50);
    } else if (Number(enteredOdds) >= 20 && Number(enteredOdds) < 30) {
      //incRange = 1.00;
      stepResult=this.checkStepByRange(enteredOdds,1.00);
    } else if (Number(enteredOdds) >= 30 && Number(enteredOdds) < 50) {
      //incRange = 2.00;
      stepResult=this.checkStepByRange(enteredOdds,2.00);
    } else if (Number(enteredOdds) >= 50 && Number(enteredOdds) < 100) {
      //incRange = 5.00;
      stepResult=this.checkStepByRange(enteredOdds,5.00);
    } else if (Number(enteredOdds) >= 100) {
      //incRange = 10.00;
      stepResult=this.checkStepByRange(enteredOdds,0.02);
    }
    return stepResult;
  }
  checkStepByRange(enteredOdds,stepValue) {
    var stepResult = enteredOdds;
    var filteredOdds = (Number(enteredOdds)*100).toFixed(2);
     var oddsDividedByStepRange = filteredOdds/(Number(stepValue)*100).toFixed(2);
      if (!Number.isInteger(oddsDividedByStepRange)) {
        // while (stepStart, stepEnd) {
        //   if (enteredOdds < stepStart) {
        //     stepResult = (stepStart - stepValue).toFixed(2);
        //     break;
        //   }
        //   stepStart += stepValue;
        // }
        //debugger;
        stepResult = (Math.trunc(oddsDividedByStepRange)*stepValue).toFixed(2);
    }
    return stepResult;
  }
  stakeChanged(selection,betslipComponentStore,e){
    this.stakeChange(selection,betslipComponentStore,e.target.value);
    this.appStore.calProfitLoss();
  }
  /**
  * This method is called when user changes the odds in betslip
  * @param {JSObject} e - this variable contains the JS element values.
  */
  stakeChange(selection,betslipComponentStore,stake){
      var minStake='';
      var maxStake='';
      if(selection.minStake>0){
      		minStake=selection.minStake
      }else{
      	minStake = 1;
      }
      if(selection.maxStake>0){
      		maxStake=selection.maxStake;
      }else{
        maxStake = 100000000;//100000;
      }
       if((re.test(stake) && Number(stake) >= 1)|| stake===""){
          //Number(stake) < minStake ||
          if(( Number(stake) > maxStake)){
            selection.stakeError = "Minimum stake: "+minStake+" and maximum stake:"+maxStake;
            //betslipComponentStore.isValidBets=false;
          }else{
            if(Number(stake) < minStake || stake==="" ){
              selection.stakeError = "Minimum stake: "+minStake+" and maximum stake:"+maxStake;
              betslipComponentStore.isValidBets=false;
            }else{
              selection.stakeError = '';
            }
            selection.userStake = stake;
            
            if(selection.isFancy){
              this.calculateFancyReturns(selection);
            }else{
              this.calculateReturns(selection);
            }
            betslipComponentStore.calStakeReturns();

          }
       }
       //this.appStore.updateBetSlipStatus();
       
  }
  /**
  * This method is called when user clicks on increase odds arrow.
  *
  */
  oddsInc(selection, betslipComponentStore) {
    selection.isOddsChange = true;
      var incRange="";
      
        if(Number(selection.userOdds)>=1 && Number(selection.userOdds)<2){
           incRange = 0.01;
        }else if(Number(selection.userOdds)>=2 && Number(selection.userOdds)<3){
          incRange = 0.02;
        }else if(Number(selection.userOdds)>=3 && Number(selection.userOdds)<4){
          incRange = 0.05;
        }else if(Number(selection.userOdds)>=4 && Number(selection.userOdds)<6){
          incRange = 0.10;
        }else if(Number(selection.userOdds)>=6 && Number(selection.userOdds)<10){
          incRange = 0.20;
        }else if(Number(selection.userOdds)>=10 && Number(selection.userOdds)<20){
          incRange = 0.50;
        }else if(Number(selection.userOdds)>=20 && Number(selection.userOdds)<30){
          incRange = 1.00;
        }else if(Number(selection.userOdds)>=30 && Number(selection.userOdds)<50){
          incRange = 2.00;
        }else if(Number(selection.userOdds)>=50 && Number(selection.userOdds)<100){
          incRange = 5.00;
        }else if(Number(selection.userOdds)>=100){
          incRange = 10.00;
        }
      
      selection.userOdds = (Number(selection.userOdds) + incRange).toFixed(2);
      if(selection.isFancy){
        this.calculateFancyReturns(selection);
      }else{
        this.calculateReturns(selection);
      }
      betslipComponentStore.calStakeReturns();
      this.appStore.calProfitLoss();
  }
  /**
  * This method is called when user clicks on decrease odds arrow.
  *
  */
  oddsDec(selection, betslipComponentStore) {
    selection.isOddsChange = true;
       if(selection.userOdds>1.01){
        var incRange="";
      
        if(Number(selection.userOdds)>=1 && Number(selection.userOdds)<=2){
           incRange = 0.01;
        }else if(Number(selection.userOdds)>2 && Number(selection.userOdds)<=3){
          incRange = 0.02;
        }else if(Number(selection.userOdds)>3 && Number(selection.userOdds)<=4){
          incRange = 0.05;
        }else if(Number(selection.userOdds)>4 && Number(selection.userOdds)<=6){
          incRange = 0.10;
        }else if(Number(selection.userOdds)>6 && Number(selection.userOdds)<=10){
          incRange = 0.20;
        }else if(Number(selection.userOdds)>10 && Number(selection.userOdds)<=20){
          incRange = 0.50;
        }else if(Number(selection.userOdds)>20 && Number(selection.userOdds)<=30){
          incRange = 1.00;
        }else if(Number(selection.userOdds)>30 && Number(selection.userOdds)<=50){
          incRange = 2.00;
        }else if(Number(selection.userOdds)>50 && Number(selection.userOdds)<=100){
          incRange = 5.00;
        }else if(Number(selection.userOdds)>100){
          incRange = 10.00;
        }
       	selection.userOdds = (Number(selection.userOdds) - incRange).toFixed(2);
       	if(selection.isFancy){
          this.calculateFancyReturns(selection);
        }else{
          this.calculateReturns(selection);
        }
       	betslipComponentStore.calStakeReturns();
        this.appStore.calProfitLoss();
       }
  }
  /**
  * This method is used to calculate the returns
  *
  */
  calculateReturns(selection){
		if(selection.userOdds && selection.userOdds>0 && selection.userStake && selection.userStake>0){
			selection.returns = (Number(selection.userOdds) * Number(selection.userStake)).toFixed(2);
		}else{
			selection.returns = "0.00"
		}
  }
  /**
  * This method is used to calculate the fancy bets returns
  *
  */
  calculateFancyReturns(selection){
     // console.log('calculate fancy returns===',selection);
      if(selection.userOdds && selection.userOdds>0 && selection.userStake && selection.userStake>0){
        var returns = (Number(selection.userOdds) * Number(selection.userStake))/100;
        var totalReturns= returns + Number(selection.userStake);
        selection.returns = totalReturns.toFixed(2);
      }else{
        selection.returns = "0.00"
      }
  }
  /**
  * This method is called when user selected stake from stake coins.
  */
  stakeSelected(stake,selection,betslipComponentStore){
  	if(stake && stake>0){
      this.stakeChange(selection,betslipComponentStore,stake);
      this.appStore.calProfitLoss();
  		// selection.userStake = stake;
  		// if(selection.isFancy){
    //     this.calculateFancyReturns(selection);
    //   }else{
    //     this.calculateReturns(selection);
    //   }
  		// betslipComponentStore.calStakeReturns();
  	}
  }

}

export default BetSlipEventComponentStore;
