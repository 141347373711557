import { extendObservable } from "mobx";

import { masterPage } from "../../sharedfiles/EndpointConfig";
import { apiCaller } from '../../sharedfiles/apiStore';

class FooterStore {
  constructor(props) {
    extendObservable(this, {
      data: [],
      communicationDetails:[],
      logoAndLicensingInformation:[],
      points:[]
    });
  }
  /**
  *
  *
  */
  getFooterData(){
   	apiCaller(masterPage.getFooter,'GET').then(response => {
  	  if(response && response.data && response.data.hasOwnProperty('CommunicationDetails') &&
  	     response.data.CommunicationDetails && response.data.CommunicationDetails.length>0){
  	      this.communicationDetails = response.data.CommunicationDetails;
  	  }
      if(response && response.data && response.data.hasOwnProperty('LogoAndLicensingInformation') &&
         response.data.LogoAndLicensingInformation && response.data.LogoAndLicensingInformation.length>0){
          this.logoAndLicensingInformation = response.data.LogoAndLicensingInformation[0];
      }
      if(response && response.data && response.data.hasOwnProperty('Points') &&
         response.data.Points && response.data.Points.length>0){
          this.points = response.data.Points;
      }
    });
  }
}

export default FooterStore;
