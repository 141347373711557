import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Route, Switch } from "react-router-dom";

import Sportspage from './Sportspage';
import Leaguepage from './Leaguepage';
import EventView from '../../sharedCcomponents/eventview/EventView';
import EventViewStore from '../../sharedCcomponents/eventview/EventViewStore';

const eventViewStore = new EventViewStore();
class SportspageLayout extends Component {
  componentWillMount() {
    this.props.appStore.currentPath = "sports";
  }
  componentDidMount() {

  }

  componentWillUnmount() {
  }

  onInitialFetch(groups) { }

  componentWillReceiveProps(nextProps) {
  }

  render() {
    const { appStore, betslipComponentStore, pLStatusChanged } = this.props;
    var betSlipStatusChange = appStore.betSlipStatusChange;
    var plStatus = pLStatusChanged;
    return (
      <Switch>
        <Route path="/sports/:sportName/:sportId/:tab?" exact render={(props) => <Sportspage {...props} appStore={appStore}
          pageId={1}
          isLoggedIn={appStore.isLoggedIn} />} />
        <Route path="/sports/:sportName/:sportId/league/:leagueId/:leagueName" exact render={(props) => <Leaguepage {...props}
          appStore={appStore}
          isLoggedIn={appStore.isLoggedIn} />} />
        <Route path="/sports/:sportName/:sportId/event/:eventId" exact render={(props) => <EventView {...props} appStore={appStore}
          eventViewStore={eventViewStore}
          eventId={appStore.eventViewId}
          isLoggedIn={appStore.isLoggedIn}
          pLStatusChanged={plStatus}
          betSlipStatusChange={betSlipStatusChange}
          betslipComponentStore={betslipComponentStore} />} />
      </Switch>
    );
  }
}

export default observer(SportspageLayout);
