import React, { Component } from 'react';
import { Route, NavLink, Switch, withRouter } from "react-router-dom";
import { BrowserRouter as Router } from 'react-router-dom';
// import './assets/css/RD-Exchange-styles.scss';
import './assets/css/RD-Exchange-styles-blue-theme.scss';
import { Modal, DropdownButton, Dropdown, Collapse } from "react-bootstrap";
import { observer } from 'mobx-react';
import IdleTimer from 'react-idle-timer';
import cookie from 'react-cookies';

// import history from './history';
import HomepageLayout from "./components/homepage/HomepageLayout";
import InplaypageLayout from "./components/inplaypage/InplaypageLayout";
import SportspageLayout from "./components/sportspage/SportspageLayout";
import MultiViewLayout from "./components/multiview/MultiViewLayout";
// import logo from './assets/images/RD-Exchange-logo.png';
//import logo from './assets/images/american-exchange-logo.svg';
import logo from './assets/images/playbig-exchange-logo.svg';
import LoginSignup from "./sharedCcomponents/loginsignup/LoginSignup";
import LoginSignupStore from "./sharedCcomponents/loginsignup/LoginSignupStore";
// import MainHeader from "./sharedCcomponents/mainheader/MainHeader";
// import MainHeaderStore from "./sharedCcomponents/mainheader/MainHeaderStore";
import SubHeader from "./sharedCcomponents/subheader/SubHeader";
import SubHeaderStore from "./sharedCcomponents/subheader/SubHeaderStore";
import Footer from "./sharedCcomponents/footer/Footer";
import FooterStore from "./sharedCcomponents/footer/FooterStore";
import BetSlipComponent from "./sharedCcomponents/betslipcomponent/BetSlipComponent";
import BetSlipComponentStore from "./sharedCcomponents/betslipcomponent/BetSlipComponentStore";
import Account from "./components/profile/account/Account";
import AccountStore from "./components/profile/account/AccountStore";
import Election from "./components/election/Election";
import ElectionStore from "./components/election/ElectionStore";
import ForgotPassword from "./components/forgotpassword/ForgotPassword";
import ForgotPasswordStore from "./components/forgotpassword/ForgotPasswordStore";
import Games from "./components/games/Games";
import PrivacyPolicy from "./components/privacypolicy/PrivacyPolicy";
import RulesRegulations from "./components/rulesregulations/RulesRegulations";
import TermsConditions from "./components/termscondition/TermsConditions";
// import AutoCompleteSearch from "./sharedfiles/autocompletesearch";
import popUpClose from './assets/images/popup-close.svg';
import dataLoader from './assets/images/loader-orange.svg';
import SettingsStore from './sharedCcomponents/subheader/settings/SettingsStore';

//const mainHeaderStore = new MainHeaderStore();
const loginSignupStore = new LoginSignupStore();
const subHeaderStore = new SubHeaderStore();
const footerStore = new FooterStore();
const betslipComponentStore = new BetSlipComponentStore();
const accountStore = new AccountStore();
const electionStore = new ElectionStore();
const forgotPasswordStore = new ForgotPasswordStore();
const settingsStore = new SettingsStore();

class App extends Component {
  constructor(props) {
    super(props)
    this.idleTimer = null
    this.onIdle = this._onIdle.bind(this)
    this.searchValue = '';
  }
  componentWillMount() {
    this.props.appStore.mounted = true;

    if (cookie.load('UTocken') && cookie.load('UTocken').length > 0) {
      this.props.appStore.isLoggedIn = true;
      this.props.appStore.showLiveStreaming = true;
      this.props.appStore.getUserMenu();
      this.props.appStore.getUserBalanceDetails();
      this.props.appStore.getBetsProfitLoss();

    } else {
      if (!window.location.href.includes('update_password')) {
        this.props.history.push('/login');
      }

    }
    if (cookie.load('userInfo')) {
      this.props.appStore.userInfo = cookie.load('userInfo');
    }
    this.props.appStore.betSlipStore = betslipComponentStore;
    this.props.appStore.getHeaderData();
    this.props.appStore.getLeftMenuData();
    this.props.appStore.appProps = this.props;
    if (this.props.location && this.props.location.pathname.includes("privacy-policy") ||
      this.props.location.pathname.includes("rules-and-regulations") ||
      this.props.location.pathname.includes("terms-and-conditions") ||
      this.props.location.pathname.includes("reset")) {
      this.props.appStore.showTC = true
    }
  }
  componentDidMount() {
    var self = this;
    setTimeout(function reCallService() {
      if (self.props.appStore.mounted) {
        self.props.appStore.getBetsProfitLoss();
        setTimeout(reCallService, 6000);
      }
    }, 6000);
    setTimeout(function reCallService() {
      if (self.props.appStore.mounted) {
        if (self.props.appStore.isLoggedIn) {
          self.props.appStore.getUserBalanceDetails();
        }
        setTimeout(reCallService, 3000);
      }
    }, 3000);
    // var api = new JsonRpcClient({
    //   endpoint: 'https://api.betfair.com/betting/json-rpc',
    //   headers: {
    //     'X-Application': 'JrRjbg2G9zgKZD94',
    //     'X-Authentication' : 'HzaltPCHZe9MV2kPrmM5ZRykZbXAf/mmQaT+aEZc+iI=',
    //     'content-type' : 'application/json'
    //   }
    // })
    // api.request(
    //   "SportsAPING/v1.0/listMarketBook",
    //   {
    //     "marketIds": ["1.127771425"],
    //     "priceProjection": {
    //         "priceData": ["EX_BEST_OFFERS", "EX_TRADED"],
    //         "virtualise": "true"
    //     }
    //   }
    // ).then(function(response) {
    //     this.setState({apiResponse: response})
    //   }.bind(this)) // End .then()
  } // End componentDidMount
  componentWillUnmount() {
    this.props.appStore.mounted = false;
  }
  _onIdle(e) {
    if (this.props.appStore.isLoggedIn) {
      this.props.appStore.showLogoutPopup = true;
      //this.props.appStore.logout();
    }
  }
  selectSport(sportName, sportId) {
    this.sportName = sportName;
    this.sportId = sportId;
  }
  navigateEventView(eventId) {
    // this.props.sportId = this.props.sportId;
    // this.props.sportName = this.props.sportName;
    // this.props.eventViewId = this.props.event.id;
    //console.log(this.props)
    this.props.appStore.eventViewId = eventId;
    //this.props.history.push('/sports/' + this.props.appStore.sportName + '/' + this.props.appStore.sportId + '/event/' + eventId);
  }

  render() {
    const { appStore } = this.props;
    var pLStatusChanged = appStore.pLStatusChanged;
    //    console.log("app eventViewId=", appStore.eventViewId);
    return (
      <Router>
        {!appStore.showTC ? <div>
          <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            element={document}
            onIdle={this.onIdle}
            debounce={250}
            timeout={1000 * 60 * 15} />
          <div className="App">
            <div className="RD-exchange-sportsBook">
              <div className="RDE-mainHeader">
                <div className="RDE-mainHeader-left">
                  <div className='RDE-logo-container'>
                    <img src={logo} className="RDE-logo" alt="logo" />
                    {/* <div className='RDE-logo__caption'>Exchange</div> */}
                  </div>
                  <ul className="RDE-mainHeader__menu">
                    {
                      appStore.headerData.map((item, index) => {
                        return <li key={"headeritem_" + index} className={item.id === 4 ? "RDE-menuItem-disabled" : ""} onClick={appStore.selectSportFromMenu.bind(appStore, item.id, item.name)}><NavLink className='no-link ' to={'/' + item.url} activeClassName="active" exact={true}><span>{item.name}</span></NavLink></li>
                      })
                    }
                    <li className={!appStore.isLoggedIn ? "RDE-menuItem-disabled " : ""}><NavLink target="_blank" className='no-link ' to={'/livecasino'} activeClassName="active" exact={true}><span>Live Casino</span></NavLink></li>
                    {/* <li><a><span>Cricket</span></a></li>
                    <li><a><span>Football</span></a></li>
                    <li><a><span>Tennis</span></a></li> */}
                  </ul>
                </div>
                <div className="RDE-mainHeader-right">
                  {/* <div className="RDE-searchBox RDE-formGroup"> */}
                  {/*<input type="text" className="RDE-formControl" placeholder="Search Events" />
                                  <i className="RDE-icon-search"></i>*/}
                  {/* <Route render={(props) => <AutoCompleteSearch {...props} appStore={appStore} app={this} />} />
                    <i className="RDE-icon-search"></i>
                  </div> */}
                  
                  {/* Deposit Modal */}

                  {!appStore.isLoggedIn ? <>{/*<div className="RDE-beforeLogin">
                    <input type="text" className="RDE-formControl" placeholder="Email" value={appStore.email} onChange={appStore.onLoginFiledChange.bind(appStore, 'email')} />
                    <input type="password" className="RDE-formControl" placeholder="Password" value={appStore.password} onChange={appStore.onLoginFiledChange.bind(appStore, 'password')}
                   />
                    <div className="RDE-captchaBox">
                      <input type="text" className="RDE-formControl" placeholder="Validation" maxLength={4} value={appStore.reAuthNumber}
                        onChange={appStore.onLoginFiledChange.bind(appStore, 'reAuthNumber')}  onKeyPress={(event)=>appStore.searchQuery(event,settingsStore)}/>
                      <div className="RDE-captchaTxt RDE-skew"><span className="RDE-skewTxt">{appStore.authNumber}</span></div>
                    </div>
                    <button className="btn RDE-btnLogin" onClick={appStore.login.bind(appStore,settingsStore)}><i className="RDE-icon-login"></i></button>
                    <button className="btn RDE-btnSignUp" onClick={appStore.showSignup.bind(appStore)}>Sign Up</button>
                    <div className="RDE-beforeLogin-errorMsg">{appStore.errorMsg}</div>
                </div> */}</> :
                    <div className="RDE-afterLogin">
                      <div className="RDE-afterLogin-balanceSection">
                        <i className="RDE-icon-reload"></i>
                        <div className="RDE-afterLogin-balanceInfo">
                          <div className=""><span className="RDE-afterLogin-balanceInfo__label">Main</span><span> : {appStore.userInfo.currency} {appStore.userInfo.userAccountBalance}</span></div>
                          <div className=""><span className="RDE-afterLogin-balanceInfo__label">Exposure</span><span> : {appStore.userInfo.userExposure}</span></div>
                        </div>
                      </div>
                      <DropdownButton className="RDE-userProfileDropdown RDE-arrow" title={
                        <div className="RDE-afterLogin-playerSection">
                          <span>{appStore.userInfo.userName}</span>
                          <i className="RDE-icon-user-profile"></i>
                        </div>
                      }>
                        <ul className="RDE-userProfileDropdownMenu">
                          {appStore.userMenu.map((item, index) => {
                            if (item.userMenuId === 8) {
                              return <li key={"userMenu_" + index} onClick={appStore.userMenuClick.bind(appStore, item.userMenuId)}>{item.userMenuName}</li>
                            } else {
                              return <li key={"userMenu_" + index}><Dropdown.Item eventKey={item.userMenuId}><NavLink className='no-link' to={'/myaccount' + item.userMenuLinkUrl}>{item.userMenuName}</NavLink></Dropdown.Item></li>
                            }
                          })}
                        </ul>
                      </DropdownButton>
                    </div>}
                    {appStore.isLoggedIn ? <div className="RDE-deposit">
                    <button className="btn RDE-btn-secondary RDE-btn-auto RDE-btn-md" onClick={appStore.showDepositPopup.bind(appStore)}>Deposit</button>
                  </div> : ''}
                </div>
              </div>
              <SubHeader subHeaderStore={subHeaderStore} appStore={appStore} isLoggedIn={appStore.isLoggedIn} settingsStore={settingsStore} />
              <div className="RDE-content">
                {appStore.currentPath !== 'profile' && appStore.currentPath !== 'games' ? <div className="RDE-leftSideBar">
                  {
                    appStore.leftMenuData.map((categorie, index) => {
                      return <div className="RDE-leftSportsMenu" key={"cat_leftmenu_" + index}>
                        <div className="RDE-leftSportsMenu-title">
                          {categorie.name}
                        </div>
                        <ul className="RDE-leftSideMenu-mainNav">
                          {categorie.Sport.map((sport, sportIndex) => {
                            return <li key={"sport_leftmenu_" + sportIndex} >

                              <a className={sport.id === appStore.selectedLeftMenuSportId ? 'active' : 'collapsed'} onClick={appStore.selectLeftMenuSport.bind(appStore, sport.name, sport.id)}><i className={"RDE-sportIcon RDE-icon-" + sport.name.replace(' ', '-').toLowerCase()} />{sport.name}<i className="RDE-icon-down-arrow"></i></a>
                              <Collapse in={sport.id === appStore.selectedLeftMenuSportId}>
                                {appStore.isLeagueLoading && sport.id === appStore.selectedLeftMenuSportId ? <div className="RDE-loader"><div className="text-center"><img src={dataLoader} alt="loading...!" /></div></div> :
                                  <ul className="RDE-leftSideMenu-subNav">
                                    {
                                      appStore.leagueslist.map((league, leagueIndex) => {
                                        return <li key={"sport_league_leftmenu_" + sportIndex + "_" + leagueIndex} >
                                          <a className={league.leagueId === appStore.selectedLeagueId ? 'truncate' : 'collapsed truncate'} onClick={appStore.selectLeague.bind(appStore, league.leagueId)}>{league.leagueName}</a>
                                          <Collapse in={league.leagueId === appStore.selectedLeagueId}>
                                            {appStore.isEventLoading && league.leagueId === appStore.selectedLeagueId ? <ul><li> <div className="RDE-loader"><div className="text-center"><img src={dataLoader} alt="loading...!" /></div></div></li></ul> : <ul className="RDE-leftSideMenu-subNav-sub">
                                              {
                                                appStore.eventsList.map((event, eventIndex) => {
                                                  return <li onClick={this.navigateEventView.bind(this, event.eventId)}>
                                                    {/*<a className={event.eventId === appStore.eventViewId?"active":''} >{event.eventName}</a>*/}
                                                    <NavLink className='no-link truncate' to={'/sports/' + appStore.sportName.replace(' ', '-').toLowerCase() + "/" + appStore.sportId + '/event/' + event.eventId} activeClassName="active" >{event.eventName}</NavLink>
                                                  </li>
                                                })
                                              }
                                              {appStore.eventsList.length === 0 ? <li><div className="RDE-noDataMessageBox">Data not available</div></li> : ""}
                                            </ul>}
                                          </Collapse>
                                        </li>
                                      })
                                    }
                                    {appStore.leagueslist.length === 0 ? <li><div className="RDE-noDataMessageBox">Data not available</div></li> : ""}
                                  </ul>}
                              </Collapse>
                            </li>
                          })}
                        </ul>
                        {/*
                          <ul className="RDE-leftSportsMenu-mostPopular">
                          {categorie.Sport.map((sport, sportIndex) => {
                            return <li key={"sport_leftmenu_" + sportIndex} onClick={appStore.selectSport.bind(appStore, sport.name, sport.id)}>
                              <NavLink className='no-link' to={'/sports/' + sport.name.replace(' ', '-').toLowerCase() + "/" + sport.id} activeClassName="active" ><i className={"RDE-icon-" + sport.name.replace(' ', '-').toLowerCase()} />{sport.name}</NavLink>
                            </li>
                          })}
                        </ul> 
                        */}
                      </div>
                    })
                  }
                </div> : ""}
                <Switch>
                  <Route exact path="/login" render={(props) => <LoginSignup {...props}
                    loginSignupStore={loginSignupStore}
                    appStore={appStore}
                    showSignupBlock={appStore.showSignupBlock}
                    settingsStore={settingsStore} />} />
                  <Route exact path="/" render={(props) => <HomepageLayout {...props}
                    appStore={appStore}
                    isLoggedIn={appStore.isLoggedIn}
                    sportId={appStore.sportId}
                    sportName={appStore.sportName} />} />
                  <Route path="/sports" render={(props) => <SportspageLayout {...props}
                    appStore={appStore}
                    isLoggedIn={appStore.isLoggedIn}
                    sportId={appStore.sportId}
                    sportName={appStore.sportName}
                    eventId={appStore.eventViewId}
                    betslipComponentStore={betslipComponentStore}
                    pLStatusChanged={appStore.pLStatusChanged} />} />
                  <Route exact path="/inplay" render={(props) => <InplaypageLayout {...props}
                    appStore={appStore}
                    isLoggedIn={appStore.isLoggedIn}
                    sportId={appStore.sportId}
                    sportName={appStore.sportName} />} />
                  <Route exact path="/multimarkets" render={(props) => <MultiViewLayout {...props}
                    appStore={appStore}
                    isLoggedIn={appStore.isLoggedIn}
                    sportId={appStore.sportId}
                    sportName={appStore.sportName}
                    betslipComponentStore={betslipComponentStore}
                    pLStatusChanged={appStore.pLStatusChanged} />} />
                  <Route path="/myaccount" render={(props) => <Account {...props}
                    appStore={appStore}
                    isLoggedIn={appStore.isLoggedIn}
                    accountStore={accountStore} />} />
                  <Route path="/election" render={(props) => <Election {...props}
                    appStore={appStore}
                    isLoggedIn={appStore.isLoggedIn}
                    electionStore={electionStore} />} />
                  <Route exact path="/livecasino" render={(props) => <Games {...props}
                    appStore={appStore} />} />
                  <Route exact path="/update_password/:token" render={(props) => <ForgotPassword {...props}
                    forgotPasswordStore={forgotPasswordStore} />} />

                </Switch>
                {appStore.currentPath !== 'profile' && appStore.currentPath !== 'games' ? <div className="RDE-rightSideBar">
                  {/* {appStore.isLoggedIn && appStore.showLiveStreaming?<div className="RDE-liveStreamVideo">
                    <iframe src="http://livestreaming.victoryexch.com" />
                  </div>:''} */}
                  <Route render={(props) => <BetSlipComponent {...props} betslipComponentStore={betslipComponentStore} appStore={appStore} coinValueChange={appStore.coinValuesChanged} />} />
                </div> : ""}
              </div>
              <Footer footerStore={footerStore} />
            </div>
            <Modal
              show={appStore.showSignupPopup}
              backdrop={true}
              backdropClassName="fade"
              centered
              onHide={appStore.hideSignup.bind(appStore)}
              dialogClassName="RDE-loginSignup-modal">
              <LoginSignup loginSignupStore={loginSignupStore} appStore={appStore} showSignupBlock={appStore.showSignupBlock} settingsStore={settingsStore} />
            </Modal>
            <Modal
              show={appStore.showLogoutPopup}
              backdrop={true}
              backdropClassName="fade"
              centered
              onHide={appStore.hideSignup.bind(appStore)}
              dialogClassName="RDE-idleLogout-modal sm">
              <div className="RDE-modal-content">
                <div className="RDE-modal-header">
                </div>
                <div className="RDE-modal-body text-center">
                  <div className="RDE-idleLogout-txt"><b>Your session has expired, Please login again.</b></div>
                </div>
                <div className="RDE-modal-footer">
                  <div className="grid-noGutter-middle-center">
                    <div className="col-12 text-center">
                      <button className="btn RDE-btn-primary RDE-btn-default" onClick={appStore.logout.bind(appStore)}>Ok</button>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
            <Modal
              show={appStore.showDeposit}
              backdrop={true}
              backdropClassName="fade"
              centered
              onHide={appStore.hideDepositPopup.bind(appStore)}
              dialogClassName="RDE-deposit-modal sm">
              <div className="RDE-modal-content">
                <div className="RDE-modal-header">
                  <div className="RDE-modal-header__txt">Deposit</div>
                  <img src={popUpClose} className="RDE-modal-closeIcon" onClick={appStore.hideDepositPopup.bind(appStore)} />
                  <div className="RDE-hr"></div>
                </div>
                <div className="RDE-modal-body">
                  <div className="RDE-deposit-contact text-center">
                    <div className="mb-5">Please contact for Deposit</div>
                    {/* <div>+91 9876543210</div> */}
                    <div>+44 7566 742924</div>
                  </div>
                </div>
                <div className="RDE-modal-footer">
                  <div className="grid-noGutter-middle-center">
                    <div className="col-12 text-center">
                      <button className="btn RDE-btn-primary" onClick={appStore.hideDepositPopup.bind(appStore)}>Close</button>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </div> :
          <div>
            <Switch>
              <Route exact path="/privacy-policy" render={(props) => <PrivacyPolicy {...props} />} />
              <Route exact path="/rules-and-regulations" render={(props) => <RulesRegulations {...props} />} />
              <Route exact path="/terms-and-conditions" render={(props) => <TermsConditions {...props} />} />
            </Switch>
          </div>
        }
      </Router>
    );
  }
}

export default withRouter(observer(App));