import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Route } from "react-router-dom";

import EventComponent from '../../../../sharedCcomponents/eventcomponent/EventComponent';
import EventComponentStore from '../../../../sharedCcomponents/eventcomponent/EventComponentStore';
import dataLoader from '../../../../assets/images/loader-orange.svg';
import { preMatchRefreshTime } from '../../../../sharedfiles/helper';

const eventComponentStore = new EventComponentStore();
class Upcoming extends Component {
  componentWillMount() {
    this.props.upcomingStore.selectedSport = this.props.defaultSport;
    this.props.upcomingStore.selectedOption = this.props.defaultOption.id;
    this.props.upcomingStore.mounted = true;
    this.props.upcomingStore.getUpcomingEventData();
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.defaultSport.id !== nextProps.defaultSport.id) {
      this.props.upcomingStore.selectedSport = nextProps.defaultSport;
      this.props.upcomingStore.getUpcomingEventData();
    }
    if (this.props.defaultOption.id !== nextProps.defaultOption.id) {
      this.props.upcomingStore.selectedOption = nextProps.defaultOption.id;
      this.props.upcomingStore.getUpcomingEventData();
    }
    if (this.props.isLoggedIn !== nextProps.isLoggedIn) {
      this.props.upcomingStore.getUpcomingEventData();
    }
  }
  componentDidMount() {
    var self = this;
    setTimeout(function reCallService() {
      if (self.props.upcomingStore.mounted) {
        self.props.upcomingStore.getUpcomingEventData();
        setTimeout(reCallService, preMatchRefreshTime);
      }

    }, preMatchRefreshTime);
  }
  componentWillUnmount() {
    this.props.upcomingStore.mounted = false;
    console.log("upcomingData")
  }
  render() {
    const { sports, upcomingStore, appStore } = this.props;
    return (
      <div className="RDE-upcomingSection">
        <div className="RDE-sportsList">
          <ul className="">
            {
              sports.map((sport, index) => {
                return <li key={"up_sport_" + index} onClick={upcomingStore.selectSport.bind(upcomingStore, sport)}>

                  <div className={upcomingStore.selectedSport.id === sport.id ? "RDE-sportsList-item active" : "RDE-sportsList-item"}>
                    <i className={"RDE-icon-" + sport.name.toLowerCase().replace(" ", "-")}></i>
                    <div className="RDE-sportsList-item__details"><span
                      className="RDE-sportsList-item__sportName">{sport.name}</span> </div>
                  </div>
                </li>
              })
            }
          </ul>
        </div>
        <div className="RDE-singleLineEventMarketHeader">
          <div className="RDE-singleLineEventMarketHeader__left">
            <div className="RDE-singleLineEventMarketHeader-title">
              {upcomingStore.selectedSport.name}
            </div>
          </div>
          <div className="RDE-singleLineEventMarketHeader__right">
            <div className="RDE-singleLineEventMarketHeader__marketSection">
              {!upcomingStore.showErrorMsg && !upcomingStore.showLoader ? <ul>
                {upcomingStore.selections.map((selection, index) => {
                  return <li key={"selection_header_upcoming_" + index}>
                    <div className="RDE-singleLineEventMarketHeader__marketItem">{selection.name}</div>
                  </li>
                })}
              </ul>
                : ""}
            </div>
          </div>
        </div>
        <div className="RDE-eventItemGroup">
          {
            upcomingStore.eventData.map((event, index) => {
             
                return (
                  index < upcomingStore.itemsShow &&   <Route render={(props) => <EventComponent {...props} event={event} key={"event_upcoming_" + index}
                  appStore={appStore}
                  eventComponentStore={eventComponentStore}
                  componentType="upcoming"
                  sportId={upcomingStore.selectedSport.id}
                  sportName={upcomingStore.selectedSport.name} />} />
            
            )})
          }
        </div>
        {upcomingStore.showErrorMsg ? <div className="RDE-noDataMessageBox">
          Currently data is not available for this selection.
        </div> : ''}
        {upcomingStore.showLoader ? <div className="RDE-loader">
          <img src={dataLoader} alt="Loader" />
        </div> : ''}
        {upcomingStore.eventData.length > upcomingStore.initialItemShow ? <div className="RDE-showMore">
          {upcomingStore.itemsShow < upcomingStore.eventData.length ? <div onClick={upcomingStore.showMore.bind(upcomingStore)}><span>Show more </span><i className="RDE-icon-down-arrow"></i></div> :
            <div onClick={upcomingStore.showLess.bind(upcomingStore)}><span>Show less </span><i className="RDE-icon-up-arrow"></i></div>}
        </div> : ''}
      </div>
    )
  }
}
export default observer(Upcoming);
