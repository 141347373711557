import React, { Component } from 'react';
import { observer } from 'mobx-react';
import cookie from 'react-cookies';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { Route, NavLink, Switch } from "react-router-dom";

import BetHistory from './bethistory/BetHistory';
import BetHistoryStore from './bethistory/BetHistoryStore';
import ProfitLoss from './profitloss/ProfitLoss';
import ProfitLossStore from './profitloss/ProfitLossStore';

const betHistoryStore = new BetHistoryStore();
const profitLossStore = new ProfitLossStore();
class MyBets extends Component {
  componentWillMount() { }
  componentWillReceiveProps(nextProps) { }
  render() {
    const { myBetsStore } = this.props;
    var isBetHistory = false;
    if (!window.location.pathname.includes('profitloss')) {
      var isBetHistory = true;
    }
    return (
      <div className="RDE-DB-myBets">
        <div className="RDE-DB-myBets-tabs">
          <ul>
            <li>
              <NavLink className={'no-link' + (isBetHistory ? ' active' : '')} to={'/myaccount/mybets/bethistory'} exact={true} activeClassName="active">
                <div className="RDE-DB-myBets-tabs-menu__item">
                  <span className="RDE-skewTxt">
                    Bet History
                  </span>
                </div>
              </NavLink>
            </li>
            <li>
              <NavLink className='no-link' to={'/myaccount/mybets/profitloss'} activeClassName="active">
                <div className="RDE-DB-myBets-tabs-menu__item">
                  <span className="RDE-skewTxt">
                    Profit & Loss</span>
                </div>
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="space-5"></div>
        <Switch>
          <Route exact path="/myaccount/mybets/bethistory" render={(props) => <BetHistory {...props}
            betHistoryStore={betHistoryStore} />} />
          <Route exact path="/myaccount/mybets" render={(props) => <BetHistory {...props}
            betHistoryStore={betHistoryStore} />} />
          <Route exact path="/myaccount/mybets/profitloss" render={(props) => <ProfitLoss {...props}
            profitLossStore={profitLossStore} />} />
        </Switch>
      </div>
    )
  }
}
export default observer(MyBets);
