import React, { Component } from 'react';
import { observer } from 'mobx-react';
import cookie from 'react-cookies';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { Collapse } from "react-bootstrap";

import dataLoader from '../../../../../assets/images/loader-orange.svg';
import { getLocalDate } from '../../../../../sharedfiles/helper'
import BalanceOverviewTable from '../../balanceoverviewtable/BalanceOverviewTable';
import BalanceOverviewTableStore from '../../balanceoverviewtable/BalanceOverviewTableStore';

const balanceOverviewTableStore = new BalanceOverviewTableStore();

class ProfitLoss extends Component {
  componentWillMount() {
    this.props.profitLossStore.totalPL = 0;
    // this.props.profitLossStore.getProfitLoss();
    this.props.profitLossStore.resetFields();
  }
  componentWillReceiveProps(nextProps) { }
  render() {
    const { profitLossStore } = this.props;
    return (
      <div className="RDE-DB-myBets__profitLoss">
        {!balanceOverviewTableStore.showEventDetails ? <><div className="RDE-sports-nav-tabs">
          <ul className="RDE-sports-nav-tabs-menu">
            <li>
              <div className="RDE-sports-nav-tabs-menu__item active">
                <span className="RDE-skewTxt">Exchange</span>
              </div>
            </li>
            {/* <li>
                <div className="RDE-sports-nav-tabs-menu__item">
                  <span className="RDE-skewTxt">Casino</span>
                </div>
              </li> */}
          </ul>
        </div>
          <div className="space-5"></div>
          <div className="RDE-DB-myBets-filters">
            <div className="RDE-DB-myBets-filters__left">
              <span className="RDE-DB-myBets-filters__label">Period From :</span>
              <div className="RDE-DB-myBets-filters__datePicker">
                <DatePicker selected={profitLossStore.fromDate}
                  dateFormat="dd/MM/yyyy"
                  onChange={profitLossStore.selectFromDate.bind(profitLossStore)}
                  className="RDE-DB-datePickerInput"
                  maxDate={new Date()} />

                <span className="RDE-DB-myBets-filters__label">To :</span>
                <DatePicker selected={profitLossStore.toDate}
                  selectsEnd
                  dateFormat="dd/MM/yyyy"
                  onChange={profitLossStore.selectToDate.bind(profitLossStore)}
                  className="RDE-DB-datePickerInput"
                  maxDate={new Date()} />
                <span className="RDE-DB-datePickerErrorMessage">{profitLossStore.dateErrMsg}</span>
              </div>
              <button className="btn RDE-btn-primary RDE-btn-small RDE-btn-auto" onClick={profitLossStore.getPL.bind(profitLossStore)}>Get P&L</button>
            </div>
            <div className="RDE-DB-myBets-filters__right">
              <ul className="RDE-DB-myBets-filters__todayYesterdayList">
                <li className={profitLossStore.selectedOption === "today" ? "active" : ""}
                  onClick={profitLossStore.selectTodayYesterday.bind(profitLossStore, 'today')}>Just For Today</li>
                <li className={profitLossStore.selectedOption === "yesterday" ? "active" : ""}
                  onClick={profitLossStore.selectTodayYesterday.bind(profitLossStore, 'yesterday')}>From Yesterday</li>
              </ul>
            </div>
          </div>

          <div className="RDE-profitLossSportsFilter">
            <ul>
              {
                profitLossStore.sports.map((sport, index) => {
                  return <li className={profitLossStore.selectedSport.sportId === sport.sportId ? "active" : ""} onClick={profitLossStore.selectSport.bind(profitLossStore, sport)}>{sport.sportName}: <span className={"RDE-DB-txtHighlight " + (Number(sport.profitLossAmount) > 0 ? "RDE-DB-profit" : "RDE-DB-loss")}>{sport.profitLossAmount}</span></li>
                })
              }
            </ul>
          </div>
          <div className="RDE-DB-totalValue">
            <div className="col-6">
              <span><b>Total P&L: PH</b>
                <span className={"RDE-DB-txtHighlight " + (profitLossStore.totalPL > 0 ? "RDE-DB-profit" : "RDE-DB-loss")}><b>({Number(profitLossStore.totalPL).toFixed(2)})</b></span></span>
            </div>
            <div className="col-6 text-right">
            </div>
          </div></> : ""}

        {!profitLossStore.showErrorMsg && !profitLossStore.showLoader ?

          <BalanceOverviewTable data={profitLossStore.profitLossData} component="PL" balanceOverviewTableStore={balanceOverviewTableStore} selectedSport={profitLossStore.selectedSport} />
          : ''}
        {profitLossStore.showErrorMsg ? <div className="RDE-noDataMessageBox">
          Currently data is not available for your selection.
        </div> : ''}
        {profitLossStore.showLoader ? <div className="RDE-loader">
          <img src={dataLoader} alt="loading...!" />
        </div> : ''}
      </div>
    )
  }
}
export default observer(ProfitLoss);
