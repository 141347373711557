import React, { Component } from 'react';
import { observer } from 'mobx-react';

import Highlights from '../../../sharedCcomponents/highlights/Highlights';
import HighlightsStore from '../../../sharedCcomponents/highlights/HighlightsStore';
import Upcoming from './upcoming/Upcoming';
import UpcomingStore from './upcoming/UpcomingStore';

const upcomingStore = new UpcomingStore();
const highlightsStore = new HighlightsStore();

class HighlightsUpcoming extends Component {
  componentWillMount() {
    // this.props.bannerStore.getBannerData();
    // this.props.bannerStore.appStore = this.props.appStore;
    this.props.highlightsUpcomingStore.getUpcomingOptions()
  }
  componentWillReceiveProps(nextProps) { }
  render() {
    const { highlightsUpcomingStore, appStore, isLoggedIn, betSlipStatusChange } = this.props;
    return (
      <div className="RDE-upcomingHighlights">
        <div className="RDE-sports-nav-tabs RDE-nav-tabs-large">
          <ul className="RDE-sports-nav-tabs-menu">
            <li onClick={highlightsUpcomingStore.toggleHighlightsUpcoming.bind(highlightsUpcomingStore, 'highlights')}>
              <div className={highlightsUpcomingStore.showTab === 'highlights' ? "RDE-sports-nav-tabs-menu__item active" : 'RDE-sports-nav-tabs-menu__item'}>
                <span className="RDE-skewTxt">
                  <i className="RDE-icon-highlights"></i> Highlights
                </span>
              </div>
            </li>
            <li onClick={highlightsUpcomingStore.toggleHighlightsUpcoming.bind(highlightsUpcomingStore, 'upcoming')}>
              <div className={highlightsUpcomingStore.showTab === 'upcoming' ? "RDE-sports-nav-tabs-menu__item active" : 'RDE-sports-nav-tabs-menu__item'}>
                <span className="RDE-skewTxt">
                  <i className="RDE-icon-upcoming"></i> Upcoming
                </span>
              </div>
            </li>
          </ul>
          {highlightsUpcomingStore.showTab === 'upcoming' ? <ul className="RDE-upcoming-filterByList">
            {
              highlightsUpcomingStore.upcomingOptions.hr.map((option, index) => {
                return <li key={'options_hr_' + index}
                  onClick={upcomingStore.selectOption.bind(upcomingStore, option)}>
                  <a className={upcomingStore.selectedOption == option.id ? "active" : ""}>
                    <div>{option.frequency}</div>
                    <div>{option.unitMeasure}</div>
                  </a></li>
              })
            }
            <li onClick={upcomingStore.selectOption.bind(upcomingStore, highlightsUpcomingStore.upcomingOptions.today)}>
              <a className={upcomingStore.selectedOption == highlightsUpcomingStore.upcomingOptions.today.id ? "active" : ""}>
                <div>{highlightsUpcomingStore.upcomingOptions.today.unitMeasure}</div>
              </a></li>
            {
              highlightsUpcomingStore.upcomingOptions.day.map((option, index) => {
                return <li key={'options_day_' + index}
                  onClick={upcomingStore.selectOption.bind(upcomingStore, option)}>
                  <a className={upcomingStore.selectedOption == option.id ? "active" : ""}>
                    <div>{option.day}</div>
                    <div>{option.month}</div>
                  </a>
                </li>
              })
            }
            <li onClick={upcomingStore.selectOption.bind(upcomingStore, highlightsUpcomingStore.upcomingOptions.all)}>
              <a className={upcomingStore.selectedOption === highlightsUpcomingStore.upcomingOptions.all.id ? "active" : ""}>
                <div>{highlightsUpcomingStore.upcomingOptions.all.unitMeasure}</div>
              </a></li>
          </ul> : ''}
        </div>
        <div className="RDE-upcomingHighlights-content">
          {highlightsUpcomingStore.showTab === 'highlights' ? <Highlights highlightsStore={highlightsStore} appStore={appStore}
            isLoggedIn={isLoggedIn}
            componentType="highlights"
            pageId={1}
            betSlipStatusChange={betSlipStatusChange} /> : ''}
          {highlightsUpcomingStore.showTab === 'upcoming' ? <Upcoming upcomingStore={upcomingStore}
            sports={highlightsUpcomingStore.upcomingSports}
            defaultSport={highlightsUpcomingStore.defaultSport}
            defaultOption={highlightsUpcomingStore.defaultOption}
            appStore={appStore}
            isLoggedIn={isLoggedIn}
            betSlipStatusChange={betSlipStatusChange} /> : ''}
        </div>
      </div>
    )
  }
}
export default observer(HighlightsUpcoming);
