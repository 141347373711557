import React, { Component } from 'react';
import { observer } from 'mobx-react';
import OddsComponent from '../oddscomponent/OddsComponent';
import { formatDate } from '../../sharedfiles/helper';
import OutcomeComponent from '../outcomescomponent/OutcomeComponent'
import { masterPage } from "../../sharedfiles/EndpointConfig";

class MarketOutcomes extends Component {
  componentWillMount() {
    this.props.marketOutcomesStore.appStore = this.props.appStore;
    this.props.marketOutcomesStore.component = this.props.component;
    this.props.marketOutcomesStore.parentStore = this.props.parentStore;
  }
  componentWillReceiveProps(nextProps) {
    // if(this.props.event.isBlocked !== nextProps.event.isBlocked){

    // }
    // if(this.props.event.isSuspended !== nextProps.event.isSuspended){

    // }
  }

  render() {
    const { data,
      eventSuspended,
      eventBlocked,
      appStore,
      eventName,
      eventId,
      marketOutcomesStore,
      component,
      betSlipStatusChange,
      homeTeam,
      awayTeam,
      homeTeamId,
      awayTeamId,
      isLive,
      profitLoss,
      appStoreProfitLoss,
      showMinMax,
      kickOffTime } = this.props;
    return (
      <div className="">
        <div className="RDE-singleLineOutcomeMarketHeader">
          <div className="RDE-singleLineOutcomeMarketHeader__leftContainer">
            <div className="RDE-sports-nav-tabs">
              <ul className="RDE-sports-nav-tabs-menu">
                <li>
                  <div className={"RDE-sports-nav-tabs-menu__item RDE-pin" + (data.isPinned ? " active" : "")} onClick={marketOutcomesStore.savePin.bind(marketOutcomesStore, eventId, data.marketGroupId, data.isPinned, data.providerId, this.props)}>
                    <span className="RDE-skewTxt">
                      <i className="RDE-icon-pin"></i>
                    </span>
                  </div>
                </li>
                <li>
                  <div className="RDE-sports-nav-tabs-menu__item active">
                    <span className="RDE-skewTxt">
                      {data.marketName}
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="RDE-singleLineOutcomeMarketHeader__rightContainer">
            <div className="RDE-singleLineOutcomeMarketHeader__bettingPercentage">
              {/*101%*/}
            </div>
            <div className="RDE-singleLineOutcomeMarketHeader__backAndLayAll RDE-backAll">
              {component === 'matchodds1' ? 'Back' : 'Back all'}
            </div>
            <div className="RDE-singleLineOutcomeMarketHeader__backAndLayAll RDE-layAll">
              {component === 'matchodds1' ? 'Lay' : 'Lay all'}
            </div>
           { !showMinMax &&<div className="RDE-singleLineOutcomeMarketHeader__bettingPercentage RDE-right">
              {/*99%*/}
            </div>}
          </div>

        </div>
        <OutcomeComponent outcomes={data.outcomes ? data.outcomes : []}
          eventSuspended={eventSuspended}
          marketSuspended={data.marketSuspended}
          eventBlocked={eventBlocked}
          marketBlocked={data.marketBlocked}
          eventName={eventName}
          eventId={eventId}
          homeTeam={homeTeam}
          awayTeam={awayTeam}
          homeTeamId={homeTeamId}
          awayTeamId={awayTeamId}
          isLive={isLive}
          kickOffTime={kickOffTime}
          marketName={data.marketName}
          marketId={data.marketGroupId}
          minStake={data.minStake}
          maxStake={data.maxStake}
          component={component}
          clMarketId={data.clMarketId}
          providerId={data.providerId}
          appStore={appStore}
          profitLoss={profitLoss}
          betSlipStatusChange={betSlipStatusChange}
          showMinMax={showMinMax}
          
          appStoreProfitLoss={appStoreProfitLoss} />
        <div className="space-5"></div>
      </div>
    )
  }
}
export default observer(MarketOutcomes);
