import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Collapse } from "react-bootstrap";

import dataLoader from '../../assets/images/loader-orange.svg';
import FancyOutcomes from '../../sharedCcomponents/fancyoutcomes/FancyOutcomes';
import FancyOutcomesStore from '../../sharedCcomponents/fancyoutcomes/FancyOutcomesStore';
import { liveMatchRefreshTime } from '../../sharedfiles/helper';

const fancyOutcomesStore = new FancyOutcomesStore();
class Election extends Component {
  componentWillMount() {
    this.props.appStore.currentPath = 'election';
    this.props.electionStore.getCommonEventView();
    this.props.appStore.clearBetslip();
  }
  componentDidMount() {
    var self = this;
    setTimeout(function reCallService() {
      if (self.props.electionStore.mounted) {
        self.props.electionStore.getCommonEventView();
        setTimeout(reCallService, liveMatchRefreshTime);
      }
    }, liveMatchRefreshTime);
  }
  componentWillUnmount() {
    this.props.electionStore.mounted = false;
  }
  render() {
    const { appStore, electionStore } = this.props
    return (
      <div className="RDE-mainContent">
        <div className="RDE-multiMarket">
          <div className="RDE-accordion-group">
            {
              electionStore.commonEvents.map((data, index) => {
                return data.events.map((event, eventIndex) => {
                  return <div className={"RDE-accordion" + (electionStore.accordionOpenIndex.indexOf(eventIndex) === -1 ? " collapsed" : "")} key={"election_0"}>
                    <div className="RDE-accordion-header RDE-singleLineEventMarketHeader" onClick={electionStore.openAccordion.bind(electionStore, eventIndex)}>
                      <div className="RDE-singleLineEventMarketHeader__left">
                        <div className="RDE-singleLineEventMarketHeader-title">
                          {event.name}
                        </div>
                      </div>
                      <div className="RDE-singleLineEventMarketHeader__right">

                      </div>
                    </div>
                    <Collapse in={electionStore.accordionOpenIndex.indexOf(0) !== -1}>
                      <div className="RDE-accordion-body">
                        <FancyOutcomes data={event.fancyBets ? event.fancyBets : {}}
                          eventSuspended={event.eventSuspended}
                          eventBlocked={event.eventBlocked}
                          eventName={event.name}
                          eventId={event.id}
                          appStore={appStore}
                          fancyOutcomesStore={fancyOutcomesStore}
                          backHeader='Yes'
                          layHeader='No'
                          mainHeader='Fancy Bets'
                          showPin={false}
                          component="fancy" />
                        <FancyOutcomes data={event.fancyBets ? event.fancyBets1 : {}}
                          eventSuspended={event.eventSuspended}
                          eventBlocked={event.eventBlocked}
                          eventName={event.name}
                          eventId={event.id}
                          appStore={appStore}
                          fancyOutcomesStore={fancyOutcomesStore}
                          backHeader='Back'
                          layHeader='Lay'
                          showPin={false}
                          mainHeader='Fancy Bets1'
                          component="adminfancy" />
                      </div>
                    </Collapse>

                  </div>
                })
              })
            }
          </div>

        </div>
        {electionStore.showErrorMsg ? <div className="RDE-noDataMessageBox">
          Currently data is not available.
        </div> : ''}
        {electionStore.showLoader ? <div className="RDE-loader">
          <img src={dataLoader} alt="loading...!" />
        </div> : ''}
      </div>
    )
  }
}
export default observer(Election);
