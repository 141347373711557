import { extendObservable } from "mobx";

class HighlightEventsStore {
  constructor(props) {
    extendObservable(this, {
      accordionOpenIndex: [0]
    });
  }
  /**
  * this method is used to open the accordion.
  * @param {Number} index- contains index to open the accordion.
  */
  openAccordion(index) {
    //console.log(index)
    if (index >= 0) {
      var eventIndex = this.accordionOpenIndex.indexOf(index);
      if (eventIndex === -1) {
        this.accordionOpenIndex.push(index);
      } else {
        this.accordionOpenIndex.splice(eventIndex, 1);
      }

    }
  }
}

export default HighlightEventsStore;
