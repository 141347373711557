import { extendObservable } from "mobx";
import cookie from 'react-cookies';

import { masterPage } from "../../sharedfiles/EndpointConfig";
import { apiCaller } from '../../sharedfiles/apiStore';

//const re = /[0-9.\b]+$/;
const re = /^\d+\.?\d{0,2}$/;
class BetSlipComponentStore {
  constructor(props) {
    this.timeout = '';
    this.timeInterval = null;
    extendObservable(this, {
      data: [],
      showOneClick: false,
      appStore: {},
      showSettings: false,
      settingsStore: {},
      betSlipItems: { backBets: [], layBets: [] },
      stakeCoins: [],
      totalStake: "0.00",
      totalReturns: "0.00",
      showConfirm: false,
      confirmBets: false,
      betSlipError: "",
      isValidBets: false,
      acceptOddsChange: false,
      showLoader: false,
      betsSaved: false,
      countDownText: "",
      countDown: 5,
      showTab: 'betslip',
      openbetsData: [],
      // selectedOpenBetsEvent:{
      //   eventId:'',
      //   eventName:''
      // },
      selectedOpenBets: [],
      editViewOpenBets: [],
      editOpenBets: false,
      openBetsErr: '',
      isOpenBetsStakeErr: false,
      //openBetsEventIds:[]
      showBetsSuccess: false,
      showOpenBetLoader: false,
      betslipSuccess: "",
      msgInfo: "",
      msgType: "",
      placedBetInfo: {
        type: '',
        stake: '',
        odds: '',
        isMatched: '',
        isSingleBet: false
      },
      isStakeEdited: true,
      isOddsEdited: true,
      totalTitle: ""
    });

  }
  /**
  * This method is used to add a item to betslip.
  * @param {Object} selection - cointains the event information to add to betslip
  */
  addBetSlipItem(selection) {
    var isAdded = false;
    this.showConfirm = false;
    this.confirmBets = false;
    var defaultStake = localStorage.getItem('defaultStake');
    if (selection && selection.hasOwnProperty('betType')) {
      selection.userOdds = selection.odds;
      selection.userStake = defaultStake ? defaultStake : 1000;
      selection.returns = defaultStake ? selection.isFancy ? this.calculateFancyReturns(selection) : (defaultStake * selection.odds).toFixed(2) : "0.00"
      if (selection.betType === 'BO') {
        var betIndex = this.findBetIndex(this.betSlipItems.backBets, selection)
        if (betIndex >= 0) {
          if (this.betSlipItems.backBets[betIndex].odds !== selection.odds) {
            this.betSlipItems.backBets[betIndex] = selection;
            isAdded = true;
          } else {
            this.appStore.removePL(this.betSlipItems.backBets[betIndex]);
            this.betSlipItems.backBets.splice(betIndex, 1);
            isAdded = false;
          }

        } else {
          /** Always only one bet needs to display in betslip i.e. recently added bet. */
          if (this.betSlipItems.backBets.length > 0) {
            this.appStore.removePL(this.betSlipItems.backBets[0]);
          } else if (this.betSlipItems.layBets.length > 0) {
            this.appStore.removePL(this.betSlipItems.layBets[0]);
          }
          this.betSlipItems = { backBets: [], layBets: [] };
          this.betSlipItems.backBets.push(selection);
          isAdded = true;
        }
      } else {
        if (selection.betType === 'LO') {
          var betIndex = this.findBetIndex(this.betSlipItems.layBets, selection)
          if (betIndex >= 0) {
            if (this.betSlipItems.layBets[betIndex].odds !== selection.odds) {
              this.betSlipItems.layBets[betIndex] = selection;
              isAdded = true;
            } else {
              this.appStore.removePL(this.betSlipItems.layBets[betIndex]);
              this.betSlipItems.layBets.splice(betIndex, 1);
              isAdded = false;
            }
          } else {
            /** Always only one bet needs to display in betslip i.e. recently added bet. */
            this.betSlipItems = { backBets: [], layBets: [] };
            this.betSlipItems.layBets.push(selection);
            isAdded = true;
          }
        }
      }
    }
    this.validateBets();
    this.calStakeReturns();
    this.appStore.calProfitLoss();
    this.showTab = 'betslip';
    return isAdded;
  }
  /**
  *
  *
  */
  findBetIndex(bets, selection) {
    var index = 0;
    var betIndex = -1;
    for (var bet of bets) {
      if (bet.eventId === selection.eventId &&
        bet.marketId === selection.marketId &&
        bet.selectionId === selection.selectionId) {
        betIndex = index;
        break;
      }
      index++;
    }
    return betIndex;
  }
  /**
  * This method is used to toggle between betslip and open bets
  * @param {String} tabName contain the tab name to select
  */
  selectTab(tabName, appStore) {
    var self = this;
    this.clearBetslip();
    this.resetOpen();
    if (cookie.load('UTocken') && cookie.load('UTocken').length > 0) {
      this.showTab = tabName;
      if (tabName === 'openbets') {
        if (self.timeInterval) {
          clearInterval(self.timeInterval);
        }
        // self.timeout=setTimeout(function reCallService() {
        //   if (self.showTab === 'openbets') {
        //     debugger;
        //       self.getOpenbets(appStore);;
        //       setTimeout(reCallService, 3* 1000);
        //     }
        //     else {
        //       clearTimeout(self.timeout);
        //     }
        // }, 3 * 1000);
        self.getOpenbets(appStore);
        self.timeInterval = setInterval(function () {
          if (self.showTab === 'openbets') {
            self.getOpenbets(appStore);
          }
          else {
            clearInterval(self.timeInterval);
          }
        }, 3000);
      }
    }
  }
  /**
  * This method used to select the open bets events
  * @param {Number} eventId contains the event id to be selected.
  **/
  selectOpenbetsEvent(eventInfo, appStore, openBetsProps) {
    this.resetOpenBetSettings();
    appStore.selectedOpenBetsEvent.eventId = eventInfo.eventId;
    appStore.selectedOpenBetsEvent.eventName = eventInfo.eventName;
    this.selectedOpenBets = [];
    this.editOpenBets = false;
    appStore.sportId = eventInfo.sportId;
    appStore.sportName = eventInfo.sportName.toLowerCase();
    appStore.eventViewId = appStore.selectedOpenBetsEvent.eventId;
    openBetsProps.naviGateTOEventView(appStore);

  }
  /**
  *
  *
  */
  oddsChanged(bet, betType, e) {
    /**
     * Enabling one field at a time. 
     */
    bet.isStakeEdited = false;
    bet.isOddsEdited = true;
    bet.isOddsChange = true;
    if ((re.test(e.target.value) && e.target.value >= 1 && e.target.value <= 100000) || e.target.value === "") {
      bet.edited = true;
      bet.changedOdds = this.checkEnteredOdds(e.target.value);
      bet.changedReturns = this.calculateOpenBetsReturns(bet);
      this.appStore.calOpenBetsProfitLoss(bet, betType);
    }
  }
  checkEnteredOdds(odds) {
    var enteredOdds = odds;
    var stepResult = odds;
    if (Number(enteredOdds) >= 1 && Number(enteredOdds) < 2) {
      //incRange = 0.01;
      stepResult = this.checkStepByRange(enteredOdds, 0.01);
    } else if (Number(enteredOdds) >= 2 && Number(enteredOdds) < 3) {
      //incRange = 0.02;
      stepResult = this.checkStepByRange(enteredOdds, 0.02);
    } else if (Number(enteredOdds) >= 3 && Number(enteredOdds) < 4) {
      //incRange = 0.05;
      stepResult = this.checkStepByRange(enteredOdds, 0.05);
    } else if (Number(enteredOdds) >= 4 && Number(enteredOdds) < 6) {
      //incRange = 0.10;
      stepResult = this.checkStepByRange(enteredOdds, 0.10);
    } else if (Number(enteredOdds) >= 6 && Number(enteredOdds) < 10) {
      //incRange = 0.20;
      stepResult = this.checkStepByRange(enteredOdds, 0.20);
    } else if (Number(enteredOdds) >= 10 && Number(enteredOdds) < 20) {
      //incRange = 0.50;
      stepResult = this.checkStepByRange(enteredOdds, 0.50);
    } else if (Number(enteredOdds) >= 20 && Number(enteredOdds) < 30) {
      //incRange = 1.00;
      stepResult = this.checkStepByRange(enteredOdds, 1.00);
    } else if (Number(enteredOdds) >= 30 && Number(enteredOdds) < 50) {
      //incRange = 2.00;
      stepResult = this.checkStepByRange(enteredOdds, 2.00);
    } else if (Number(enteredOdds) >= 50 && Number(enteredOdds) < 100) {
      //incRange = 5.00;
      stepResult = this.checkStepByRange(enteredOdds, 5.00);
    } else if (Number(enteredOdds) >= 100) {
      //incRange = 10.00;
      stepResult = this.checkStepByRange(enteredOdds, 0.02);
    }
    return stepResult;
  }
  checkStepByRange(enteredOdds, stepValue) {
    var stepResult = enteredOdds;
    var filteredOdds = (Number(enteredOdds) * 100).toFixed(2);
    var oddsDividedByStepRange = filteredOdds / (Number(stepValue) * 100).toFixed(2);
    if (!Number.isInteger(oddsDividedByStepRange)) {
      // while (stepStart, stepEnd) {
      //   if (enteredOdds < stepStart) {
      //     stepResult = (stepStart - stepValue).toFixed(2);
      //     break;
      //   }
      //   stepStart += stepValue;
      // }
      //debugger;
      stepResult = (Math.trunc(oddsDividedByStepRange) * stepValue).toFixed(2);
    }
    return stepResult;
  }
  /**
  *
  *
  */
  oddsInc(bet, betType) {
    /**
 * Enabling one field at a time. 
 */
    bet.isStakeEdited = false;
    bet.isOddsEdited = true;
    bet.edited = true;
    bet.isOddsChange = true;
    var incRange = "";

    if (Number(bet.changedOdds) >= 1 && Number(bet.changedOdds) < 2) {
      incRange = 0.01;
    } else if (Number(bet.changedOdds) >= 2 && Number(bet.changedOdds) < 3) {
      incRange = 0.02;
    } else if (Number(bet.changedOdds) >= 3 && Number(bet.changedOdds) < 4) {
      incRange = 0.05;
    } else if (Number(bet.changedOdds) >= 4 && Number(bet.changedOdds) < 6) {
      incRange = 0.10;
    } else if (Number(bet.changedOdds) >= 6 && Number(bet.changedOdds) < 10) {
      incRange = 0.20;
    } else if (Number(bet.changedOdds) >= 10 && Number(bet.changedOdds) < 20) {
      incRange = 0.50;
    } else if (Number(bet.changedOdds) >= 20 && Number(bet.changedOdds) < 30) {
      incRange = 1.00;
    } else if (Number(bet.changedOdds) >= 30 && Number(bet.changedOdds) < 50) {
      incRange = 2.00;
    } else if (Number(bet.changedOdds) >= 50 && Number(bet.changedOdds) < 100) {
      incRange = 5.00;
    } else if (Number(bet.changedOdds) >= 100) {
      incRange = 10.00;
    }

    bet.changedOdds = (Number(bet.changedOdds) + incRange).toFixed(2);
    //bet.changedOdds = (Number(bet.changedOdds)+0.01).toFixed(2);
    bet.changedReturns = (bet.changedOdds * bet.changedStake).toFixed(2);
    this.appStore.calOpenBetsProfitLoss(bet, betType);
  }

  calculateOpenBetsReturns(bet) {
    if (bet.isFancy) {
      if (bet.changedOdds && bet.changedOdds > 0 && bet.changedStake && bet.changedStake > 0) {
        var returns = (Number(bet.changedOdds) * Number(bet.changedStake)) / 100;
        var totalReturns = returns + Number(bet.changedStake);
        return totalReturns.toFixed(2);
      } else {
        return "0.00"
      }
    } else {
      if (bet.changedOdds && bet.changedOdds > 0 && bet.changedStake && bet.changedStake > 0) {
        return (Number(bet.changedOdds) * Number(bet.changedStake)).toFixed(2);
      } else {
        return "0.00"
      }
    }


  }
  /**
  *
  *
  */
  oddsDec(bet, betType) {
    /**
    * Enabling one field at a time. 
    */
    bet.isStakeEdited = false;
    bet.isOddsEdited = true;
    bet.edited = true;
    bet.isOddsChange = true;
    if (bet.changedOdds > 1.01) {
      // bet.changedOdds = (Number(bet.changedOdds)-0.01).toFixed(2);
      var incRange = "";

      if (Number(bet.changedOdds) >= 1 && Number(bet.changedOdds) <= 2) {
        incRange = 0.01;
      } else if (Number(bet.changedOdds) > 2 && Number(bet.changedOdds) <= 3) {
        incRange = 0.02;
      } else if (Number(bet.changedOdds) > 3 && Number(bet.changedOdds) <= 4) {
        incRange = 0.05;
      } else if (Number(bet.changedOdds) > 4 && Number(bet.changedOdds) <= 6) {
        incRange = 0.10;
      } else if (Number(bet.changedOdds) > 6 && Number(bet.changedOdds) <= 10) {
        incRange = 0.20;
      } else if (Number(bet.changedOdds) > 10 && Number(bet.changedOdds) <= 20) {
        incRange = 0.50;
      } else if (Number(bet.changedOdds) > 20 && Number(bet.changedOdds) <= 30) {
        incRange = 1.00;
      } else if (Number(bet.changedOdds) > 30 && Number(bet.changedOdds) <= 50) {
        incRange = 2.00;
      } else if (Number(bet.changedOdds) > 50 && Number(bet.changedOdds) <= 100) {
        incRange = 5.00;
      } else if (Number(bet.changedOdds) > 100) {
        incRange = 10.00;
      }
      bet.changedOdds = (Number(bet.changedOdds) - incRange).toFixed(2);
      bet.changedReturns = (bet.changedOdds * bet.changedStake).toFixed(2);
      this.appStore.calOpenBetsProfitLoss(bet, betType);
    }
  }
  stakeChanged(bet, betType, e) {
    /**
     * Enabling one field at a time. 
     */
    bet.isStakeEdited = true;
    bet.isOddsEdited = false;
    var minStake = '';
    var maxStake = '';
    if (bet.minStake > 0) {
      minStake = bet.minStake
    } else {
      minStake = 1;
    }
    if (bet.maxStake > 0) {
      maxStake = bet.maxStake;
    } else {
      maxStake = 100000;
    }
    var stake = e.target.value;
    if ((re.test(stake) && Number(stake) >= 1) || stake === "") {
      bet.changedStake = stake;
      bet.edited = true;
      if ((Number(stake) < minStake || Number(stake) > maxStake) || stake === "") {
        bet.stakeError = "Minimum stake: " + minStake + " and maximum stake:" + maxStake;
      } else {
        bet.stakeError = '';
        // if(bet.isFancy){
        //   this.calculateFancyReturns(selection);
        // }else{
        //   this.calculateReturns(selection);
        // }
        bet.changedReturns = this.calculateOpenBetsReturns(bet);
        this.appStore.calOpenBetsProfitLoss(bet, betType);
      }
    }
    //this.appStore.updateBetSlipStatus();
  }
  validateOpenBets(bets) {
    for (var bet of bets.backBets) {
      if (bet.odds < 1 || bet.stake === 0 || bet.stake === '') {
        return false;
      }
    }
    for (var bet of bets.layBets) {
      if (bet.odds < 1 || bet.stake === 0 || bet.stake === '') {
        return false;
      }
    }
    return true;
  }
  updateOpenbets(appStore) {
    //this.resetOpenBetSettings();
    // this.openbetsData = [];
    clearInterval(this.timeInterval)
    this.showOpenBetLoader = true;
    var token = cookie.load('UTocken')
    var params = {
      "userToken": cookie.load('UTocken'),
      "layBets": [],
      "backBets": []
    }
    this.isOpenBetsStakeErr = true;
    for (var openBetsEvent of this.openbetsData) {
      if (openBetsEvent.eventId === appStore.selectedOpenBetsEvent.eventId && openBetsEvent.unMatchedBets) {
        for (var bet of openBetsEvent.unMatchedBets.bets) {
          if (bet.hasOwnProperty("edited") && bet.edited) {
            if (bet.oddType.toLowerCase() === "back") {
              params.backBets.push({
                "betId": bet.betId,
                "odds": Number(bet.changedOdds),
                "stake": Number(bet.changedStake),
                "returns": Number(bet.changedReturns),
                "betfairBetId": bet.betfairBetId,
                "clientMarketId": bet.clientMarketId,
                "eventId": openBetsEvent.eventId,
                "outcomeId": bet.outcomeId
              })
              if (bet.stakeError && bet.stakeError.length > 0) {
                this.isOpenBetsStakeErr = false
              }
            } else {
              params.layBets.push({
                "betId": bet.betId,
                "odds": Number(bet.changedOdds),
                "stake": Number(bet.changedStake),
                "returns": Number(bet.changedReturns),
                "betfairBetId": bet.betfairBetId,
                "clientMarketId": bet.clientMarketId,
                "eventId": openBetsEvent.eventId,
                "outcomeId": bet.outcomeId
              });
              if (bet.stakeError && bet.stakeError.length > 0) {
                this.isOpenBetsStakeErr = false
              }
            }

          }
        }
      }
    }
    if (this.validateOpenBets(params) && this.isOpenBetsStakeErr) {
      apiCaller(masterPage.updateOpenBets, 'POST', params).then(response => {
        // if(response && response.data && response.data.length>0){
        //   this.openbetsData = response.data;
        //   for(var i=0;i< this.openbetsData.length;i++){
        //     if(this.openbetsData[i]['unMatchedBets']){
        //       for(var j=0;j<this.openbetsData[i]['unMatchedBets']['backBets'].length;j++){
        //         this.openbetsData[i]['unMatchedBets']['backBets'][j]['changedOdds'] = this.openbetsData[i]['unMatchedBets']['backBets'][j]['odds'];
        //         this.openbetsData[i]['unMatchedBets']['backBets'][j]['changedStake'] = this.openbetsData[i]['unMatchedBets']['backBets'][j]['stake'];
        //         this.openbetsData[i]['unMatchedBets']['backBets'][j]['changedReturns'] = this.openbetsData[i]['unMatchedBets']['backBets'][j]['returns'];
        //         this.openbetsData[i]['unMatchedBets']['backBets'][j]['stakeError'] = "";
        //       }
        //       for(var j=0;j<this.openbetsData[i]['unMatchedBets']['layBets'].length;j++){
        //         this.openbetsData[i]['unMatchedBets']['layBets'][j]['changedOdds'] = this.openbetsData[i]['unMatchedBets']['layBets'][j]['odds'];
        //         this.openbetsData[i]['unMatchedBets']['layBets'][j]['changedStake'] = this.openbetsData[i]['unMatchedBets']['layBets'][j]['stake'];
        //         this.openbetsData[i]['unMatchedBets']['layBets'][j]['changedReturns'] = this.openbetsData[i]['unMatchedBets']['layBets'][j]['returns'];
        //         this.openbetsData[i]['unMatchedBets']['layBets'][j]['stakeError'] = "";
        //       }
        //     }
        //   }
        // }
        this.showOpenBetLoader = false;
        //&& response.data.betUpdated
        if (response && response.data) {
          // this.resetOpen();
          if (response.data[0].betUpdated) {
            this.editOpenBets = false;
            this.openbetsData = [];
            //clearInterval(this.timeInterval);
            appStore.getUserBalanceDetails();
            //this.getOpenbets(this.appStore);
            this.selectTab('openbets', appStore);
          }
          else {
            this.showMessage(response.data[0].reasonForFailure, "error");
          }
        }
      }).catch(excepption => {
        this.editOpenBets = false;
        this.openbetsData = [];
        //clearInterval(this.timeInterval);
        //this.getOpenbets(this.appStore);
        this.selectTab('openbets', appStore);
      });
    } else {
      if (this.isOpenBetsStakeErr) {

      } else {
        this.openBetsErr = "Please enter stake and odds should >1"
      }


    }
  }
  resetOpen() {
    this.resetOpenBetSettings();
    for (var i = 0; i < this.openbetsData.length; i++) {
      if (this.openbetsData[i]['unMatchedBets']) {
        for (var j = 0; j < this.openbetsData[i]['unMatchedBets']['bets'].length; j++) {
          this.openbetsData[i]['unMatchedBets']['bets'][j]['changedOdds'] = this.openbetsData[i]['unMatchedBets']['bets'][j]['odds'];
          this.openbetsData[i]['unMatchedBets']['bets'][j]['changedStake'] = this.openbetsData[i]['unMatchedBets']['bets'][j]['stake'];
          this.openbetsData[i]['unMatchedBets']['bets'][j]['changedReturns'] = this.openbetsData[i]['unMatchedBets']['bets'][j]['returns'];
          this.openbetsData[i]['unMatchedBets']['bets'][j]['stakeError'] = "";
        }
        for (var j = 0; j < this.openbetsData[i]['unMatchedBets']['bets'].length; j++) {
          this.openbetsData[i]['unMatchedBets']['bets'][j]['changedOdds'] = this.openbetsData[i]['unMatchedBets']['bets'][j]['odds'];
          this.openbetsData[i]['unMatchedBets']['bets'][j]['changedStake'] = this.openbetsData[i]['unMatchedBets']['bets'][j]['stake'];
          this.openbetsData[i]['unMatchedBets']['bets'][j]['changedReturns'] = this.openbetsData[i]['unMatchedBets']['bets'][j]['returns'];
          this.openbetsData[i]['unMatchedBets']['bets'][j]['stakeError'] = "";
        }
      }
    }
    this.editOpenBets = false;
    this.appStore.clearBetslip();
  }
  cancelBets(betId, appStore) {
    this.callCancelBets([betId], appStore);
  }
  cancelAllBets(appStore) {
    var betIds = []
    for (var openBetsEvent of this.openbetsData) {
      if (openBetsEvent.eventId === appStore.selectedOpenBetsEvent.eventId && openBetsEvent.unMatchedBets) {
        for (var bet of openBetsEvent.unMatchedBets.bets) {
          betIds.push(bet.betId);
        }
        // for(var bet of openBetsEvent.unMatchedBets.bets){
        //   betIds.push(bet.betId);
        // }
      }
    }
    this.callCancelBets(betIds, appStore);
  }
  callCancelBets(betIds, appStore) {
    var params = {
      "userToken": cookie.load('UTocken'),
      "betIdList": betIds
    }
    if (params.betIdList.length > 0) {
      this.showOpenBetLoader = true;
      apiCaller(masterPage.cancelOpenBets, 'POST', params).then(response => {
        this.showOpenBetLoader = false;
        if (response && response.data && response.data.betCancelled) {
          this.openbetsData = [];
          this.getOpenbets(appStore);
          appStore.getUserBalanceDetails();
        }
      });
    }

  }
  /**
  * This method is used to select the bet.
  * @param {String} type contains type of bet (back or lay).
  * @param {Number} betId contains bet id to select.
  */
  selectOpenBet(betId) {
    this.selectedOpenBets.push(betId)
  }
  /**
  * This method is used to show the bet in edit mode.
  * @parm {Number} betId contains the bet id to show in edit mode
  */
  editOpenBet(bet) {
    // bet.changedOdds = bet.odds;
    // bet.changedStake = bet.stake;
    // bet.changedReturns = bet.returns;
    // this.editViewOpenBets.push(bet.betId);
    this.editOpenBets = true;
  }
  /**
  * This method is used to get the openbets for user.
  */
  getOpenbets(appStore) {
    //this.resetOpenBetSettings();
    appStore.openBetsEventIds = [];
    // this.openbetsData = [];
    var token = cookie.load('UTocken')
    if (token && token.length > 0) {
      if (this.openbetsData.length === 0) {
        this.showOpenBetLoader = true;
      }
      apiCaller(masterPage.getOpenbets + '?userToken=' + token, 'GET').then(response => {
        this.showOpenBetLoader = false;
        if (response && response.data && response.data.length > 0) {
          var userViewingEvent = this.filterOpenBetsData();
          var openBetsResponse = response.data;
          for (var i = 0; i < openBetsResponse.length; i++) {
            appStore.openBetsEventIds.push(openBetsResponse[i].eventId);
            if (openBetsResponse[i]['unMatchedBets']) {
              for (var j = 0; j < openBetsResponse[i]['unMatchedBets']['bets'].length; j++) {
                if (Object.keys(userViewingEvent).length > 0 && userViewingEvent.bets && userViewingEvent.bets.length > 0) {
                  for (var backBet of userViewingEvent.bets) {
                    if (backBet.betId == openBetsResponse[i]['unMatchedBets']['bets'][j]['betId']) {
                      openBetsResponse[i]['unMatchedBets']['bets'][j] = backBet;
                    }
                    else {
                      openBetsResponse[i]['unMatchedBets']['bets'][j]["isOddsEdited"] = true;
                      openBetsResponse[i]['unMatchedBets']['bets'][j]["isStakeEdited"] = true;
                      openBetsResponse[i]['unMatchedBets']['bets'][j]["edited"] = false;
                      openBetsResponse[i]['unMatchedBets']['bets'][j]['changedOdds'] = openBetsResponse[i]['unMatchedBets']['bets'][j]['odds'];
                      openBetsResponse[i]['unMatchedBets']['bets'][j]['changedStake'] = openBetsResponse[i]['unMatchedBets']['bets'][j]['stake'];
                      openBetsResponse[i]['unMatchedBets']['bets'][j]['changedReturns'] = openBetsResponse[i]['unMatchedBets']['bets'][j]['returns'];
                      openBetsResponse[i]['unMatchedBets']['bets'][j]['stakeError'] = "";
                    }
                  }
                }
                else {
                  openBetsResponse[i]['unMatchedBets']['bets'][j]["isOddsEdited"] = true;
                  openBetsResponse[i]['unMatchedBets']['bets'][j]["isStakeEdited"] = true;
                  openBetsResponse[i]['unMatchedBets']['bets'][j]["edited"] = false;
                  openBetsResponse[i]['unMatchedBets']['bets'][j]['changedOdds'] = openBetsResponse[i]['unMatchedBets']['bets'][j]['odds'];
                  openBetsResponse[i]['unMatchedBets']['bets'][j]['changedStake'] = openBetsResponse[i]['unMatchedBets']['bets'][j]['stake'];
                  openBetsResponse[i]['unMatchedBets']['bets'][j]['changedReturns'] = openBetsResponse[i]['unMatchedBets']['bets'][j]['returns'];
                  openBetsResponse[i]['unMatchedBets']['bets'][j]['stakeError'] = "";
                }
              }
              // for (var j = 0; j < openBetsResponse[i]['unMatchedBets']['bets'].length; j++) {
              //   if (Object.keys(userViewingEvent).length > 0 && userViewingEvent.bets && userViewingEvent.bets.length>0) {
              //     for (var layBet of userViewingEvent.bets) {
              //       if (layBet.betId == openBetsResponse[i]['unMatchedBets']['bets'][j]['betId']) {
              //         openBetsResponse[i]['unMatchedBets']['bets'][j] = layBet;
              //       }
              //       else {
              //         openBetsResponse[i]['unMatchedBets']['bets'][j]["isOddsEdited"] = true;
              //         openBetsResponse[i]['unMatchedBets']['bets'][j]["isStakeEdited"] = true;
              //         openBetsResponse[i]['unMatchedBets']['bets'][j]["edited"] = false;
              //         openBetsResponse[i]['unMatchedBets']['bets'][j]['changedOdds'] = openBetsResponse[i]['unMatchedBets']['bets'][j]['odds'];
              //         openBetsResponse[i]['unMatchedBets']['bets'][j]['changedStake'] = openBetsResponse[i]['unMatchedBets']['bets'][j]['stake'];
              //         openBetsResponse[i]['unMatchedBets']['bets'][j]['changedReturns'] = openBetsResponse[i]['unMatchedBets']['bets'][j]['returns'];
              //         openBetsResponse[i]['unMatchedBets']['bets'][j]['stakeError'] = "";
              //       }
              //     }
              //   }
              //   else {
              //     openBetsResponse[i]['unMatchedBets']['bets'][j]["isOddsEdited"] = true;
              //     openBetsResponse[i]['unMatchedBets']['bets'][j]["isStakeEdited"] = true;
              //     openBetsResponse[i]['unMatchedBets']['bets'][j]["edited"] = false;
              //     openBetsResponse[i]['unMatchedBets']['bets'][j]['changedOdds'] = openBetsResponse[i]['unMatchedBets']['bets'][j]['odds'];
              //     openBetsResponse[i]['unMatchedBets']['bets'][j]['changedStake'] = openBetsResponse[i]['unMatchedBets']['bets'][j]['stake'];
              //     openBetsResponse[i]['unMatchedBets']['bets'][j]['changedReturns'] = openBetsResponse[i]['unMatchedBets']['bets'][j]['returns'];
              //     openBetsResponse[i]['unMatchedBets']['bets'][j]['stakeError'] = "";
              // }
              // }
            }
          }

          this.openbetsData = openBetsResponse;
          if (!!appStore.selectedOpenBetsEvent.eventId && appStore.selectedOpenBetsEvent.eventId != undefined) {
            var openBetEventIndex = appStore.openBetsEventIds.indexOf(appStore.selectedOpenBetsEvent.eventId);
            if (openBetEventIndex === -1) {
              appStore.selectedOpenBetsEvent.eventName = this.openbetsData[0].eventName;
              appStore.selectedOpenBetsEvent.eventId = this.openbetsData[0].eventId;
            }
          }
          else {
            appStore.selectedOpenBetsEvent.eventName = this.openbetsData[0].eventName;
            appStore.selectedOpenBetsEvent.eventId = this.openbetsData[0].eventId;
          }
        }
        else {
          this.openbetsData = [];
        }
      }).catch(excepption => {
        this.openbetsData = [];
      });
    }
  }

  filterOpenBetsData() {
    var unMatchedBets = [];
    for (var item of this.openbetsData) {
      if (!!this.appStore.selectedOpenBetsEvent.eventId && this.appStore.selectedOpenBetsEvent.eventId != undefined &&
        this.appStore.selectedOpenBetsEvent.eventId === item.eventId) {
        unMatchedBets = item.unMatchedBets;
      }
    }
    return unMatchedBets;
  }
  /**
  * This method is used to calculate the total stake and returns.
  *
  */
  calStakeReturns() {
    var tStake = "0.00";
    var tReturns = "0.00"
    var totalTitle = "";
    if (this.betSlipItems.hasOwnProperty('backBets') && this.betSlipItems.backBets.length > 0) {
      totalTitle = "Profit";
      for (var bet of this.betSlipItems.backBets) {
        tStake = (Number(tStake) + Number(bet.userStake)).toFixed(2);
        tReturns = (Number(tReturns) + (Number(bet.returns) - Number(bet.userStake))).toFixed(2);
      }
      if (!isNaN(tStake)) {
        this.totalStake = tStake;
      }
      if (!isNaN(tReturns)) {
        this.totalReturns = tReturns;
      }
    }
    if (this.betSlipItems.hasOwnProperty('layBets') && this.betSlipItems.layBets.length > 0) {
      totalTitle = totalTitle.length > 0 ? totalTitle + '/Liability' : 'Liability'
      for (var bet of this.betSlipItems.layBets) {
        tStake = (Number(tStake) + (Number(bet.returns) - Number(bet.userStake))).toFixed(2);
        tReturns = (Number(tReturns) + Number(bet.userStake)).toFixed(2);
      }
      if (!isNaN(tStake)) {
        this.totalStake = tStake;
      }
      if (!isNaN(tReturns)) {
        this.totalReturns = tReturns;
      }

    }
    this.totalTitle = totalTitle;
    this.validateBets();
    // this.appStore.updateBetSlipStatus();
  }
  /**
  * This method is used to remove the bet from the betslip.
  * @param {number} betIndex- contains the bet index to remove.
  * @param {string} betType- contains the bet type string, 'BO' for back bet and 'LO' for lay bet. 
  */
  removeBet(betIndex, betType) {
    if (betIndex !== '' && betIndex >= 0 && (betType === 'BO' || betType === 'LO')) {
      var removedBet = {};
      var betSlipBets = [];
      if (betType === "BO") {
        betSlipBets = this.betSlipItems.backBets;
      } else if (betType === "LO") {
        betSlipBets = this.betSlipItems.layBets;
      }
      if (betSlipBets.length > 0 && betIndex < betSlipBets.length) {
        removedBet = betSlipBets.splice(betIndex, 1);
        if (removedBet && removedBet.length > 0) {
          this.removeBetLocal(removedBet[0], betType);
          this.appStore.removePL(removedBet[0]);
        }
      }
      if (betType === "BO") {
        this.betSlipItems.backBets = betSlipBets;
      } else if (betType === "LO") {
        this.betSlipItems.layBets = betSlipBets;
      }
    }
    this.validateBets();
    this.calStakeReturns();
    this.appStore.calProfitLoss();
  }
  /**
  * This method is used to remove the bet from local storage.
  * @param {Object} removeBet- contains bet information to remove.
  * @param {string} betType- contains the bet type string, 'BO' for back bet and 'LO' for lay bet 
  */
  removeBetLocal(removeBet, betType) {
    // console.log("removeBet==",removeBet,"==betType==",betType)
    if (localStorage && removeBet) {
      var localBets = JSON.parse(localStorage.getItem('betslip'));
      if (betType === 'BO' && localBets.hasOwnProperty('backBets')) {
        var newBackBets = this.filterLocalBets(localBets.backBets, removeBet);
        if (newBackBets) {
          localBets.backBets = newBackBets;
          localStorage.setItem('betslip', JSON.stringify(localBets));
        }
      }
      if (betType === 'LO' && localBets.hasOwnProperty('layBets')) {
        var newLayBets = this.filterLocalBets(localBets.layBets, removeBet);
        if (newLayBets) {
          localBets.layBets = newLayBets;
          localStorage.setItem('betslip', JSON.stringify(localBets));
        }
      }
      this.appStore.updateBetSlipStatus();
    }
  }
  /**
  * This method is used to filter the removed item from local bets
  * @param {Array} bets - contains the localbets.
  * @param {Object} removeBet - contains the bet information to remove.
  */
  filterLocalBets(bets, removeBet) {
    var newBackBets = [];
    for (var bet of bets) {
      // console.log(bet,"===",removeBet)
      if (bet.eventId === removeBet.eventId &&
        bet.marketId === removeBet.marketId &&
        bet.selectionId === removeBet.selectionId) {

      } else {
        newBackBets.push(bet)
      }
    }
    return newBackBets;
  }
  /**
  * This method is used to check and uncheck the confirm betslip
  *
  */
  confirmBetSlip(event) {
    this.showConfirm = event.target.checked;
  }
  /**
  * This method is used to place the bet.
  * if user selects the show confirm then it will open confirmation window.
  */
  placeBet() {

    if (!this.appStore.isLoggedIn) {
      this.appStore.showLogin()
    } else {
      this.validateBets();
      if (this.isValidBets) {
        if (this.showConfirm) {
          this.confirmBets = true;
        } else {
          this.saveBets();
        }
      }
    }
  }
  /**
  * This method is used to place the bet for one click.
  */
  placeOneClickBet(bet) {
    // if(this.appStore.userInfo.userAccountBalance > this.totalStake){
    // console.log("please one click bet===",bet)
    this.showLoader = true;
    this.countDown = 3;
    this.countDownText = "Please wait for "
    var countDownTimer = setInterval(() => {
      this.countDown--;
      if (this.countDown <= 0) {
        this.countDownText = "Please wait...";
      }
      if (this.countDown <= 0 && this.betsSaved) {
        this.showLoader = false;
        setTimeout(() => {
          this.betSlipError = ""
        }, 3000)
        clearInterval(countDownTimer);
      }
    }, 1000)
    var userStake = {};
    if (cookie.load("selectedStake")) {
      userStake = cookie.load("selectedStake");
    }
    var param = {
      "betDetails": [],
      "userToken": cookie.load('UTocken'),
      "oddsChangeAcceptanceFlag": 0,
      "totalReturns": Number((userStake.coinValue * bet.odds).toFixed(2)),
      "totalStake": userStake.coinValue
    }
    var obj = {
      "eventId": bet.eventId,
      "outcomeId": bet.selectionId,
      "marketId": bet.marketId,
      "odds": bet.odds,
      "oddType": bet.betType === 'BO' ? 'B' : 'L',
      "homeTeamId": bet.homeTeamId,
      "awayTeamId": bet.awayTeamId,
      "stakeAmount": userStake.coinValue,
      "returns": Number((userStake.coinValue * bet.odds).toFixed(2)),
      "oddsManualChangeFlag": 0,
      "isLive": bet.isLive,
      "clientMarketId": bet.clMarketId,
      "selectionId": bet.clOutcomeId,
      "providerId": bet.providerId,
      "originalOdds": bet.odds
    }
    param.betDetails.push(obj);
    var findbet = this.appStore.findPLBets(bet);
    this.appStore.betsProfitLoss = [findbet];
    this.appStore.calculatePL();
    this.callSaveBet(param)
    // }else{
    //   this.betSlipError= "Insufficient funds in your account"
    // }
  }
  /**
  * this method is used to save the bets.
  */
  saveBets() {
    //cookie.load('UTocken')
    this.betsSaved = false;
    this.showLoader = true;
    var isBetFair = false;

    //if(this.appStore.userInfo.userAccountBalance >= Number(this.totalStake)){

    if (this.isValidBets) {
      var param = {
        "betDetails": [],
        "userToken": cookie.load('UTocken'),
        "oddsChangeAcceptanceFlag": this.acceptOddsChange ? 1 : 0,
        "totalReturns": Number(this.totalReturns),
        "totalStake": Number(this.totalStake)
      }
      for (var bet of this.betSlipItems.backBets) {
        isBetFair = bet.isBetFair;
        var obj = {
          "eventId": bet.eventId,
          "outcomeId": bet.selectionId,
          "marketId": bet.marketId,
          "odds": bet.userOdds,
          "oddType": 'B',
          "homeTeamId": bet.homeTeamId,
          "awayTeamId": bet.awayTeamId,
          "stakeAmount": bet.userStake,
          "returns": bet.returns,
          "oddsManualChangeFlag": bet.userOdds === bet.odds ? 0 : 1,
          "isLive": bet.isLive,
          "clientMarketId": bet.clMarketId,
          "selectionId": bet.clOutcomeId,
          "providerId": bet.providerId,
          "originalOdds": bet.odds
        }
        param.betDetails.push(obj);
      }
      for (var bet of this.betSlipItems.layBets) {
        isBetFair = bet.isBetFair;
        var obj = {
          "eventId": bet.eventId,
          "outcomeId": bet.selectionId,
          "marketId": bet.marketId,
          "odds": bet.userOdds,
          "oddType": 'L',
          "homeTeamId": bet.homeTeamId,
          "awayTeamId": bet.awayTeamId,
          "stakeAmount": bet.userStake,
          "returns": bet.returns,
          "oddsManualChangeFlag": bet.userOdds === bet.odds ? 0 : 1,
          "isLive": bet.isLive,
          "clientMarketId": bet.clMarketId,
          "selectionId": bet.clOutcomeId,
          "providerId": bet.providerId,
          "originalOdds": bet.odds
        }
        param.betDetails.push(obj);
      }

      this.countDown = isBetFair ? 3 : 0;
      this.countDownText = isBetFair ? "Please wait for " : "Please wait...";
      var countDownTimer = setInterval(() => {
        this.countDown--;
        if (this.countDown <= 0) {
          this.countDownText = "Please wait...";
        }
        if (this.countDown <= 0 && this.betsSaved) {
          this.showLoader = false;
          setTimeout(() => {
            this.betSlipError = ""
          }, 3000)
          clearInterval(countDownTimer);
        }
      }, 1000)

      this.callSaveBet(param);
    }

    // }else{
    //   this.betsSaved=true
    //   var betslipMsg= "Insufficient funds in your account";
    //   this.showMessage(betslipMsg, "error");
    // }
  }
  callSaveBet(param) {

    apiCaller(masterPage.saveBets, 'POST', param).then(response => {
      this.betsSaved = true;
      if (response && response.hasOwnProperty('data')) {
        if (response && response.hasOwnProperty('data') &&
          response.data &&
          response.data.hasOwnProperty('betSlipResponseList') &&
          response.data.betSlipResponseList.length > 0 && response.data.isBetPlaced) {
          this.appStore.getBetsProfitLoss();
          //this.appStore.saveProfitLoss();
          // this.clearBetslip();
          this.clearSavedBets(response.data.betSlipResponseList);
          if (response.data.hasOwnProperty('remainingBalance') && response.data.remainingBalance !== null) {
            var userInfo = cookie.load("userInfo");
            userInfo.userAccountBalance = response.data.remainingBalance;
            cookie.save("userInfo", userInfo);
            this.appStore.userInfo = userInfo;
          }
          //let bettype = response.data.betSlipResponseList[0].matched ? "Matched" : "Un-Matched";
          let betslipMsg = "Your bets have been placed";// and "+bettype;
          // //this.betslipSuccess = betslipMsg
          // this.showMessage(betslipMsg, 'success')
          var betLength = param.betDetails.length;
          if (betLength === 1) {
            var bet = param.betDetails[0];
            this.placedBetInfo = {
              type: bet.oddType,
              stake: bet.stakeAmount,
              odds: response.data.betSlipResponseList[0].odds,
              isSingleBet: true
            }
            let bettype = response.data.betSlipResponseList[0].matched ? "Matched" : "Un-Matched";
            this.placedBetInfo.isMatched = response.data.betSlipResponseList[0].matched;
            betslipMsg = "Your bets have been placed and " + bettype;
          }
          this.showMessage(betslipMsg, "success");
          this.appStore.getUserBalanceDetails();
        } else if (response && response.hasOwnProperty('data') &&
          response.data &&
          response.data.hasOwnProperty('reasonForFailure') &&
          response.data.reasonForFailure.length > 0) {
          var betSlipError = response.data.reasonForFailure;
          this.showMessage(betSlipError, 'error')
        } else {
          var betSlipError = "";
          //((a||{}).b||{}).c
          if (((response || {}).data || {}).betPlaced) {
            betSlipError = response.data.betSlipResponseList[0].betStatus;
          } else if (response && response.hasOwnProperty("data") && response.data &&
            response.data.hasOwnProperty("betSlipResponseList") && response.data.betSlipResponseList.length > 0) {
            betSlipError = response.data.betSlipResponseList[0].errorCode;
          } else if (response && !response.data && response.description) {
            betSlipError = response.description
          }
          else {
            betSlipError = "bet(s) not placed";
          }
          this.showMessage(betSlipError, 'error')
        }
      }
    });
  }
  clearSavedBets(saveBetsResponse) {
    //stakeError
    var backBetIndex = -1;
    var layBetIndex = -1;
    var removeBets = [];

    for (var i = 0; i < this.betSlipItems.backBets.length; i++) {
      for (var saveBet of saveBetsResponse) {
        if (saveBet.eventId === this.betSlipItems.backBets[i]['eventId'] && saveBet.selectionId === this.betSlipItems.backBets[i]['clOutcomeId'] && saveBet.oddType === 'B') {
          if (saveBet.betStatus.toLowerCase() === 'success') {
            removeBets.push({
              betIndex: i,
              betType: 'BO'
            })
          } else {
            this.betSlipItems.backBets[i]['stakeError'] = saveBet.errorCode;
          }
        }
      }
    }
    for (var i = 0; i < this.betSlipItems.layBets.length; i++) {
      layBetIndex++;
      for (var saveBet of saveBetsResponse) {
        if (saveBet.eventId === this.betSlipItems.layBets[i]['eventId'] && saveBet.selectionId === this.betSlipItems.layBets[i]['clOutcomeId'] && saveBet.oddType === 'L') {
          if (saveBet.betStatus.toLowerCase() === 'success') {
            removeBets.push({
              betIndex: i,
              betType: 'LO'
            })
          } else {
            this.betSlipItems.layBets[i]['stakeError'] = saveBet.errorCode;
          }
        }
      }
    }
    if (removeBets.length > 0) {
      //all bets are placed
      this.showBetsSuccess = true;
      let betslipMsg = "Your bets have been placed";
      this.showMessage(betslipMsg, 'success')
    }
    var index = removeBets.length;
    while (index--) {
      var rmBet = removeBets[index];
      this.removeBet(rmBet.betIndex, rmBet.betType);
      removeBets.splice(index, 1);
    }
    // for(var rmBet of removeBets){
    //  this.removeBet(rmBet.betIndex, rmBet.betType);
    // }
    this.appStore.getBetsProfitLoss();

  }
  /**
  * this method is used to clear betslip.
  */
  clearBetslip() {
    var betSlipItems = {
      backBets: [],
      layBets: []
    }
    this.betSlipItems = betSlipItems;
    localStorage.setItem("betslip", JSON.stringify(betSlipItems));
    this.acceptOddsChange = false;
    this.showConfirm = false;
    this.confirmBets = false;
    this.betSlipError = "";
    this.appStore.updateBetSlipStatus();
    this.appStore.betsProfitLoss = [];
    this.appStore.pLStatusChanged = !this.appStore.pLStatusChanged;
    this.appStore.getBetsProfitLoss();
    this.hideMessage()
    //this.appStore.calProfitLoss();
  }
  /**
  * This method is used to go back to bet betslip.
  */
  goBackToBetSlip() {
    this.confirmBets = false;
  }
  /**
  * This method is used to set accept odds change.
  * @param {Object} event - contains the element value
  */
  changeAcceptOddsChange(event) {
    // this.acceptOddsChange = event.target.checked;
  }
  /**
  * this method is used to get the bets from service using local storage information.
  */
  getBets() {

    /*   var param={
                     "backBets": [],
                     "layBets": []
                 }
       var defaultStake= localStorage.getItem('defaultStake');
       if(localStorage){
         var betSlipItems=JSON.parse(localStorage.getItem('betslip'));
         if(betSlipItems && betSlipItems.hasOwnProperty('backBets')){
             for(var bet of betSlipItems.backBets){
               var obj={
                           "eventId": bet.eventId,
                           "marketId": bet.marketId,
                           "outcomeId": bet.selectionId,
                           "oddType": bet.betType==='BO'?'b':'l',
                           "odds":bet.odds
                       }
               param.backBets.push(obj)
             }
         }
         if(betSlipItems && betSlipItems.hasOwnProperty('layBets')){
             for(var bet of betSlipItems.layBets){
               var obj={
                           "eventId": bet.eventId,
                           "marketId": bet.marketId,
                           "outcomeId": bet.selectionId,
                           "oddType": bet.betType==='BO'?'b':'l',
                           "odds":bet.odds
                       }
               param.layBets.push(obj)
             }
         }
         if(param.backBets.length>0 || param.layBets.length>0){
           apiCaller(masterPage.getBets,'POST',param).then(response => {
             if(response && response.hasOwnProperty('data') && response.data){
                 if(response.data.hasOwnProperty('backBets') && response.data.backBets){
                   for(var bet of response.data.backBets){
                     var obj = {
                                 eventId: bet.eventId,
                                 marketId: bet.marketId,
                                 selectionId:bet.outcomeId,
                                 eventName:bet.eventName,
                                 marketName:bet.marketName,
                                 odds:bet.odds,
                                 userOdds: bet.odds,
                                 userStake:defaultStake?defaultStake:"",
                                 returns:defaultStake?bet.isFancy?this.calculateFancyReturns(bet):(defaultStake*bet.odds).toFixed(2):"0.00",
                                 selectionName:bet.outcomeName,
                                 homeTeam:bet.homeTeamName,
                                 awayTeam:bet.awayTeamName,
                                 betType:'BO',
                                 isFancy:bet.providerId===1 || bet.providerId === 3?true:false,
                                 isBetFair:bet.providerId===2?true:false,
                                 providerId:bet.providerId,
                                 isLive:bet.isLive,
                                 clMarketId:bet.clientMarketId,
                                 clOutcomeId:bet.selectionId,
                                 minStake:bet.minStake,
                                 maxStake:bet.maxStake,
                                 stakeError:""
                               }
                   this.betSlipItems.backBets.push(obj);
                   }
                 }
                 if(response.data.hasOwnProperty('layBets') && response.data.layBets){
                   for(var bet of response.data.layBets){
                       var obj = {
                                 eventId: bet.eventId,
                                 marketId: bet.marketId,
                                 selectionId:bet.outcomeId,
                                 eventName:bet.eventName,
                                 marketName:bet.marketName,
                                 odds:bet.odds,
                                 userOdds: bet.odds,
                                 userStake:defaultStake?defaultStake:"",
                                 selectionName:bet.outcomeName,
                                 homeTeam:bet.homeTeamName,
                                 awayTeam:bet.awayTeamName,
                                 returns:defaultStake?bet.isFancy?this.calculateFancyReturns(bet):(defaultStake*bet.odds).toFixed(2):"0.00",
                                 betType:'LO',
                                 isFancy:bet.providerId===1 || bet.providerId === 3?true:false,
                                 isBetFair:bet.providerId===2?true:false,
                                 providerId:bet.providerId,
                                 isLive:bet.isLive,
                                 clMarketId:bet.clientMarketId,
                                 clOutcomeId:bet.selectionId,
                                 minStake:bet.minStake,
                                 maxStake:bet.maxStake,
                                 stakeError:""
                               }
                       this.betSlipItems.layBets.push(obj);
                   }
                 }
                 this.validateBets();
                 if(response.data.hasOwnProperty('userComments') && 
                    response.data.userComments &&
                    response.data.userComments.length>0){
                   this.betSlipError=response.data.userComments;
                   this.validateLocalBets();
                 }
             }else{
               localStorage.setItem('betslip',JSON.stringify({backBets:[],layBets:[]})) 
             }
           });
         }
         
       }*/
  }
  /**
  * This method is used to calculate the fancy bets returns
  *
  */
  calculateFancyReturns(selection) {
    var defaultStake = localStorage.getItem('defaultStake');
    if (selection.odds && selection.odds > 0 && defaultStake) {
      var returns = (Number(selection.odds) * Number(defaultStake)) / 100;
      var totalReturns = returns + Number(defaultStake);
      return totalReturns.toFixed(2);
    }
    return "0.00"
  }
  /**
  * This method is used to check bets are validate or not
  *
  */
  validateBets() {
    var validBets = true;
    if (this.betSlipItems.hasOwnProperty('backBets') &&
      this.betSlipItems.backBets.length > 0 &&
      this.betSlipItems.hasOwnProperty('layBets') &&
      this.betSlipItems.layBets.length > 0) {
      for (var backBet of this.betSlipItems.backBets) {
        for (var layBet of this.betSlipItems.layBets) {
          if (backBet.eventId === layBet.eventId &&
            backBet.marketId === layBet.marketId &&
            backBet.selectionId === layBet.selectionId &&
            backBet.providerId !== 1 &&
            backBet.providerId !== 3 &&
            backBet.providerId !== 4 &&
            layBet.providerId !== 1 &&
            layBet.providerId !== 3 &&
            layBet.providerId !== 4 &&
            backBet.userOdds < layBet.userOdds) {
            var betSlipError = "Your back odds should not less then your lay odds for " + backBet.eventName + " ," + backBet.marketName;
            this.showMessage(betSlipError, 'error')
            validBets = false;
            break;
          }
        }
        if (validBets) {
          validBets = true;
          this.betSlipError = ""
        } else {
          validBets = false;
        }
      }
    } else {
      validBets = true;
      this.betSlipError = ""
    }
    // check stake
    if (validBets) {
      this.betSlipError = "";
      if (this.betSlipItems.hasOwnProperty('backBets') && this.betSlipItems.backBets.length > 0) {
        for (var bet of this.betSlipItems.backBets) {
          var minStake = bet.minStake > 0 ? bet.minStake : 1;
          var maxStake = bet.maxStake > bet.minStake && bet.maxStake > 0 ? bet.maxStake : 100000000;
          if ((bet.userStake < minStake || bet.userStake > maxStake) || bet.userStake === "") {
            validBets = false;
            break;
          }
        }
      }
      if (this.betSlipItems.hasOwnProperty('layBets') && this.betSlipItems.layBets.length > 0) {
        for (var bet of this.betSlipItems.layBets) {
          var minStake = bet.minStake > 0 ? bet.minStake : 1;
          var maxStake = bet.maxStake > bet.minStake && bet.maxStake > 0 ? bet.maxStake : 100000000;
          if ((bet.userStake < minStake || bet.userStake > maxStake) || bet.userStake === "") {
            validBets = false;
            break;
          }
        }
      }
      if (validBets) {
        this.isValidBets = true;
      } else {
        this.isValidBets = false;
      }
    } else {
      this.isValidBets = false
    }
  }
  /**
  * this method is used to remove the invalid local bets.
  */
  validateLocalBets() {
    var newLocalItems = {
      backBets: [],
      layBets: []
    }
    var betSlipItems = JSON.parse(localStorage.getItem('betslip'));
    if (betSlipItems && betSlipItems.hasOwnProperty('backBets')) {
      for (var bet of betSlipItems.backBets) {
        var isFound = false;
        for (var bBet of this.betSlipItems.backBets) {
          if (bet.eventId === bBet.eventId &&
            bet.marketId === bBet.marketId &&
            bet.selectionId === bBet.selectionId) {
            isFound = true;
          }
        }
        if (isFound) {
          newLocalItems.backBets.push(bet)
        }
      }
    }
    if (betSlipItems && betSlipItems.hasOwnProperty('layBets')) {
      for (var bet of betSlipItems.layBets) {
        var isFound = false;
        for (var bBet of this.betSlipItems.backBets) {
          if (bet.eventId === bBet.eventId &&
            bet.marketId === bBet.marketId &&
            bet.selectionId === bBet.selectionId) {
            isFound = true;
          }
        }
        if (isFound) {
          newLocalItems.layBets.push(bet)
        }
      }
    }
    localStorage.setItem('betslip', JSON.stringify(newLocalItems));
  }
  /**
  *
  */
  checkTimer() {
    //  console.log("checkTimer ", this)
  }

  showMessage(msg, msgType) {
    this.msgInfo = msg;
    this.msgType = msgType;
    var self = this;
    setTimeout(function () { self.hideMessage() }, 10 * 1000);
  }
  hideMessage() {
    this.msgInfo = '';
    this.msgType = '';
    this.placedBetInfo = {
      type: '',
      stake: '',
      odds: '',
      isMatched: '',
      isSingleBet: false
    }
  }

  loadStakeCoins() {
    if (this.appStore.isLoggedIn && localStorage && localStorage.getItem('stakeCoins')) {
      this.stakeCoins = JSON.parse(localStorage.getItem('stakeCoins')).coins;
    } else {
      this.stakeCoins = [
        { "coinValue": 10 },
        { "coinValue": 50 },
        { "coinValue": 100 },
        { "coinValue": 200 },
        { "coinValue": 300 },
        { "coinValue": 400 }
      ]
    }
  }
  resetOpenBetSettings() {
    this.isStakeEdited = true;
    this.isOddsEdited = true;
  }
  updateBetSlipOdds(data) {
    var market = data;
    for (var betType in this.betSlipItems) {
      if (this.betSlipItems[betType] && this.betSlipItems[betType].length > 0) {
        for (var bet of this.betSlipItems[betType]) {
          if (bet?.clMarketId === market?.marketId) {
            if(market?.runners){
            for (var outcome of market?.runners) {
              if (outcome.selectionId === Number(bet.clOutcomeId) && !bet.isOddsChange) {
                var changedBackOdds = 0;
                var backOddsIndex = 0
                if (bet.betType === "BO") {
                  for (var backSelection of outcome.ex.availableToBack) {
                    if (backOddsIndex < 3) {
                      if (backSelection.price > changedBackOdds) {
                        changedBackOdds = backSelection.price;
                      }
                    }
                    backOddsIndex++;
                  }
                  if (changedBackOdds > 1 && changedBackOdds > bet.userOdds) {
                    //  self.checkOddsUpdate(self.props.outcome.backOdds,changedBackOdds,'BO');
                    //  self.props.outcome.backOdds = changedBackOdds;
                    bet.userOdds = changedBackOdds;
                  } else if (changedBackOdds < 1) {
                    //  self.props.outcome.backOdds = '-';
                    bet.userOdds = '-';
                  }
                }
                else {
                  var changedLayOdds = 0;
                  var layOddsIndex = 0
                  for (var laySelection of outcome.ex.availableToLay) {
                    if (layOddsIndex < 1) {
                      if (layOddsIndex === 0) {
                        changedLayOdds = laySelection.price
                      } else if (laySelection.price < changedLayOdds) {
                        changedLayOdds = laySelection.price
                      }
                    }
                    layOddsIndex++;
                  }
                  if (changedLayOdds > 1 && changedLayOdds < bet.userOdds) {
                    //  self.checkOddsUpdate(self.props.outcome.layOdds,changedLayOdds,'LO');
                    //  self.props.outcome.layOdds = changedLayOdds;
                    bet.userOdds = changedLayOdds;
                  } else if (changedLayOdds < 1) {
                    //  self.props.outcome.layOdds = '-';
                    bet.userOdds = '-';
                  }
                }
              }
            }
          }
          }
        }
        localStorage.setItem("betslip", JSON.stringify(this.betSlipItems));
      }
    }
  }
}

export default BetSlipComponentStore;
