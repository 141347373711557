import React, { Component } from 'react';
import { observer } from 'mobx-react';
import cookie from 'react-cookies';

class EventViewOddsComponent extends Component {
  constructor(props) {
    super(props)
    this.layOddsInc = false;
    this.layOddsDec = false;
    this.backOddsInc = false;
    this.backOddsDec = false;
    this.isSelected = false;
  }
  componentWillMount() {
    this.selectedBetsLocal()
  }
  componentWillReceiveProps(nextProps) {
    if (cookie.load('oddsHighights') && cookie.load('oddsHighights') === 'true') {
      if (this.props.outcome.backOdds !== nextProps.outcome.backOdds) {
        if (nextProps.outcome.backOdds < this.props.outcome.backOdds) {
          this.backOddsDec = true;
        } else {
          this.backOddsInc = true;
        }
        setTimeout(() => {
          this.removeOddsChange();
        }, 3000)
      }
      if (this.props.outcome.layOdds !== nextProps.outcome.layOdds) {
        if (nextProps.outcome.layOdds < this.props.outcome.layOdds) {
          this.layOddsDec = true;
        } else {
          this.layOddsInc = true;
        }
        setTimeout(() => {
          this.removeOddsChange();
        }, 3000)
      }
    }

    if (this.props.betSlipStatusChange !== nextProps.betSlipStatusChange) {
      this.selectedBetsLocal();
    }
  }
  /**
  * this method is used to remove the odds change.
  */
  removeOddsChange() {
    this.backOddsDec = false;
    this.layOddsInc = false;

  }
  /**
  * This method is used to get the bets from local
  *
  */
  selectedBetsLocal() {
    if (localStorage) {
      var betSlipItems = JSON.parse(localStorage.getItem('betslip'));
      if (betSlipItems && betSlipItems.hasOwnProperty('backBets') && this.props.oddsType === 'BO') {
        var isFound = false;
        for (var bet of betSlipItems.backBets) {
          if (bet.eventId === this.props.eventId &&
            bet.marketId === this.props.marketId &&
            bet.selectionId === this.props.outcomes.id &&
            Number(bet.odds) === this.props.outcome.odds) {
            this.props.outcome.isSelected = true;
            isFound = true;
          } else {
            // this.props.outcome.isSelected = false;
          }
          if (!isFound) {
            this.props.outcome.isSelected = false;
          }
        }
        if (betSlipItems.backBets.length === 0) {
          this.props.outcome.isSelected = false;
        }
      }

      if (betSlipItems && betSlipItems.hasOwnProperty('layBets') && this.props.oddsType === 'LO') {
        var isFound = false;
        for (var bet of betSlipItems.layBets) {
          if (bet.eventId === this.props.eventId &&
            bet.marketId === this.props.marketId &&
            bet.selectionId === this.props.outcomes.id &&
            Number(bet.odds) === this.props.outcome.odds) {
            this.props.outcome.isSelected = true;
            isFound = true;
          } else {
            // this.props.outcome.isSelected = false;
          }
        }
        if (!isFound) {
          this.props.outcome.isSelected = false;
        }
        if (betSlipItems.layBets.length === 0) {
          this.props.outcome.isSelected = false;

        }
      }
    }
  }
  /**
  * This method is used to add the bet to betslip.
  *
  */
  addToBetSlip(betType) {
    if (this.props.outcome.odds < 1 || isNaN(this.props.outcome.odds) || !this.props.showOdds) {
      return
    }
    var obj = {
      eventId: this.props.eventId,
      clEventId: this.props.eventId,
      marketId: this.props.marketId,
      clMarketId: this.props.clMarketId,
      selectionId: this.props.outcomes.id,
      clOutcomeId: this.props.outcomes.clOutcomeId,
      homeTeamId: this.props.homeTeamId,
      awayTeamId: this.props.awayTeamId,
      eventName: this.props.eventName,
      marketName: this.props.marketName,
      odds: this.props.outcome.odds,
      selectionName: this.props.component === 'fancy' ? this.props.outcomes.name + '-' + this.props.outcome.outcome : this.props.outcomes.name,
      betType: this.props.oddsType,
      minStake: this.props.minStake,
      maxStake: this.props.maxStake,
      homeTeam: this.props.homeTeam,
      awayTeam: this.props.awayTeam,
      kickOffTime: this.props.kickOffTime,
      isLive: this.props.isLive,
      stakeError: "",
      providerId: this.props.providerId,
      isFancy: this.props.providerId === 3 ? true : false, //this.props.providerId===1 || 
      isBetFair: this.props.providerId === 2 ? true : false,
      subMarket: this.props.subMarket ? this.props.subMarket : null,
      isOddsChange: false
    }
    var isSelected = "";
    this.isSelected = !this.isSelected;
    isSelected = this.isSelected;
    var isAdded = this.props.appStore.addToBetSlip(obj, betType, !isSelected);
    this.props.outcome.isSelected = isAdded ? true : false;
  }
  render() {
    const { outcome, outcomeIndex, oddsType, component, showOdds } = this.props;
    var BOClasses = '';
    if (component !== 'fancy' && component !== 'adminfancy' && component !== "popular" && component !== "election") {
      BOClasses = (oddsType === "BO" ? "btn RDE-btnOdds RDE-btnBackOdds RDE-btnBackOdds_" : "btn RDE-btnOdds RDE-btnLayOdds RDE-btnLayOdds_");
      switch (outcomeIndex) {
        case 0:
          BOClasses = oddsType === "BO" ? BOClasses + "_three" : BOClasses + "_one";
          break;
        case 1:
          BOClasses = BOClasses + "_two";
          break;
        case 2:
          BOClasses = oddsType === "BO" ? BOClasses + "_one" : BOClasses + "_three";
          break;
        default:
          BOClasses = oddsType === "BO" ? BOClasses + "_one" : BOClasses + "_three";
      }
    } else if (component === 'fancy' || component === 'adminfancy' || component === 'election') {
      BOClasses = (oddsType === "BO" ? "btn RDE-btnOdds RDE-btnBackOdds RDE-btnBackOdds_one" : "btn RDE-btnOdds RDE-btnLayOdds RDE-btnLayOdds_one");
    } else if (component === 'popular') {
      BOClasses = (oddsType === "BO" ? "btn RDE-btnOdds RDE-btnBackOdds" : "btn RDE-btnOdds RDE-btnLayOdds");
    }

    if (this.props.eventBlocked || this.props.marketBlocked) {
      BOClasses = "RDE-btnOddsDisabled";
    }
    if (this.backOddsInc) {
      BOClasses += " RDE-btnOddsIncreased";
    } else if (this.backOddsDec) {
      BOClasses += " RDE-btnOddsDecreased";
    }
    let selected = outcome.isSelected;
    if (selected === true) {
      BOClasses += " active";
    }
    var odds = component === 'popular' ? (Number(outcome.odds)).toFixed(2) : (Number(outcome.odds)).toFixed(2)
    return (
      <button className={BOClasses} onClick={this.addToBetSlip.bind(this)}>
        <div><b>{component === 'fancy' ? outcome.outcome : showOdds && Number(outcome.odds) > 1 ? (Number(outcome.odds)).toFixed(2) : '-'}</b></div>
        {component === 'fancy' ? Number(outcome.odds) > 1 ? (Number(outcome.odds)).toFixed(2) : '-' : showOdds ? <div>{outcome.size ? outcome.size : ''}</div> : ""}
        {/**&& component!=='popular' */}
      </button>
    )
  }
}
export default observer(EventViewOddsComponent);
