import { extendObservable } from "mobx";
import { scoreCard, interval } from "../../sharedfiles/EndpointConfig";
import { apiCaller } from '../../sharedfiles/apiStore';

class ScoreCardStore {
  constructor(props) {
    extendObservable(this, {
      data: {
        "message": "Success.",
        "code": 0,
        "error": false,
        "data": [
          {
            "p1b": 28,
            "p2b": "0",
            "bd": 300,
            "nr": 5,
            "ap": "p1",
            "p1r": 55,
            "rb": 0,
            "p2r": "0",
            "p14": "2",
            "p24": "0",
            "s": 318,
            "p16": 5,
            "p26": "0",
            "lb": ".1.1.2 | 4.2.1.1.4.2 | 1.wd.W.6.6.0.1 | ",
            "w": 8,
            "cb": "Bangladesh won by 4 runs"
          },
          {
            "pp": 0,
            "p1": "Donald Tiripano",
            "st": "Zimbabwe",
            "b": "",
            "p2": "Carl Mumba",
            "rt": "Bangladesh",
            "c": "Bangladesh won the toss opted to bat\n\nDaily Cricket News - https://www.youtube.com/watch?v=TtFslm5QIvU\n\nBangladesh: 322/8 (50.0)\nZimbabwe: 318/8 (50.0)\n\nBangladesh won by 4 runs",
            "i": 2,
            "lw": "Tinotenda Mutombodzi 34 (21)",
            "tb": 300,
            "sd": "1",
            "rd": "5",
            "t": "0",
            "so": "0"
          },
          {
            "ballsdone": 300,
            "ballsdone2": 300,
            "comment": "Bangladesh won by 4 runs",
            "date": "3 Mar, 12:30 PM",
            "flag1": "https://storage.googleapis.com/ce_flags/v1/Bangladesh.png",
            "flag2": "https://storage.googleapis.com/ce_flags/v1/Zimbabwe.png",
            "inning": 2,
            "match_number": "2nd ODI",
            "order": 0,
            "rate": 0,
            "rate2": 1,
            "rate_team": "Bangladesh",
            "score": 318,
            "score2": "322",
            "series_name": "Zimbabwe tour of Bangladesh 2020",
            "status": 2,
            "t1": "BAN",
            "t2": "ZIM",
            "target": "0",
            "team1": "Bangladesh",
            "team2": "Zimbabwe",
            "title": "Bangladesh vs Zimbabwe",
            "total_balls": 300,
            "type": 0,
            "venue": "",
            "who": 0,
            "wicket": 8,
            "wicket2": "8"
          }
        ]
      },
      scoreData: {},
      scoreInterval: '',
      showLoader: false
    });
  }
  getScores(sportId, eventId) {
    this.scoreData = {};
    this.showLoader = true;
    if (this.scoreInterval) {
      clearInterval(this.scoreInterval);
    }
    // if (sportId == 4) {
    //   this.getCricketScore(eventId);
    //   this.scoreInterval = setInterval(() => {
    //     this.getCricketScore(eventId);
    //   }, interval.liveScore);
    // }
    // else {
    //   this.getOtherSportScore(eventId);
    //   this.scoreInterval = setInterval(() => {
    //     this.getOtherSportScore(eventId);
    //   }, interval.liveScore);

    // }
  }
  getCricketScore(eventId) {
    apiCaller(scoreCard.cricketScore + "?matchid=" + eventId, 'GET').then(response => {
      if (response && response.data) {
        this.showOdds = true;
        if (!response.data.error) {
          var scoreData = {}
          response.data.map(function (obj) {
            scoreData = Object.assign({}, scoreData, obj);
          });
          this.scoreData = scoreData;
          this.scoreData.overs1 = this.calculateOvers(this.scoreData.ballsdone);
          this.scoreData.overs2 = this.calculateOvers(this.scoreData.ballsdone2);
          this.scoreData.previousOvers = this.getPreviousOvers(this.scoreData.lb);
        }
      }
      this.showLoader = false;
    });

  }
  getOtherSportScore(eventId) {
    apiCaller(scoreCard.otherSportScores + "?matchid=" + eventId, 'GET').then(response => {
      if (response && response.length > 0) {
        this.showOdds = true;
        this.scoreData = response[0];
      }
      this.showLoader = false;
    });
  }
  calculateOvers(totalBalls) {
    var overs = (Math.floor(totalBalls / 6));
    var ball = (totalBalls % 6)
    if (ball > 0) {
      overs = overs + '.' + ball
    }
    return overs;

  }

  getPreviousOvers(lastBalls) {
    var self = this;
    var po = lastBalls.split('|');
    //var lo = po[0];
    //this.scoreData.lastOver = lo.split(' ').reverse();
    var previousOvers = []
    po.map(function (over, index) {
      if (index === po.length - 1) {
        self.scoreData.lastOver = over.trim().split('.');
      }
      else {
        previousOvers.push(over.trim().split(' '));
      }

    });
    return previousOvers;

    //this.scoreData.previousOvers = previousOvers;

  }


}

export default ScoreCardStore;
