import { extendObservable } from "mobx";
import Validator from 'validatorjs';

import { getAuthenticationNumber } from "../../sharedfiles/helper";
import { signup } from "../../sharedfiles/EndpointConfig";
import { apiCaller } from '../../sharedfiles/apiStore';

const re = /^[0-9\b]+$/;
const userRe = /^[A-Za-z0-9 ]+$/;

class LoginSignupStore {
  constructor(props) {
    extendObservable(this, {
      data: {},
      showSignup: true,
      authNumber: '',
      email: "",
      password: "",
      reAuthNumber: '',
      errorMsg: '',
      signupFields: {
        email: "",
        name: "",
        phoneNumber: "",
        password: "",
        confirmPassword: ""
      },
      signupErrors: {
        email: "",
        name: "",
        phoneNumber: "",
        password: "",
        confirmPassword: ""
      },
      forgotFields: {
        email: "",
        mobile: ""
      },
      forgotFieldsError: {
        email: "",
        mobile: ""
      },
      appStore: {},
      isEmailError: false,
      showSignupSuccess: false,
      showSignUpLoader: false,
      showLoginLoader: false,
      showForgotpassword: false,
      showForgotSuccess: false,
      showForgotLoader: false,
      forgotMessage: "",
      signupErr: "",
      loginSignupProps: {}
    });

  }
  /**
  * This method is called when user clicks on login or register for toggle display.
  *
  */
  toggleLoginSignup() {
    this.resetFields();
    this.showSignup = !this.showSignup;

  }
  /**
  * This method is used to reset the login and signup fields
  *
  */
  resetFields() {
    this.email = '';
    this.password = '';
    this.reAuthNumber = '';
    this.isEmailError = false;
    this.errorMsg = '';
    this.signupErr = '';
    this.showSignupSuccess = false;
    this.showForgotpassword = false;
    this.signupFields = {
      email: "",
      name: "",
      phoneNumber: "",
      password: "",
      confirmPassword: ""
    };
    this.signupErrors = {
      email: "",
      name: "",
      phoneNumber: "",
      password: "",
      confirmPassword: ""
    };
    this.forgotFields = {
      email: "",
      mobile: ""
    };
    this.forgotFieldsError = {
      email: "",
      mobile: ""
    }
  }
  /**
  * This method is called when there is a change in the login fields(onchange event).
  * this method reads user typed value and assigns to respective state variable 
  * @param -field - {string}- this param contains the field name in which change occered 
  * @param -event- {js event object }- which contains user typed authentication number.
  */
  onLoginFiledChange(field, event) {
    switch (field) {
      case 'email':
        this.email = event.target.value;
        break;
      case 'password':
        this.password = event.target.value;
        break;
      case 'reAuthNumber':
        if (event.target.value === '' || re.test(event.target.value)) {
          this.reAuthNumber = event.target.value;
        }
        break;
      default:
        console.log("signup default");
    }
  }
  /**
  * This method is called when user clicks login button.
  * this method validate fileds
  * first it validates the authentication number
  * and it calls main login method in the app store
  */
  login(settingsStore) {

    var data = {
      emailId: this.email,
      password: this.password
    };
    this.appStore.validateLoginFields(data, (isValid) => {
      if (isValid) {
        if (Number(this.authNumber) === Number(this.reAuthNumber)) {
          this.showLoginLoader = true;
          this.appStore.mainLogin(data, (isSuccess, des) => {
            this.showLoginLoader = false;
            if (isSuccess) {
              this.email = "";
              this.password = "";
              this.errorMsg = "";
              settingsStore.getSettingsValue();
              this.loginSignupProps.history.push('/');
            } else if (des?.length !== 2) {
              this.errorMsg = des;
              this.authNumber = getAuthenticationNumber();
            }
            else {
              this.errorMsg = 'Invalid email id/user name or password.';
              this.authNumber = getAuthenticationNumber();
            }
            this.reAuthNumber = '';
          });
        } else {
          this.errorMsg = 'Invalid authentication number.';
          this.authNumber = getAuthenticationNumber();
          this.reAuthNumber = '';
        }
      } else {
        this.errorMsg = 'Invalid email id/user name or password.';
        this.authNumber = getAuthenticationNumber();
      }
      this.reAuthNumber = '';
    });
  }
  /**
  * This method is used to show the forgot password screen.
  */
  toggleShowForgot() {
    this.showForgotpassword = !this.showForgotpassword;
    this.showForgotSuccess = false;
    this.forgotMessage = "";
    this.forgotFields.email = "";
    this.forgotFieldsError.email = '';
    this.email = "";
    this.password = "";
    this.reAuthNumber = "";
    this.errorMsg = "";
  }
  /**
  * This method is called when there is a change in the sign up fields(onchange event).
  * this method reads user typed value and assigns to respective state variable 
  * @param -field - {string}- this param contains the field name in which change occered 
  * @param -event- {js event object }- which contains user typed authentication number.
  */
  onSignupFieldChange(field, event) {
    this.signupErr = "";
    switch (field) {
      case 'email':
        this.signupFields.email = event.target.value;
        this.signupErrors.email = "";
        var rules = {
          email: 'required|email',
        };
        var validation = new Validator({ email: event.target.value }, rules);
        if (validation.passes()) {
          apiCaller(signup.emailValidation + "?emailId=" + event.target.value, 'GET').then(response => {
            if (response.data && response.data.hasOwnProperty('isDuplicate') && response.data.isDuplicate) {
              this.isEmailError = true;
              this.signupErrors.email = "Email ID already exist, Please choose another.";
            } else {
              this.isEmailError = false;
              this.signupErrors.email = "";
            }
          });
        }
        break;
      case 'name':
        if (event.target.value === '' || userRe.test(event.target.value)) {
          this.signupFields.name = event.target.value;
          this.signupErrors.name = "";
          var rules = {
            name: 'required|min:8',
          };
          var validation = new Validator({ name: event.target.value }, rules);
          if (validation.passes()) {
            apiCaller(signup.userNameValidation + "?userName=" + event.target.value, 'GET').then(response => {
              if (response.data && response.data.hasOwnProperty('isDuplicate') && response.data.isDuplicate) {
                this.isEmailError = true;
                this.signupErrors.name = "User name already exist, Please choose another.";
              } else {
                this.isEmailError = false;
                this.signupErrors.name = "";
              }
            });
          }
        }
        break;
      case 'password':
        this.signupFields.password = event.target.value;
        this.signupErrors.password = "";
        break;
      case 'confirmPassword':
        this.signupFields.confirmPassword = event.target.value;
        this.signupErrors.confirmPassword = "";
        break;
      case 'phoneNumber':
        if (event.target.value === '' || re.test(event.target.value)) {
          this.signupFields.phoneNumber = event.target.value;
          this.signupErrors.phoneNumber = "";
        }
        break;
      default:
        console.log("signup default");
    }
  }
  signUp() {
    var rules = {
      name: 'required|min:8',
      email: 'required|email',
      password: 'required|min:8',
      phoneNumber: 'max:16|min:8',
      confirmPassword: 'required|same:password',

    };
    var validation = new Validator(this.signupFields, rules, {
      "required.email": "Please enter Email ID",
      "email.email": "Email format is invalid.",
      "required.name": "Please enter Name",
      "required.password": "Please enter Password",
      "required.confirmPassword": "Please enter confirm Password",
      "same.confirmPassword": "Password and Confirm Password should match",
      "min.password": "Password must be at least 8 characters.",
      "min.phoneNumber": "Phone number must be at least 8 characters.",
    });
    var param = {
      userName: this.signupFields.name,
      password: this.signupFields.password,
      emailId: this.signupFields.email,
      phoneNumber: this.signupFields.phoneNumber
    }
    if (validation.passes() && !this.isEmailError) {
      this.showSignUpLoader = true;
      this.signupErr = ""
      apiCaller(signup.register, 'POST', param).then(response => {
        if (response.data && response.data.hasOwnProperty('isCreated') && response.data.isCreated) {
          this.showSignUpLoader = false;
          this.showSignupSuccess = true;
        } else if (response.data && response.data.hasOwnProperty('isCreated') && !response.data.isCreated && response.data.hasOwnProperty('reason') && response.data.reason.length > 0) {
          this.showSignUpLoader = false;
          this.signupErr = response.data.reason;
        }
        else if (!response.data || response.data == null) {
          this.showSignUpLoader = false;
          this.signupErr = response.description;
        }
        else {
          this.showSignUpLoader = false;
          this.signupErr = 'Your request not succesful please try again';
        }
        setTimeout(() => {
          if (this.signupErr) {
            this.signupErr = "";
          }
        }, 6000)
      });
    } else {
      if (validation.errors.has('email') && validation.errors.get("email").length > 0) {
        this.signupErrors.email = validation.errors.get("email")[0]
      }
      if (validation.errors.has('name') && validation.errors.get("name").length > 0) {
        this.signupErrors.name = validation.errors.get("name")[0]
      }
      if (validation.errors.has('phoneNumber') && validation.errors.get("phoneNumber").length > 0) {
        this.signupErrors.phoneNumber = validation.errors.get("phoneNumber")[0]
      }
      if (validation.errors.has('password') && validation.errors.get("password").length > 0) {
        this.signupErrors.password = validation.errors.get("password")[0]
      }
      if (validation.errors.has('confirmPassword') && validation.errors.get("confirmPassword").length > 0) {
        this.signupErrors.confirmPassword = validation.errors.get("confirmPassword")[0]
      }
    }
  }
  /**
  * This method is called when there is a change in the forgot password fields(onchange event).
  * this method reads user typed value and assigns to respective state variable 
  * @param -field - {string}- this param contains the field name in which change occered 
  * @param -event- {js event object }- which contains user typed authentication number.
  */
  onForgotFieldChange(field, event) {
    switch (field) {
      case 'email':
        this.forgotFields.email = event.target.value;
        this.forgotFieldsError.email = "";
        break;
      case 'mobile':
        if (event.target.value === '' || re.test(event.target.value)) {
          this.forgotFields.mobile = event.target.value;
          this.forgotFieldsError.mobile = "";
        }
        break;
      default:
        console.log("");
    }
  }
  /**
  * This method is used to submit the request for forgot password.
  */
  submitForgot() {
    var rules = {
      email: 'required|email',
    };
    var validation = new Validator(this.forgotFields, rules, {
      "required.email": "Please enter Email ID",
      "email.email": "Email format is invalid.",
    });
    // var param = {
    //   emailId: this.forgotFields.email,
    //   mobile: this.forgotFields.mobile
    // }
    if (validation.passes()) {
      this.showForgotLoader = true;
      // call service
      apiCaller(signup.forgotPassWord + '?emailId=' + this.forgotFields.email, 'GET').then(response => {
        this.showForgotSuccess = true;
        this.showForgotLoader = false;
        if (response.data && response.data.hasOwnProperty('updated') && response.data.updated) {
          this.forgotMessage = "Link for reset password is sent to your mail,please check the email."
        } else {
          if (response && response.data && response.data.hasOwnProperty('message') && response.data.message.length > 0) {
            this.forgotMessage = response.data.message
          } else {
            this.forgotMessage = "Unable to send reset link, please try after some time."
          }
        }
      });
    } else {
      if (validation.errors.has('email') && validation.errors.get("email").length > 0) {
        this.forgotFieldsError.email = validation.errors.get("email")[0]
      }
      if (validation.errors.has('mobile') && validation.errors.get("mobile").length > 0) {
        this.forgotFieldsError.mobile = validation.errors.get("mobile")[0]
      }
    }
  }
}

export default LoginSignupStore;
