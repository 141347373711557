import React, { Component } from 'react';
import { observer } from 'mobx-react';
import cookie from 'react-cookies';

import BalanceOverviewTable from '../balanceoverviewtable/BalanceOverviewTable';
import dataLoader from '../../../../assets/images/loader-orange.svg';
import ReactPaginate from 'react-paginate';

class BalanceOverview extends Component {
  componentWillMount() {
    this.props.balanceOverviewStore.getBalanceOverview();
  }
  componentWillReceiveProps(nextProps) { }
  render() {
    const { balanceOverviewStore, appStore } = this.props;
    return (
      <div className="RDE-DB-balanceOverview">
        <div className="RDE-DB-balanceOverview-balanceSection">
          <div className="RDE-DB-balanceOverview-welcome">
            <div className=""><b>Welcome,</b></div>
            <div>View your account details here. You can manage funds, review and change your settings and see the performance of your betting activity.</div>
          </div>
          <div className="RDE-DB-balanceOverview-myBalance">
            <div>My Balance</div>
            <div className="RDE-DB-myBalance__value">{appStore.userInfo.userAccountBalance.toFixed(2)}</div>
          </div>
        </div>
        <div className="space-5"></div>
        {balanceOverviewStore.showLoader ? <div className="RDE-loader">
          <img src={dataLoader} alt="loading...!" />
        </div> : <>
          <BalanceOverviewTable data={balanceOverviewStore.data} component="BO" />
          {balanceOverviewStore.showErrorMsg ? <div className="RDE-noDataMessageBox">
            Currently data is not available.
          </div> : ''}
        </>}
        {balanceOverviewStore.totalPages > 0 && <div className="RDE-DB-pagination">
          <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            // breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={balanceOverviewStore.totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={1}
            onPageChange={balanceOverviewStore.handlePageChange.bind(balanceOverviewStore)}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />
        </div>}
      </div>
    )
  }
}
export default observer(BalanceOverview);
