import { extendObservable } from "mobx";
import cookie from 'react-cookies';

import { sportsPage } from "../../../sharedfiles/EndpointConfig";
import { apiCaller } from '../../../sharedfiles/apiStore';
import { getTimeZoneOffset } from '../../../sharedfiles/helper';

class SportsHighlightsStore {
  constructor(props) {
    extendObservable(this, {
      bannerData: [],
      appStore:{},
      pageId:"",
      leagues:[],
      selectedLeagueId:0,
      leagueEvents:[],
      sportId:"",
      sportName:"",
      showLoader:true,
      showErrorMsg:false,
      flkty:{},
      isInitialLoad:true
    });
  }
  /**
  * this method is used to get the highlights leagues
  *
  */
  getLeagues(){
    this.showLoader = true;
    apiCaller(sportsPage.getLeagues+"?sportId="+this.sportId,'GET').then(response => {
      if(response?.data?.popularLeagues?.length>0){
          this.leagues = response.data.popularLeagues;
          this.selectedLeagueId= this.leagues[0].id;
          this.getLeagueEvent();
      }else{
        this.leagues=[];
        this.showLoader = false;
        this.showErrorMsg= true;
      }
    });
  }
  /**
  * This method is used to get the league events based on league selected.
  *
  */
  getLeagueEvent(){
    if(this.isInitialLoad){
      this.showLoader = true;
      this.isInitialLoad = false;
      this.leagueEvents=[];
    }

    this.showErrorMsg = false;
    var token="";
    var cookeTocken = cookie.load('UTocken')
    if(cookeTocken && cookeTocken.length>0){
      token = cookeTocken;
    }
    apiCaller(sportsPage.getLeagueEvents+"?sportId="+this.sportId+"&leagueId="+this.selectedLeagueId+"&userToken="+token+"&minutes="+getTimeZoneOffset(),'GET').then(response => {
      this.showLoader=false;
      if(response?.data?.highlights?.length>0 && response.data.highlights[0].events.length>0){
          this.leagueEvents = response.data.highlights;
      }else{
        this.showErrorMsg= true;
      }
    });
  }
  /**
  * This method is called when user selects league
  * @param {Number} leagueId- contains the league id to select;
  */
  leagueSelect(leagueId){
      if(leagueId && leagueId>0){
          this.selectedLeagueId= leagueId;
          this.showLoader = true;
          this.leagueEvents=[];
          this.getLeagueEvent();
      }
  }
}

export default SportsHighlightsStore;
