import { extendObservable } from "mobx";
import cookie from 'react-cookies';

import { upcomingHighlights } from "../../../../sharedfiles/EndpointConfig";
import { apiCaller } from '../../../../sharedfiles/apiStore';
import { getTimeZoneOffset } from '../../../../sharedfiles/helper';
import { selectSelection, filterApiResponse } from '../../../../sharedfiles/helper';

class UpcomingStore {
  constructor(props) {
    extendObservable(this, {
      eventData: [],
      appStore: {},
      selectedSport: "",
      selectedOption: null,
      showErrorMsg: false,
      showLoader: false,
      selections: [],
      itemsPerClick: 20,
      itemsShow: 20,
      initialItemShow: 20,
      isInitialLoad: true,
      mounted: true,
    });
  }
  /**
  * This method is called from component will mount when component is loaded,
  * in this method we will get the banners data.
  */
  getUpcomingEventData() {
    
    if (this.selectedSport && this.selectedSport !== null
      && this.selectedSport.hasOwnProperty('id') && this.selectedSport.id !== null
      && this.selectedOption && this.selectedOption !== null) {
      this.showErrorMsg = false;
      if (this.isInitialLoad) {
        this.showLoader = true;
        this.isInitialLoad = false;
      }
      var token = "";
      var cookeTocken = cookie.load('UTocken')
      if (cookeTocken && cookeTocken.length > 0) {
        token = cookeTocken;
      }
      apiCaller(upcomingHighlights.getUpcomingEvents + "?sportId=" + this.selectedSport.id + "&optionId=" + this.selectedOption + "&timeInterval=" + getTimeZoneOffset() + "&userToken=" + token, 'GET').then(response => {
        this.showLoader = false;
        if (response.data && response.data.hasOwnProperty('sports') &&
          response.data.sports && response.data.sports.length > 0) {

          if (this.eventData.length > 0) {
            this.eventData = filterApiResponse(this.eventData, response.data.sports[0].events, "eventId");
          }
          else {
            this.eventData = response.data.sports[0].events;
          }
          this.selections = selectSelection(this.eventData[0]);
        } else {
          this.showErrorMsg = true
          this.eventData = [];
        }
      });
    } else {
      this.showErrorMsg = true
      this.eventData = [];
    }

  }
  /**
  * This method is called when user clicks on sport in upcoming.
  * @param sport-{object} contains the sport information on which user clicks.
  */
  selectSport(sport) {
    if (sport && sport.hasOwnProperty('name')) {
      this.selectedSport = sport;
      this.showLoader = true;
      this.eventData = [];
      this.getUpcomingEventData();
    }
  }
  /**
  * This method is called when user clicks on sport in upcoming.
  * @param sport-{object} contains the sport information on which user clicks.
  */
  selectOption(option) {
    this.selectedOption = option.id;
    this.showLoader = true;
    this.getUpcomingEventData();
  }
  /**
  * This method is used to show more events
  */
  showMore() {
    this.itemsShow += this.itemsPerClick;
  }
  /**
  * This method is used to show less events
  */
  showLess() {
    this.itemsShow = this.initialItemShow;
  }
}

export default UpcomingStore;
