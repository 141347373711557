import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Route } from "react-router-dom";

import LeaguePageComponent from './leaguepagecomponent/LeaguePageComponent';
import LeaguePageComponentStore from './leaguepagecomponent/LeaguePageComponentStore';

const leaguePageComponentStore = new LeaguePageComponentStore();
class Leaguepage extends Component {
  componentWillMount() {
    this.props.appStore.clearBetslip();
  }
  componentDidMount() {

  }

  componentWillUnmount() {
  }

  onInitialFetch(groups) { }

  componentWillReceiveProps(nextProps) {
  }

  render() {
    const { appStore } = this.props;
    return (
      <div className="RDE-mainContent">
        <Route render={(props) => <LeaguePageComponent {...props} leaguePageComponentStore={leaguePageComponentStore}
          appStore={appStore}
          sportId={appStore.sportId}
          sportName={appStore.sportName} />} />
      </div>
    );
  }
}

export default observer(Leaguepage);
