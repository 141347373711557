import React, { Component } from 'react';
import { observer } from 'mobx-react';
import cookie from 'react-cookies';

class ChangePassword extends Component {
  componentWillMount() {
    this.props.changePasswordStore.clearFields()

  }
  componentWillReceiveProps(nextProps) { }
  render() {
    const { changePasswordStore } = this.props;
    return (
      <div className="RDE-DB-changePassword">
        <div className="grid-top">
          <div className="col-6">
            <div className="RDE-DB-infoBox">
              <div className="RDE-DB-infoBox__header">
                <div className="RDE-DB-infoBox__header-left">Change Password</div>
                <div className="RDE-DB-infoBox__header-right"></div>
              </div>
              <div className="RDE-DB-infoBox__content">
                <div className="RDE-DB-infoBox-item">
                  <div className="RDE-DB-infoBox-item__left">
                    <span>Old password</span>
                    <span className="RDE-DB-colon">:</span>
                  </div>
                  <div className="RDE-DB-infoBox-item__right">
                    <div className=""><input type="password" className="RDE-formControl" placeholder="Enter old password" value={changePasswordStore.updateFields.password} onChange={changePasswordStore.onFiledChange.bind(changePasswordStore, 'password')} /></div>

                  </div>
                </div>
                <div className="RDE-DB-errorMessage text-center">{changePasswordStore.updateFieldErrors.password}</div>
                <div className="space-5"></div>
                <div className="RDE-DB-infoBox-item">
                  <div className="RDE-DB-infoBox-item__left">
                    <span>New password</span>
                    <span className="RDE-DB-colon">:</span>
                  </div>
                  <div className="RDE-DB-infoBox-item__right">
                    <div className=""><input type="password" className="RDE-formControl" placeholder="Enter new password" value={changePasswordStore.updateFields.newPassword} onChange={changePasswordStore.onFiledChange.bind(changePasswordStore, 'newPassword')} /></div>

                  </div>
                </div>
                <div className="RDE-DB-errorMessage text-center">{changePasswordStore.updateFieldErrors.newPassword}</div>
                <div className="RDE-DB-infoBox-item">
                  <div className="RDE-DB-infoBox-item__left">
                    <span>Confirm password</span>
                    <span className="RDE-DB-colon">:</span>
                  </div>
                  <div className="RDE-DB-infoBox-item__right">
                    <div className=""><input type="password" className="RDE-formControl" placeholder="Enter confirm password" value={changePasswordStore.updateFields.confirmPassword} onChange={changePasswordStore.onFiledChange.bind(changePasswordStore, 'confirmPassword')} /></div>
                  </div>
                </div>
                <div className="RDE-DB-errorMessage text-center">{changePasswordStore.updateFieldErrors.confirmPassword}</div>

                <div className="space-5"></div>
                <div className="RDE-DB-errorMessage text-center">{changePasswordStore.updateError}</div>
                <div className="space-5"></div>
                <div className="RDE-DB-successMessage text-center">{changePasswordStore.updateSuccess}</div>
                <div className="grid-noGutter-middle-center">
                  <div className="col-4"><button className="btn RDE-btn-primary" onClick={changePasswordStore.updatePassword.bind(changePasswordStore)}>Update</button></div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    )
  }
}
export default observer(ChangePassword);
