import * as moment from 'moment';

export var preMatchRefreshTime = 1000*60*1;
export var liveMatchRefreshTime = 1000*60*1;

export function getAuthenticationNumber(){
	return Math.floor((Math.random() * (9999-1000) )+ 1000);
}

export function formatDate(date,componentType){
    if(date){
        var today = new Date();
        var tomorrow = new Date();
        tomorrow.setDate(today.getDate()+1);
        var dt="";
        if(componentType==="upcoming" || componentType === "sportsHighlights" || componentType==='leaguecomponent'){
            dt = moment(date.replace(/\s/, 'T')).toDate();
        }else{
            var utcDate = new Date(date.replace(/\s/, 'T')+'Z').toUTCString();
            dt = new Date(utcDate);
        }
        var formatedDate=""
        if(today.getDate() === dt.getDate() && today.getMonth() === dt.getMonth() && today.getFullYear() === dt.getFullYear()){
            if(componentType === 'inplay' || componentType === 'sportsLiveNow'){

                if(today.getHours() === dt.getHours() && ((dt.getMinutes()-today.getMinutes()<=5) && (dt.getMinutes()-today.getMinutes()>=1))){
                    formatedDate= "Starts in "+ (dt.getMinutes()-today.getMinutes()) + "'"
                }else if((dt.getMinutes()-today.getMinutes()<1)){
                    formatedDate = "About to start";
                }
            }else{
                formatedDate = addZero(dt.getHours())+":"+addZero(dt.getMinutes());
            }
        }else if(tomorrow.getDate() === dt.getDate() && tomorrow.getMonth() === dt.getMonth() && tomorrow.getFullYear() === dt.getFullYear()){
            formatedDate ="Tomorrow"+" "+addZero(dt.getHours())+":"+addZero(dt.getMinutes());
        }else{
            formatedDate = addZero(dt.getDate())+"-"+addZero(dt.getMonth()+1)+"-"+dt.getFullYear()+" "+addZero(dt.getHours())+":"+addZero(dt.getMinutes());
        }
        
        return formatedDate;
    }
	
}

export function openBetsFormatDate(date){
    if(date){
        var utcDate = new Date(date.replace(/\s/, 'T')+'Z').toUTCString();
        var dt = new Date(utcDate);
        return addZero(dt.getDate())+"-"+addZero(dt.getMonth()+1)+"-"+dt.getFullYear()+" "+addZero(dt.getHours())+":"+addZero(dt.getMinutes());
    }
    
}

export function accoridonTimeFormat(date){
    if(date){
        var today = new Date();
        var tomorrow = new Date();
        tomorrow.setDate(today.getDate()+1);
        var dt="";
        dt = new Date(date);
        var monthNames = ["January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November", "December"];
        var formatedDate=""
        if(today.getDate() === dt.getDate() && today.getMonth() === dt.getMonth() && today.getFullYear() === dt.getFullYear()){
            formatedDate = "Today";
        }else if(tomorrow.getDate() === dt.getDate() && tomorrow.getMonth() === dt.getMonth() && tomorrow.getFullYear() === dt.getFullYear()){
            formatedDate ="Tomorrow";
        }else{
            formatedDate = ordinal_suffix_of(dt.getDate())+" "+monthNames[dt.getMonth()];
        }
        return formatedDate;
    }
}

export function getLocalDate(date){
    var dt="";
    var utcDate = new Date(date.replace(/\s/, 'T')+'Z').toUTCString();
    dt = new Date(utcDate);
    return addZero(dt.getDate())+"-"+addZero(dt.getMonth()+1)+"-"+dt.getFullYear();
}
function ordinal_suffix_of(i) {
    var j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return i + "st";
    }
    if (j === 2 && k !== 12) {
        return i + "nd";
    }
    if (j === 3 && k !== 13) {
        return i + "rd";
    }
    return i + "th";
}
export function searchTimeFormat(date){
    var utcDate = new Date(date.replace(/\s/, 'T')+'Z').toUTCString();
    var dt = new Date(utcDate);
    var formatedDate=addZero(dt.getHours())+":"+addZero(dt.getMinutes());  
    return formatedDate;
}

function addZero(number){
	if(number<10){
		return (0+""+number);
	}
	return number
}
/**
* This method used to validate the user coins( settings and oneclick )
* @param {coins} - array contains list of coins.
*/
export function validateCoins(coins){
    var validCoins = true;
    for(var coin of coins){
        if(coin.coinValue === "" || coin.coinValue === null || coin.coinValue < 1){
          validCoins = false;
        }
    }
    if(validCoins){
        return true;
    }else{
        return false
    }
}
/**
* This method is used to select the selection name for display in upcoming and highlights.  
* @param {event} contains event from which need to return outcomes
*/
export function selectSelection(event){
      if(event.hasOwnProperty('markets') && event.markets && event.markets.length>0 && 
         event.markets[0].hasOwnProperty('outcomes') && event.markets[0].outcomes && event.markets[0].outcomes.length>0){
        return event.markets[0].outcomes;
      }  
}

/**
* This method will return timezone offset in mins.
*/
export function getTimeZoneOffset(){
    var timeInterval = new Date().getTimezoneOffset();
    if(timeInterval < 0){
      return Math.abs(timeInterval);
    }else{
      return -Math.abs(timeInterval);
    }
}
/**
 * 
 * @param {{{Array}}:contains the previous api call response} currentData 
 * @param {{{Array}}:contain the latest api call response} newData 
 * @param {string:name of the object key} key 
 */
export function filterApiResponse(currentData,newData) {
    if (newData && Array.isArray(newData) && newData.length > 0 &&
    currentData && Array.isArray(currentData) && currentData.length >0) {
      for (var evt of newData) {
        var matchedEvent = currentData[currentData.map(function (item) { return item.key; }).indexOf(evt.key)];
        if (Object.keys(matchedEvent).length > 0) {
          evt = matchedEvent;
        }
      }
    }
    return newData;
  }

/**
*
*
*
**/
export function utcToTimeZone(dateTime,timeZone){
    //var aestTime = new Date(dateTime.replace(/\s/, 'T')+'Z').toLocaleString("en-US", {timeZone: "Asia/Shanghai"});
    var aestTime = new Date(dateTime.replace(/\s/, 'T')+'Z').toLocaleString("en-US");
    aestTime = new Date(aestTime);
    //console.log('AEST time: '+aestTime.toLocaleString());
    return {
        transactionDate:addZero(aestTime.getDate())+"/"+addZero(aestTime.getMonth()+1)+"/"+aestTime.getFullYear()+" "+addZero(aestTime.getHours())+":"+addZero(aestTime.getMinutes())+":"+addZero(aestTime.getSeconds()),
        date: addZero(aestTime.getFullYear())+"/"+addZero(aestTime.getMonth()+1)+"/"+aestTime.getDate(),
        time:addZero(aestTime.getHours())+":"+addZero(aestTime.getMinutes())+":"+addZero(aestTime.getSeconds())
    }
}