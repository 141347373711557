import React, { Component } from 'react';
import { observer } from 'mobx-react';
import poweredByLogo from '../../assets/images/ne-logo.svg';

class Footer extends Component {
  componentWillMount() {
    this.props.footerStore.getFooterData();
  }
  componentWillReceiveProps(nextProps){}
  render() {
      const {footerStore} = this.props;
      return (
        <div className="RDE-footer">
          <div className="RDE-footer-contactInfoList">
              <ul>
                {
                  footerStore.communicationDetails.map((item, index)=>{
                    return <li key={"footer_com_"+index}><i className={"RDE-icon-"+item.name}></i>
                        {/* {item.id===1?<a href={"mailto:"+item.text}> {item.text}</a>:item.id===2?item.text:<a href={item.url} target="_blank" rel="noopener noreferrer"> {item.text}</a>} */}
                    </li>
                  })
                }
                

                
              </ul>
          </div>
          <div className="RDE-footer-privacyTerms">
              <ul>
                  {
                    footerStore.points.map((item, index)=>{
                      return <li key={"footer_TC_"+index}>
                         <a target="_blank"> {item.text}</a>
                         {/* <a href={item.url} target="_blank"> {item.text}</a> */}
                      </li>
                    })
                  }
              </ul>
          </div>
          <div className="RDE-footer-licenseBox">
              <ul>
                  <li>
                      <div className="RDE-footer-PoweredByTxt">Powered by</div>
                      <div><img src={poweredByLogo} alt="logo"/></div>
                  </li>
                  <li dangerouslySetInnerHTML={{ __html: footerStore.logoAndLicensingInformation.text }}></li>
              </ul>
          </div>
      </div>
        )
    }
}
export default observer(Footer);
