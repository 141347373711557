import { extendObservable } from "mobx";
import cookie from 'react-cookies';

import { eventView } from "../../sharedfiles/EndpointConfig";
import { apiCaller } from '../../sharedfiles/apiStore';

class EventViewStore {
  constructor(props) {
    extendObservable(this, {
      data: [],
      appStore: {},
      eventId: -1,
      matchOdds: {},
      fancyBets: {},
      eventInfo: {},
      popularBets: [],
      goalsBets: [],
      showTab: "popular",
      sportName: "",
      sportId: -1,
      isMatchOdds: false,
      isFancy: false,
      isPopular: false,
      isGoals: false,
      showLoader: false,
      showErrorMsg: false,
      isInitialLoad: true,
      mounted: true,
      betslipComponentStore: {},
      eventPL: [],
      marchOddsPL: {},
      marchOdds1PL: {},
      matchOdds1: []

    });
  }
  /**
  * This method is used to get the event view data.
  *
  */
  getEventView(appStore) {
    if (this.isInitialLoad) {
      this.showLoader = true;
    }
    this.showErrorMsg = false;
    // this.eventInfo={};
    var token = "";
    var cookeTocken = cookie.load('UTocken');
    if (cookeTocken && cookeTocken.length > 0) {
      token = cookeTocken;
    }
    apiCaller(eventView.getEvent + "?eventId=" + this.eventId + "&userToken=" + token, 'GET').then(response => {
      this.showLoader = false;
    //  this.eventInfo = {};
      if(this.appStore.betsProfitLoss.length>0){
      this.getEventPL();
      }
      if(!response?.data){
        this.eventInfo = {};
      }
      if (response?.data) {
        var openBetEventIndex = this.appStore.openBetsEventIds.indexOf(this.eventId);
        // if (appStore != null && appStore !== undefined && openBetEventIndex!==-1) {
        //       // appStore.selectedOpenBetsEvent.eventId = this.eventId;
        //       // appStore.selectedOpenBetsEvent.eventName = response.data.eventName;
        // }
        this.eventInfo = response.data;
        if (response?.data?.isLive) {
          appStore.showLiveStreaming = true;
        } else {
          appStore.showLiveStreaming = false;
        }
        this.sportId = response.data.sportId;
      }
      if (response?.data?.matchOdds) {
        this.matchOdds = response?.data?.matchOdds;
        this.marchOddsPL = {};
        if(this.eventPL.length>0){
        this.marchOddsPL = this.getMarketPL(response.data.matchOdds.marketGroupId, response.data.matchOdds.providerId);
        }
        this.isMatchOdds = true;
      } else {
        this.isMatchOdds = false;
      }
      if (response?.data?.matchOdds1) {
        this.matchOdds1 = response?.data?.matchOdds1;
        this.marchOdds1PL = {};
        if(this.eventPL.length>0){
        this.marchOdds1PL = this.getMarketPL(response.data.matchOdds1.marketGroupId, response.data.matchOdds1.providerId);
        }
        this.isMatchOdds1 = true;
      } else {
        this.isMatchOdds1 = false;
      }
      if (response?.data?.fancyBets) {
        this.fancyBets = response?.data?.fancyBets;
        this.isFancy = true;
      } else {
        this.isFancy = false;
      }
      if (response?.data?.popular?.length > 0) {
        this.popularBets = response?.data?.popular;
        this.isPopular = true;
      } else {
        this.isPopular = false;
      }
      if (response?.data?.goals?.length > 0) {
        this.goalsBets = response?.data?.goals;
        this.isGoals = true;
      } else {
        this.isGoals = false;
      }
      if (!this.isMatchOdds && !this.isFancy && !this.isPopular && !this.isGoals) {
        this.showErrorMsg = true;
      }
      if (this.isInitialLoad) {
        this.isInitialLoad = false;
        if (this.isPopular) {
          this.showTab = 'popular';
        } else if (this.isGoals) {
          this.showTab = 'goal';
        }
      }
      var statsEventId = 'm' + this.eventId;
      // window.SIR("addWidget", ".RDE-eventView-scoreBoardContainer", "match.lmtPlus", {layout: "double", collapseTo:"momentum",activeSwitcher:"scoreDetails",matchId:statsEventId,disableAds:true,expanded:"true"});
    });
  }
  /**
  * This method is used to get the profit and loss of the user bets.
  */
  getProfitLoss() {
   // console.log("getProfitloss==", this.betslipComponentStore.betSlipItems)
  }
  /**
  * This method is used to get the PL for market.
  */
  getMarketPL(marketId, providerId, subMarket) {
    var marketPL = {};
    for (var bet of this.eventPL) {

      if (marketId === bet.marketId && providerId === bet.providerId && subMarket === bet.subMarket) {
        marketPL = bet
      }
    }
    return marketPL;
  }
  /**
  * This method is used to get the market profit loss.
  */
  getEventPL() {
    this.eventPL = [];
    for (var bet of this.appStore.betsProfitLoss) {
      if (this.eventId === bet.eventId) {
        this.eventPL.push(bet)
      }
    }
  }
  updateData() {
    this.getEventView(this.appStore);
  }
  /**
  * this method is used to selected the tab
  */
  selectTab(tabName) {
    this.showTab = tabName;
  }
}

export default EventViewStore;
