import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { getAuthenticationNumber } from "../../sharedfiles/helper"
//import popUpClose from '../../assets/images/popup-close.svg';
import signUploader from '../../assets/images/loader.svg'
import logo from '../../assets/images/playbig-exchange-logo.svg';
class LoginSignup extends Component {
    componentWillMount() {
        this.props.loginSignupStore.authNumber = getAuthenticationNumber();
        this.props.loginSignupStore.showSignup = this.props.showSignupBlock;
        this.props.loginSignupStore.loginSignupProps = this.props;
        this.props.loginSignupStore.resetFields();
        this.props.loginSignupStore.appStore = this.props.appStore;
    }
    handleKeyPress(e){
    if( e.key==='Enter'){
       
   this.props.loginSignupStore.login(this.props.settingsStore)
   if(this.props.loginSignupStore.showForgotpassword){
    this.props.loginSignupStore.submitForgot()
   }
}
    }
    componentWillReceiveProps(nextProps) { }
    render() {
        const { loginSignupStore, appStore,settingsStore } = this.props;
        return (
        <div className="RDE-loginSignupPage">
           <div className="RDE-modal ">
            <div class="RDE-centered-modal RDE-loginSignup-modal">
            <div className="RDE-loginSignup-modal-content">
                <div className={"RDE-loginSignup-backDrop" + (loginSignupStore.showSignup ? '' : " RDE-logInActive")}>
                    <div className="RDE-loginSignup-backDrop__content">
                        <div className="RDE-loginSignup-backDrop__innerContent">
                            <div className="RDE-loginSignup-info">
                                <div className="RDE-loginSignup-infoItem text-center">
                                    <h2>Have an Account?</h2>
                                    <button className="btn RDE-btn-primary RDE-btn-large" onClick={loginSignupStore.toggleLoginSignup.bind(loginSignupStore)}>Login</button>
                                </div>
                                <div className="RDE-loginSignup-infoItem text-center">
                                    <h2>Don't have an Account?</h2>
                                    <button className="btn RDE-btn-primary RDE-btn-large" onClick={loginSignupStore.toggleLoginSignup.bind(loginSignupStore)}>Sign Up</button>
                                </div>
                            </div>
                            <div className="RDE-loginSignup-form">
                                <div className="RDE-loginSignupBox RDE-signUp">
                                    <div class="RD-loginLogo">
                                        <img alt="logo" src={logo} class="RDE-logo" />
                                    </div>
                                    {!loginSignupStore.showSignupSuccess ? <div>
                                        <div className="RDE-loginSignupBox-header">
                                            Register
                                        </div>
                                        <div className="RDE-loginSignupBox-content">
                                            <div className="RDE-formGroup">
                                                <input type="text" className="RDE-formControl" placeholder="Name" value={loginSignupStore.signupFields.name} onChange={loginSignupStore.onSignupFieldChange.bind(loginSignupStore, 'name')} />
                                            </div>
                                            <div className="RDE-formControl-errorMessage">{loginSignupStore.signupErrors.name}</div>
                                            <div className="RDE-formGroup">
                                                <input type="text" className="RDE-formControl" placeholder="Email" value={loginSignupStore.signupFields.email} onChange={loginSignupStore.onSignupFieldChange.bind(loginSignupStore, 'email')} />
                                            </div>
                                            <div className="RDE-formControl-errorMessage">{loginSignupStore.signupErrors.email}</div>
                                            <div className="RDE-formGroup">
                                                <input type="text" className="RDE-formControl" placeholder="Phone Number" maxLength="16" value={loginSignupStore.signupFields.phoneNumber} onChange={loginSignupStore.onSignupFieldChange.bind(loginSignupStore, 'phoneNumber')} />
                                            </div>
                                            <div className="RDE-formControl-errorMessage">{loginSignupStore.signupErrors.phoneNumber}</div>
                                            <div className="grid-middle">
                                                <div className="col-6">
                                                    <div className="RDE-formGroup">
                                                        <input type="password" className="RDE-formControl" placeholder="Password" value={loginSignupStore.signupFields.password} onChange={loginSignupStore.onSignupFieldChange.bind(loginSignupStore, 'password')} />
                                                    </div>
                                                    <div className="RDE-formControl-errorMessage">{loginSignupStore.signupErrors.password}</div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="RDE-formGroup">
                                                        <input type="password" className="RDE-formControl" placeholder="Confirm Password" value={loginSignupStore.signupFields.confirmPassword} onChange={loginSignupStore.onSignupFieldChange.bind(loginSignupStore, 'confirmPassword')} />
                                                    </div>
                                                    <div className="RDE-formControl-errorMessage">{loginSignupStore.signupErrors.confirmPassword}</div>
                                                </div>
                                            </div>
                                            <div className="RDE-formControl-errorMessage text-center">{loginSignupStore.signupErr}</div>
                                            <div className="space-5"></div>
                                            <div className="RDE-formGroup mb-0 text-center">
                                                <button className="btn RDE-btn-primary RDE-btn-large"  onClick={loginSignupStore.signUp.bind(loginSignupStore)}>Sign Up</button>
                                            </div>
                                        </div>
                                        <div className="RDE-loginSignupBox-footer">
                                            <div className="grid-middle-noGutter">
                                                <div className="col-8 text-center">
                                                    <div className="RDE-loginSignupBox-footer__title">For assistance please contact us at</div>
                                                </div>
                                                <div className="grid-middle">
                                                    <div className="col-6">
                                                        <i className="RDE-icon-email"></i>
                                                        {/* <a href="mailto@cs@victoryexch.com">info@victoryexch.com</a> */}
                                                    </div>
                                                    <div className="col-6 text-right">
                                                        <i className="RDE-icon-whatsapp"></i>
                                                        {/* +44 7566742924 */}
                                                    </div>
                                                </div>
                                            </div>

                                            
                                        </div>
                                    </div> :
                                        <div className="RDE-loginSignupBox-signUpSuccess">
                                            <div className="RDE-loginSignupBox-signUpSuccess-content text-center">
                                                <div className="RDE-signUpSuccess__headerTxt">
                                                    <i className="RDE-icon-success"></i>
                                                    Thank you...!
                                            </div>
                                                <div className="RDE-signUpSuccess__mainTxt">Your registration is successfull</div>
                                                <div className="RDE-loginSignupBox-footer__goBack">
                                                    <span className="">Please verify your email before login, verification link is sent to your email.</span>
                                                 <i className="RDE-icon-go-back"></i>
                                                <span className="RDE-forgotLink" onClick={loginSignupStore.toggleLoginSignup.bind(loginSignupStore)}> Go to login</span>
                                                </div>
                                            </div>
                                        </div>}
                                    {loginSignupStore.showSignUpLoader ? <div className="RDE-signUp-loader">
                                        <img src={signUploader} alt="loading...!" />
                                    </div> : ''}
                                </div>
                                <div className="RDE-loginSignupBox RDE-logIn">
                                    <div className="RD-loginLogo">
                                        <img alt="logo" src={logo} className="RDE-logo" />
                                    </div>
                                    {!loginSignupStore.showForgotpassword?<div className="">
                                        <div className="RDE-loginSignupBox-header">
                                            Login
                                        </div>
                                        <div className="RDE-loginSignupBox-content" onKeyPress={(event)=>this.handleKeyPress(event)} >
                                            <div className="RDE-formGroup">
                                                <input type="text" className="RDE-formControl" placeholder="Email" value={loginSignupStore.email} onChange={loginSignupStore.onLoginFiledChange.bind(loginSignupStore, 'email')} />
                                            </div>
                                            <div className="RDE-formControl-errorMessage text-center">

                                            </div>
                                            <div className="RDE-formGroup">
                                                <input type="password" className="RDE-formControl" placeholder="Password" value={loginSignupStore.password} onChange={loginSignupStore.onLoginFiledChange.bind(loginSignupStore, 'password')} />
                                            </div>
                                            <div className="RDE-formControl-errorMessage text-center">

                                            </div>
                                            <div className="RDE-formGroup RDE-captchaBox">
                                                <input type="text" className="RDE-formControl" placeholder="Validation" value={loginSignupStore.reAuthNumber} onChange={loginSignupStore.onLoginFiledChange.bind(loginSignupStore, 'reAuthNumber')} maxLength={4} />
                                                <div className="RDE-captchaTxt RDE-skew"><span className="RDE-skewTxt">{loginSignupStore.authNumber}</span></div>
                                            </div>
                                            <div className="RDE-formControl-errorMessage text-center">
                                                {loginSignupStore.errorMsg}
                                            </div>
                                            <div className="space-5"></div>
                                            <div className="RDE-formGroup text-center">
                                                <button className="btn RDE-btn-primary RDE-btn-large"  onClick={loginSignupStore.login.bind(loginSignupStore,settingsStore)}>Login</button>
                                            </div>
                                        </div>
                                        <div className="RDE-loginSignupBox-footer grid-middle-noGutter text-center">
                                            <div className="RDE-loginSignupBox-footer__goBack">
                                                <span className="RDE-forgotLink" onClick={loginSignupStore.toggleShowForgot.bind(loginSignupStore)}>Forgot Password?</span>
                                            </div>
                                        </div>
                                    </div>:
                                    <div className="RDE-loginSignupBox-forgotPassword" onKeyPress={(event)=>this.handleKeyPress(event)} >
                                        <div className="RDE-loginSignupBox-header">
                                            Forgot Password
                                        </div>
                                        {!loginSignupStore.showForgotSuccess && !loginSignupStore.showForgotLoader?<div className="RDE-loginSignupBox-content" >
                                            <div className="RDE-formGroup">
                                                <input type="text" className="RDE-formControl" placeholder="Email" 
                                                       onChange={loginSignupStore.onForgotFieldChange.bind(loginSignupStore,'email')}
                                                       value={loginSignupStore.forgotFields.email}/>
                                            </div>
                                            <div className="RDE-formControl-errorMessage">{loginSignupStore.forgotFieldsError.email}</div>
                                            {/*<div className="RDE-formGroup">
                                                <input type="text" className="RDE-formControl" placeholder="Mobile" 
                                                       onChange={loginSignupStore.onForgotFieldChange.bind(loginSignupStore,'mobile')}
                                                       value={loginSignupStore.forgotFields.mobile}/>
                                            </div>
                                            <div className="RDE-formControl-errorMessage">{loginSignupStore.forgotFieldsError.mobile}</div>
                                            <div className="space-5"></div>*/}
                                            <div className="RDE-formGroup text-center">
                                                <button className="btn RDE-btn-primary RDE-btn-large" onClick={loginSignupStore.submitForgot.bind(loginSignupStore)}>Submit</button>
                                            </div>
                                        </div>:''}
                                        {loginSignupStore.showForgotSuccess && !loginSignupStore.showForgotLoader?
                                        <div className={loginSignupStore.forgotMessage.includes("Link for")?"text-center RDE-DB-successMessage":"text-center RDE-formControl-errorMessage"}>{loginSignupStore.forgotMessage}</div>:''}
                                        {loginSignupStore.showForgotLoader ? <div className="RDE-signUp-loader">
                                            <img src={signUploader} alt="loading...!" />
                                        </div> : ''}
                                        <div className="RDE-loginSignupBox-footer grid-middle-noGutter text-center">
                                            <div className="RDE-loginSignupBox-footer__goBack">
                                                <i className="RDE-icon-go-back"></i>
                                                <span className="RDE-forgotLink" onClick={loginSignupStore.toggleShowForgot.bind(loginSignupStore)}>Back to Login</span>
                                            </div>
                                        </div>
                                    </div>}
                                    {loginSignupStore.showLoginLoader ? <div className="RDE-signUp-loader">
                                        <img src={signUploader} alt="loading...!" />
                                    </div> : ''}
                                </div>

                            </div>
                        </div>
                    </div>
                    {/* <img src={popUpClose} alt="close popup" className="RDE-modal-closeIcon" onClick={appStore.hideSignup.bind(appStore)} /> */}
                </div>
            </div>
        </div>
        </div>
            <div className="RDE-modal d-none">
                <div className="RDE-changePassword-modal RDE-loginSignup-modal">
                <div className="RDE-loginSignup-modal-content">
                    <div className="RDE-loginSignup-form ">
                    <div className="RDE-loginSignupBox RDE-logIn">
                        <div class="RD-loginLogo">
                             <img alt="logo" src={logo} class="RDE-logo" />
                             <div className='RDE-logo__caption'>Exchange</div>
                        </div>
                      <div className="">
                        <div className="RDE-loginSignupBox-header">Change Password</div>
                        <div className="RDE-loginSignupBox-content">
                          <div className="RDE-formGroup">
                            {/* <input type="text" className="RDE-formControl" placeholder="New Password" value={forgotPasswordStore.resetFields.newPassword} onChange={forgotPasswordStore.onResetChange.bind(forgotPasswordStore,'newPassword')}/> */}
                            <input type="password" className="RDE-formControl" placeholder="New Password" autoComplete="off" />
                          </div>
                          <div className="RDE-formControl-errorMessage">
                              {/* {forgotPasswordStore.resetFieldsError.newPassword} */}
                              </div>
                          <div className="RDE-formGroup">
                            {/* <input type="password" className="RDE-formControl" placeholder="Confirm Password"  value={forgotPasswordStore.resetFields.confirmPassword} onChange={forgotPasswordStore.onResetChange.bind(forgotPasswordStore,'confirmPassword')} /> */}
                            <input type="password" className="RDE-formControl" placeholder="Confirm Password" autoComplete="off" />
                          </div>
                          <div className="RDE-formControl-errorMessage">
                              {/* {forgotPasswordStore.resetFieldsError.confirmPassword} */}
                              </div>
                          <div className="RDE-formControl-errorMessage"></div>
                          <div className="RDE-formGroup text-center">
                            {/* <button className="btn RDE-btn-primary RDE-btn-large" onClick={forgotPasswordStore.submitReset.bind(forgotPasswordStore)}>Submit</button> */}
                            <button className="btn RDE-btn-primary RDE-btn-large">Submit</button>
                          </div>
                        </div>
                        <div class="RDE-loginSignupBox-footer grid-middle-noGutter text-center">
                            <div class="RDE-loginSignupBox-footer__goBack">
                                <i class="RDE-go-back"></i><span class="RDE-forgotLink"> Back to Login</span>
                            </div>
                        </div>
                      </div>
                    </div>
                    </div>
                  </div>
                </div>
                </div>
        </div>
        
        )
    }
}
export default observer(LoginSignup);
