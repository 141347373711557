import React, { Component } from 'react';
import { observer } from 'mobx-react';
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
class Banner extends Component {
    componentWillMount() {
        this.props.bannerStore.pageId = this.props.pageId;
        this.props.bannerStore.getBannerData();
        this.props.bannerStore.appStore = this.props.appStore;
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.isLoggedIn !== nextProps.isLoggedIn) {
            this.props.bannerStore.getBannerData();
        }
    }
    render() {
        const { bannerStore, appStore } = this.props;
        const handleOnDragStart = e => e.preventDefault()
        return (
            <div className="RDE-home-alice-carousel">
                {/* {bannerStore.bannerData && bannerStore.bannerData.length > 0 && !this.props.isLoggedIn ? <AliceCarousel
                    buttonsDisabled={true}
                    dotsDisabled={false}
                    autoPlay={true}
                    autoPlayInterval={20000}
                    stopAutoPlayOnHover={true}
                    mouseDragEnabled={true}
                    preventEventOnTouchMove={true}
                    onDragStart={handleOnDragStart}>
                    {bannerStore.bannerData.map(function (item, index) {
                        return (
                            <div className="RDE-mainBanner" onClick={bannerStore.clickBanner.bind(bannerStore)} key={"bannerItem_" + index} style={{ backgroundImage: 'url(' + item.imageUrl + ')' }}>
                                <div className="RDE-mainBanner__content">
                                    <div className="RDE-mainBanner__innerContent">
                                        <div className="RDE-mainBanner__innerTxt">
                                            <div className="">{item.text}</div>
                                        </div>
                                        {item.isButton && item.buttonType === 1 ? <button className="btn RDE-btnBanner-Register RDE-btn-primary RDE-skew" onClick={appStore.showSignup.bind(appStore)}><span className="RDE-skewTxt">{item.buttonText}</span></button> : <button className="btn RDE-btnBanner-Register RDE-btn-primary RDE-skew" onClick={bannerStore.bannerButtonClick.bind(bannerStore, item.buttonType)}><span className="RDE-skewTxt">{item.buttonText}</span></button>}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </AliceCarousel> : ''} */}
                {bannerStore.loginBannerData && bannerStore.loginBannerData.length > 0 && this.props.isLoggedIn ? <AliceCarousel
                    buttonsDisabled={true}
                    dotsDisabled={false}
                    autoPlay={true}
                    autoPlayInterval={20000}
                    stopAutoPlayOnHover={true}
                    mouseDragEnabled={true}
                    preventEventOnTouchMove={true}
                    onDragStart={handleOnDragStart}>
                    {bannerStore.loginBannerData.map(function (item, index) {
                        return (
                            <div className="RDE-mainBanner" onClick={bannerStore.clickBanner.bind(bannerStore)} key={"bannerItem_" + index} >
                                <div className="RDE-mainBanner__content">
                                    <div className="RDE-mainBanner__innerContent">
                                        {/* <div className="RDE-mainBanner__innerTxt">
                                            <div className="">{item.text}</div>
                                        </div> */}
                                        <img src={item.imageUrl} alt={"bannerItem_" + index} />
                                        {item.isButton && item.buttonType === 1 ? <button className="btn RDE-btnBanner-Register RDE-btn-primary RDE-skew" onClick={appStore.showSignup.bind(appStore)}><span className="RDE-skewTxt">{item.buttonText}</span></button> : <button className="btn RDE-btnBanner-Register RDE-btn-primary RDE-skew" onClick={bannerStore.bannerButtonClick.bind(bannerStore, item.buttonType)}><span className="RDE-skewTxt">{item.buttonText}</span></button>}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </AliceCarousel> : ''}
            </div>
        )
    }
}
export default observer(Banner);
