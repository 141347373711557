import { extendObservable } from "mobx";
import Validator from 'validatorjs';

import { signup } from "../../sharedfiles/EndpointConfig";
import { apiCaller } from '../../sharedfiles/apiStore';

class ForgotPasswordStore {
  constructor(props) {
    extendObservable(this, {
      showResetLoader: false,
      token: "",
      resetFields: {
        newPassword: '',
        confirmPassword: ''
      },
      resetFieldsError: {
        newPassword: '',
        confirmPassword: ''
      },
      showForgotSuccess: false,
      showForgotLoader: false,
      message: "",
      isSuccess: false
    });
  }
  /**
  * This method is used to submit the reset request
  */
  submitReset() {
    var rules = {
      newPassword: 'required|min:8',
      confirmPassword: 'required|same:newPassword',
    };
    var validation = new Validator(this.resetFields, rules, {
      "required.newPassword": "Please enter new password",
      "required.confirmPassword": "Please enter confirm Password",
      "same.confirmPassword": "password and confirm password should match",
      "min.password": "Password must be at least 8 characters.",
    });
    var param = {
      "emailConfirmationToken": this.token,
      "newPassword": this.resetFields.newPassword
    }
    if (validation.passes()) {
      this.showResetLoader = true;
      // call service
      this.message = "";
      apiCaller(signup.resetPassword, 'POST', param).then(response => {
        if (response && response.hasOwnProperty('data') && response.data.hasOwnProperty('updated')) {
          this.isSuccess = response.data.updated;
          if (response.data.hasOwnProperty("message") && response.data.message.length > 0) {
            this.message = response.data.message
          } else {
            this.message = "Password updated successfully."
          }
        }

        this.showForgotSuccess = true;
        this.showForgotLoader = false;
        // if(response.data && response.data.hasOwnProperty('updated') && response.data.updated){
        //   this.forgotMessage = "Link for reset password is sent to your mail,please check the email."
        // }else{
        //   if(response && response.data && response.data.hasOwnProperty('message') && response.data.message.length>0){
        //     this.forgotMessage = response.data.message
        //   }else{
        //     this.forgotMessage = "Unable to send reset link, please try after some time."
        //   }
        // }
      });
    } else {
      if (validation.errors.has('newPassword') && validation.errors.get("newPassword").length > 0) {
        this.resetFieldsError.newPassword = validation.errors.get("newPassword")[0]
      }
      if (validation.errors.has('confirmPassword') && validation.errors.get("confirmPassword").length > 0) {
        this.resetFieldsError.confirmPassword = validation.errors.get("confirmPassword")[0]
      }
    }
  }
  /**
  * This method is called when there is a change in the reset fields(onchange event).
  * this method reads user typed value and assigns to respective state variable 
  * @param -field - {string}- this param contains the field name in which change occered 
  * @param -event- {js event object }- which contains user typed authentication number.
  */
  onResetChange(field, event) {
    switch (field) {
      case 'newPassword':
        this.resetFields.newPassword = event.target.value;
        this.resetFieldsError.newPassword = "";
        break;
      case 'confirmPassword':
        this.resetFields.confirmPassword = event.target.value;
        this.resetFieldsError.confirmPassword = "";
        break;
      default:
        console.log("");
    }
  }
}

export default ForgotPasswordStore;
