import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { DropdownButton, Dropdown, DropdownItem } from "react-bootstrap";
import cookie from 'react-cookies';
import Clock from 'react-live-clock';
import { Route } from "react-router-dom";

import OneClickBet from '../oneclickbet/OneClickBet';
import OneClickBetStore from '../oneclickbet/OneClickBetStore';
import Settings from './settings/Settings';
//import SettingsStore from './settings/SettingsStore';
import AutoCompleteSearch from "../../sharedfiles/autocompletesearch";

const oneClickBetStore = new OneClickBetStore();
//const settingsStore = new SettingsStore();
class SubHeader extends Component {
  componentWillMount() {
    this.props.subHeaderStore.appStore = this.props.appStore;
    this.props.settingsStore.appStore = this.props.appStore;
    this.props.subHeaderStore.settingsStore = this.props.settingsStore;
    this.props.subHeaderStore.getSubHeader();
    if (this.props.isLoggedIn) {
      this.props.settingsStore.getSettingsValue.bind(this.props.settingsStore);
    }

    if (cookie.load("showOneClick") && cookie.load("showOneClick") === "true") {
      this.props.subHeaderStore.showOneClick = true;
    }
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.isLoggedIn !== nextProps.isLoggedIn && nextProps.isLoggedIn) {
      this.props.settingsStore.getSettingsValue.bind(this.props.settingsStore);
    }
  }
  onToggleSettings() { }
  render() {
    const { subHeaderStore, appStore, settingsStore } = this.props;
    return (
      <div className="RDE-subHeader">
        <div className="RDE-mainHeader__time">
          <Clock format={'HH:mm:ss'} ticking={true} /> GMT <Clock format={'Z'} ticking={true} />
        </div>
        <div className="RDE-mainHeader__marquee">
          <marquee>Welcome to Xchange</marquee>
        </div>

        <div className="RDE-searchBox RDE-formGroup">
          {/*<input type="text" className="RDE-formControl" placeholder="Search Events" />
                                  <i className="RDE-icon-search"></i>*/}
          <Route render={(props) => <AutoCompleteSearch {...props} appStore={appStore} app={this} />} />
          <i className="RDE-icon-search"></i>
        </div>

        <div className="RDE-subHeader-rightMenu">
          <ul>
            {
              subHeaderStore.data.map((item, index) => {
                if (item.id === 3 && appStore.isLoggedIn) {
                  return <li id="allowClick" key={"subheader_" + index}>
                    <span onClick={subHeaderStore.toggleSettings.bind(subHeaderStore)} className="RDE-subHeader-rightMenu__item">{item.name}</span>
                    <DropdownButton show={subHeaderStore.showSettings} className="RDE-settingsDropdown RDE-arrow"
                      alignRight
                    >
                      <Settings settingsStore={settingsStore} subHeaderStore={subHeaderStore} appStore={appStore} />
                    </DropdownButton></li>
                } else {
                  return <li key={"subheader_" + index} className={item.id === 1 && cookie.load("showOneClick") === "true" ? "active" : ""} onClick={subHeaderStore.subMenuClick.bind(subHeaderStore, item.id)}><span className="RDE-subHeader-rightMenu__item">{item.name}</span></li>
                }

              })
            }
          </ul>
        </div>

        {subHeaderStore.showOneClick ? <OneClickBet subHeaderStore={subHeaderStore} oneClickBetStore={oneClickBetStore} /> : ""}
      </div>
    )
  }
}
export default observer(SubHeader);
