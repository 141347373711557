import { extendObservable } from "mobx";
import { upcomingHighlights } from "../../../sharedfiles/EndpointConfig";
import { apiCaller } from '../../../sharedfiles/apiStore';
import { getTimeZoneOffset } from '../../../sharedfiles/helper';

class HighlightsUpcomingStore {
  constructor(props) {
    extendObservable(this, {
      bannerData: [],
      appStore: {},
      showTab: 'highlights',
      upcomingSports: [],
      defaultSport: {},
      defaultOption: {},
      upcomingOptions: {
        "all": {},
        "hr": [],
        "today": {},
        "day": []
      }
    });
  }
  /**
  * This method is called from component will mount when component is loaded,
  * in this method we will get the upcoming options and sports data.
  */
  getUpcomingOptions() {
    apiCaller(upcomingHighlights.getUpcomingOptions + "?timeInterval=" + getTimeZoneOffset(), 'GET').then(response => {
      if (response.data && response.data.hasOwnProperty('buttons') &&
        response.data.buttons && response.data.buttons.length > 0) {
        this.upcomingSports = response.data.buttons;
        if (Object.keys(this.defaultSport).length === 0) {
          this.defaultSport = this.upcomingSports[0];
        }

      }
      if (response.data && response.data.hasOwnProperty('options') &&
        response.data.options && response.data.options.length > 0) {
        this.upcomingOptions = {
          "all": {},
          "hr": [],
          "today": {},
          "day": []
        }
        for (var option of response.data.options) {
          if (option.unitMeasure === "HR") {
            this.upcomingOptions.hr.push(option);
          } else if (option.unitMeasure === "Day") {
            this.upcomingOptions.day.push(this.calculateDayOption(option));
          } else if (option.unitMeasure === "All") {
            this.upcomingOptions.all = option;
            this.defaultOption = option;
          } else if (option.unitMeasure === "Today") {
            this.upcomingOptions.today = option;
          }
        }
      }
    });
  }
  /**
  * This method is used to calculate the date based on the frequency provided in service
  * @param option-{object} contains the option
  */
  calculateDayOption(option) {
    var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    if (option.hasOwnProperty('frequency') && parseInt(option.frequency) > 0) {
      var d = new Date();
      d.setDate(d.getDate() + parseInt(option.frequency));
      option.day = d.getDate();
      option.month = monthNames[d.getMonth()];
      return option
    }
  }
  /**
  * This method is used to toggle between highlights and upcoming.
  * @param show - {string} this variable contains the 'highlights' or 'upcoming', based on which we will show
  */
  toggleHighlightsUpcoming(show) {
  //  console.log(show)
    this.showTab = show;
  }
}

export default HighlightsUpcomingStore;
