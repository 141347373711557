import { extendObservable } from "mobx";
import cookie from 'react-cookies';

import { sportsPage } from "../../../sharedfiles/EndpointConfig";
import { apiCaller } from '../../../sharedfiles/apiStore';
import { getTimeZoneOffset } from '../../../sharedfiles/helper';

class LeaguePageComponentStore {
  constructor(props) {
    extendObservable(this, {
      selectedTab: "highlights",
      sportName: "",
      sportId: "",
      leagueName: "",
      leagueId: "",
      leagueEvents: [],
      showLoader: false,
      showErrorMsg: false
    });
  }
  /**
  * This method is used to get the league events based on league selected.
  *
  */
  getLeagueEvent() {
    this.showLoader = true;
    this.showErrorMsg = false;
    this.leagueEvents = []
    var token = "";
    var cookeTocken = cookie.load('UTocken')
    if (cookeTocken && cookeTocken.length > 0) {
      token = cookeTocken;
    }
    apiCaller(sportsPage.getLeagueWiseEvents + "?timeInterval=" + getTimeZoneOffset() + "&leagueId=" + this.leagueId + "&userToken=" + token, 'GET').then(response => {
      this.showLoader = false;
      if (response.data && response.data.hasOwnProperty('leagues') &&
        response.data.leagues && response.data.leagues.length > 0) {
        this.leagueEvents = response.data.leagues;
      } else {
        this.showErrorMsg = true;
      }
    });
  }
}

export default LeaguePageComponentStore;
