import React, { Component } from 'react';
import { observer } from 'mobx-react';
class BetSlipEventComponent extends Component {
  componentWillMount() {
    // if(this.props.appStore.isLoggedIn && localStorage && localStorage.getItem('stakeCoins')){
    //   console.log("user coins===",localStorage.getItem('stakeCoins'));
    //   this.props.betSlipEventComponentStore.stakeCoins = JSON.parse(localStorage.getItem('stakeCoins')).coins;
    // }else{
    //   this.props.betSlipEventComponentStore.stakeCoins = [
    //                                                           {"coinValue":10},
    //                                                           {"coinValue":50},
    //                                                           {"coinValue":100},
    //                                                           {"coinValue":200},
    //                                                           {"coinValue":300},
    //                                                           {"coinValue":400}
    //                                                       ]
    // }
    // this.props.betSlipEventComponentStore.userOdds = this.props.selection.odds;
    this.props.betSlipEventComponentStore.appStore = this.props.appStore;
  }
  componentWillReceiveProps(nextProps) {
    this.props.betSlipEventComponentStore.appStore = this.props.appStore;
    if (this.props.odds !== nextProps.odds) {
      if (nextProps.selection.isFancy) {
        this.props.betSlipEventComponentStore.calculateFancyReturns(nextProps.selection);
      } else {
        this.props.betSlipEventComponentStore.calculateReturns(nextProps.selection);
      }
      this.props.appStore.calProfitLoss();
    }
  }
  render() {
    const { betSlipEventComponentStore,
      selection,
      stakeCoins,
      betslipComponentStore,
      betIndex,
      betType, } = this.props;
    //  console.log("selection===", selection);
    return (
      <div className="RDE-betslipBox RDE-backOddsBetslipBox">
        <div className="RDE-betslipBox__content truncate">
          <div className="RDE-betslipBox__fixture truncate">{selection.eventName}
          </div>
          <div className="RDE-betslipBox__marketOutcome">
            <span>{selection.marketName} - </span>
            <span className="RDE-betslipBox__outcome">{selection.selectionName}</span>
          </div>
          <div className="RDE-betslipBox__oddsStakeReturns">
            {!selection.isBetFair ? <div className="RDE-betslipBox__oddsBox disabled">{selection.userOdds} </div>
              : <div className="RDE-betslipBox__oddsBox">
                <input type="text" className="RDE-formControl" value={selection.userOdds}
                  onChange={betSlipEventComponentStore.oddsChanged.bind(betSlipEventComponentStore, selection, betslipComponentStore)} />
                <i className="RDE-icon-plus" onClick={betSlipEventComponentStore.oddsInc.bind(betSlipEventComponentStore, selection, betslipComponentStore)}></i>
                <i className="RDE-icon-minus" onClick={betSlipEventComponentStore.oddsDec.bind(betSlipEventComponentStore, selection, betslipComponentStore)}></i>
              </div>}
            <div className="RDE-betslipBox__stakeBox">
              <input type="text" className="RDE-formControl" placeholder="Stake" value={selection.userStake}
                onChange={betSlipEventComponentStore.stakeChanged.bind(betSlipEventComponentStore, selection, betslipComponentStore)} />
            </div>
            <div className="RDE-betslipBox__retunsBox">
              <span>{betType === 'BO' ? 'Profit' : 'Liability'}: </span>
              <span className={"RDE_betslipBox__retunsValue " + (betType === 'BO' ? 'profit' : 'loss')}>{(selection.returns - selection.userStake).toFixed(2)}</span>
            </div>

          </div>
          {selection.stakeError.length > 0 ? <div className="RDE-betslip-errorMsgBox">{selection.stakeError}</div> : ""}
          <div className="RDE-betslipBox__stakeList">
            <ul>
              {
                stakeCoins.map((coin, index) => {
                  return <li key={selection.selectionId + "_" + index} onClick={betSlipEventComponentStore.stakeSelected.bind(betSlipEventComponentStore, coin.coinValue, selection, betslipComponentStore)}><a>{coin.coinValue}</a></li>
                })
              }
            </ul>
          </div>
        </div>
        <div className="RDE-betslipBox__close" onClick={betslipComponentStore.removeBet.bind(betslipComponentStore, betIndex, betType)}>
          <i className="RDE-icon-close"></i>
        </div>
      </div>
    )
  }
}
export default observer(BetSlipEventComponent);
