import React, { Component } from 'react';
import { observer } from 'mobx-react';
import cookie from 'react-cookies';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Dropdown, DropdownButton } from "react-bootstrap";
import dataLoader from '../../../../../assets/images/loader-orange.svg';
import { utcToTimeZone } from '../../../../../sharedfiles/helper';

class BetHistory extends Component {
  componentWillMount() {
    this.props.betHistoryStore.resetFields();
  }
  componentWillReceiveProps(nextProps) { }
  render() {
    const { betHistoryStore } = this.props;
    return (
      <div>
        <div className="RDE-DB-myBets-filters">
          <div className="RDE-DB-myBets-filters__left">
            <span className="RDE-DB-myBets-filters__label">Bet status :</span>
            <Dropdown className="RDE-DB-dropdownDefault">
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                {betHistoryStore.betStatus}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <ul>
                  <li onClick={betHistoryStore.selectBetStatus.bind(betHistoryStore, 'Open bets')}><Dropdown.Item eventKey="openbets">Open bets</Dropdown.Item></li>
                  <li onClick={betHistoryStore.selectBetStatus.bind(betHistoryStore, 'Settled bets')}><Dropdown.Item eventKey="settled">Settled bets</Dropdown.Item></li>
                  <li onClick={betHistoryStore.selectBetStatus.bind(betHistoryStore, 'Suspended')}><Dropdown.Item eventKey="suspended">Suspended</Dropdown.Item></li>
                </ul>
              </Dropdown.Menu>
            </Dropdown>
            <div className="RDE-space"></div>
            {/*<div className="RDE-dropdownDefault show dropdown">
                <button aria-haspopup="true" aria-expanded="true" type="button" className="dropdown-toggle btn btn-primary">
                  Settled
                </button>
                <div aria-labelledby="" className="dropdown-menu show dropdown-menu-right" x-placement="bottom-end">
                  <div>
                    <ul className="RDE-settingsDropdownMenu">
                      <li>
                        
                      </li>
                    </ul>
                  </div>
                </div>
              </div>*/}
            <span className="RDE-DB-myBets-filters__label">Period From :</span>
            <div className="RDE-DB-myBets-filters__datePicker">
              <DatePicker selected={betHistoryStore.fromDate}
                dateFormat="dd/MM/yyyy"
                onChange={betHistoryStore.selectFromDate.bind(betHistoryStore)}
                className="RDE-DB-datePickerInput"
                maxDate={new Date()} />

              <span className="RDE-DB-myBets-filters__label">To :</span>
              <DatePicker selected={betHistoryStore.toDate}
                selectsEnd
                dateFormat="dd/MM/yyyy"
                onChange={betHistoryStore.selectToDate.bind(betHistoryStore)}
                className="RDE-DB-datePickerInput"
                maxDate={new Date()} />
              {/* <span className="RDE-DB-myBets-filters__label">Order by :</span> */}
              <span className="RDE-DB-datePickerErrorMessage">{betHistoryStore.dateErrMsg}</span>
            </div>
            <button className="btn RDE-btn-primary RDE-btn-small RDE-btn-auto" onClick={betHistoryStore.getHistory.bind(betHistoryStore)}>Get History</button>
          </div>
          <div className="RDE-DB-myBets-filters__right">
            <ul className="RDE-DB-myBets-filters__todayYesterdayList">
              <li className={betHistoryStore.selectedOption === "today" ? "active" : ""}
                onClick={betHistoryStore.selectTodayYesterday.bind(betHistoryStore, 'today')}>Just For Today</li>
              <li className={betHistoryStore.selectedOption === "yesterday" ? "active" : ""}
                onClick={betHistoryStore.selectTodayYesterday.bind(betHistoryStore, 'yesterday')}>From Yesterday</li>
            </ul>
          </div>
        </div>
        {!betHistoryStore.showErrorMsg && !betHistoryStore.showLoader ? <div className="RDE-DB-myBets__betHistory">
          <div className="RDE-DB-table">
            <table className="RDE-DB-table-default">
              <thead>
                <tr>
                  <th>Bet ID</th>
                  {/*<th>PL ID</th>*/}
                  <th className="RDE-DB-marketColumn">Market</th>
                  <th>Selection</th>
                  <th>Type</th>
                  <th className="RDE-DB-dateTimeColumn">Bet placed</th>
                  <th>Odds</th>
                  <th>Stake</th>
                  {/*<th>Avg. Odds matched</th>*/}
                  {/*<th>Profit/Loss</th>*/}
                </tr>
              </thead>
              <tbody>
                {
                  betHistoryStore.userBetDetails.map((item, index) => {
                    return <tr key="bethistory_" index>
                      <td>{item.betSlipId}</td>
                      {/*<td>{item.profileId}</td>*/}
                      <td>{item.bMarket && item.bMarket.length > 0 ? item.bMarket : '--'}</td>
                      <td>{item.bSelection && item.bSelection.length > 0 ? item.bSelection : '--'}</td>
                      <td>{item.betType && item.betType.length > 0 ? item.betType : '--'}</td>
                      <td>{item.betPlaced && item.betPlaced.length > 0 ? utcToTimeZone(item.betPlaced).transactionDate : '--'}</td>
                      <td>{item.betOdds > 0 ? item.betOdds : "--"}</td>
                      <td>{item.betStake > 0 ? item.betStake : '--'}</td>
                      {/*<td>{item.totalMatched > 0 ? item.totalMatched : "--"}</td>*/}
                      {/*<td><span className="RDE-DB-txtHighlight">({item.profitLoss})</span></td>*/}
                    </tr>
                  })
                }

              </tbody>
            </table>
          </div>
        </div> : ""}
        {betHistoryStore.showErrorMsg ? <div className="RDE-noDataMessageBox">
          Currently data is not available for your selection.
        </div> : ''}
        {betHistoryStore.showLoader ? <div className="RDE-loader">
          <img src={dataLoader} alt="loading...!" />
        </div> : ''}
      </div>
    )
  }
}
export default observer(BetHistory);
