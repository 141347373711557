import React, { Component } from 'react';
import { observer } from 'mobx-react';

import dataLoader from '../../assets/images/loader-orange.svg';
import logo from '../../assets/images/playbig-logo.svg';

class ForgotPassword extends Component {
  componentWillMount() {
    if (this.props.match && this.props.match.params.token !== null) {
      this.props.forgotPasswordStore.token = this.props.match.params.token;

    }
  }
  gotoLogin() {
    this.props.history.push('/login')
  }
  componentDidMount() {
  }
  componentWillUnmount() {
  }
  render() {
    const { forgotPasswordStore } = this.props;
    return (
      <div className="RDE-loginSignupPage">
        <div className="RDE-modal">
          <div className="RDE-changePassword-modal RDE-loginSignup-modal">
            <div className="RDE-loginSignup-modal-content">
              <div className="RDE-loginSignup-form ">
                <div className="RDE-loginSignupBox RDE-logIn">
                  <div class="RD-loginLogo">
                    <img src={logo} class="RDE-logo" />
                    <div className='RDE-logo__caption'>Exchange</div>
                  </div>
                  <div className="">
                    <div className="RDE-loginSignupBox-header">Change Password</div>
                    {!forgotPasswordStore.showForgotSuccess ? <div className="RDE-loginSignupBox-content">
                      <div className="RDE-formGroup">
                        {/* <input type="text" className="RDE-formControl" placeholder="New Password" value={forgotPasswordStore.resetFields.newPassword} onChange={forgotPasswordStore.onResetChange.bind(forgotPasswordStore,'newPassword')}/> */}
                        <input type="password" className="RDE-formControl" placeholder="New Password" autoComplete="off" value={forgotPasswordStore.resetFields.newPassword} onChange={forgotPasswordStore.onResetChange.bind(forgotPasswordStore, 'newPassword')} />
                      </div>
                      <div className="RDE-formControl-errorMessage">
                        {forgotPasswordStore.resetFieldsError.newPassword}
                      </div>
                      <div className="RDE-formGroup">
                        {/* <input type="password" className="RDE-formControl" placeholder="Confirm Password"  value={forgotPasswordStore.resetFields.confirmPassword} onChange={forgotPasswordStore.onResetChange.bind(forgotPasswordStore,'confirmPassword')} /> */}
                        <input type="password" className="RDE-formControl" placeholder="Confirm Password" autoComplete="off" value={forgotPasswordStore.resetFields.confirmPassword} onChange={forgotPasswordStore.onResetChange.bind(forgotPasswordStore, 'confirmPassword')} />
                      </div>
                      <div className="RDE-formControl-errorMessage">
                        {forgotPasswordStore.resetFieldsError.confirmPassword}
                      </div>
                      <div className="RDE-formControl-errorMessage"></div>
                      <div className="RDE-formGroup text-center">
                        {/* <button className="btn RDE-btn-primary RDE-btn-large" onClick={forgotPasswordStore.submitReset.bind(forgotPasswordStore)}>Submit</button> */}
                        <button className="btn RDE-btn-primary RDE-btn-large" onClick={forgotPasswordStore.submitReset.bind(forgotPasswordStore)}>Submit</button>
                      </div>
                    </div> : <div>{forgotPasswordStore.message}</div>}
                    <div class="RDE-loginSignupBox-footer grid-middle-noGutter text-center">
                      <div class="RDE-loginSignupBox-footer__goBack">
                        <i class="RDE-go-back"></i><span class="RDE-forgotLink" onClick={this.gotoLogin.bind(this)}> Back to Login</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*<div className="RD-passwordReset-dialog">
            <div className="modal" style={{"display": "block"}}>
              <div className="RDE-centered-modal RDE-loginSignup-modal">
                <div className="RD-loginLogo">
                  <img src={logo} className="RDE-logo" />
                </div>
                <div className="RDE-loginSignup-modal-content">
                  <div className="RDE-loginSignup-form">
                    <div className="RDE-loginSignupBox RDE-logIn">
                      <div className="">
                        <div className="RDE-loginSignupBox-header">Login</div>
                        <div className="RDE-loginSignupBox-content">
                          <div className="RDE-formGroup">
                            <input type="text" className="RDE-formControl" placeholder="New Password" value={forgotPasswordStore.resetFields.newPassword} onChange={forgotPasswordStore.onResetChange.bind(forgotPasswordStore,'newPassword')}/>
                          </div>
                          <div className="RDE-formControl-errorMessage">{forgotPasswordStore.resetFieldsError.newPassword}</div>
                          <div className="RDE-formGroup">
                            <input type="password" className="RDE-formControl" placeholder="Confirm Password"  value={forgotPasswordStore.resetFields.confirmPassword} onChange={forgotPasswordStore.onResetChange.bind(forgotPasswordStore,'confirmPassword')} />
                          </div>
                          <div className="RDE-formControl-errorMessage">{forgotPasswordStore.resetFieldsError.confirmPassword}</div>
                          <div className="RDE-formControl-errorMessage"></div>
                          <div className="RDE-formGroup text-center">
                            <button className="btn RDE-btn-primary RDE-btn-large" onClick={forgotPasswordStore.submitReset.bind(forgotPasswordStore)}>Submit</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>*/}
        </div>
      </div>
    )
  }
}
export default observer(ForgotPassword);
