import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Route } from "react-router-dom";

import Banner from "../../sharedCcomponents/banner/Banner";
import BannerStore from "../../sharedCcomponents/banner/BannerStore";
import SportsPageComponent from "./sportspagecomponent/SportsPageComponent";
import SportsPageComponentStore from "./sportspagecomponent/SportsPageComponentStore";

const bannerStore = new BannerStore();
const sportsPageComponentStore = new SportsPageComponentStore();
class Sportspage extends Component {
  componentWillMount() {
    this.props.appStore.clearBetslip();
  }
  componentDidMount() {

  }

  componentWillUnmount() {
  }

  onInitialFetch(groups) { }

  componentWillReceiveProps(nextProps) {
  }

  render() {
    const { appStore } = this.props;
    return (
      <div className="RDE-mainContent">
        <Route render={(props) => <Banner {...props} bannerStore={bannerStore} appStore={appStore} pageId={1} isLoggedIn={appStore.isLoggedIn} />} />
        <Route render={(props) => <SportsPageComponent {...props} sportId={appStore.sportId}
          sportName={appStore.sportName}
          sportsPageComponentStore={sportsPageComponentStore}
          appStore={appStore} />} />
      </div>
    );
  }
}

export default observer(Sportspage);
