import { extendObservable } from 'mobx';
import Validator from 'validatorjs';
import cookie from 'react-cookies';
import createBrowserHistory from 'history/createBrowserHistory'

import { login } from "./sharedfiles/EndpointConfig";
import { apiCaller } from './sharedfiles/apiStore';
import { masterPage } from "./sharedfiles/EndpointConfig";
import { getAuthenticationNumber } from "./sharedfiles/helper"
const re = /^[0-9\b]+$/;

class AppStore {
  constructor() {
    extendObservable(this, {
      showSignupPopup: false,
      isLoggedIn: false,
      mounted: true,
      headerData: [],
      userMenu: [],
      authNumber: getAuthenticationNumber(),
      email: "",
      password: "",
      reAuthNumber: '',
      errorMsg: '',
      leftMenuData: [],
      showSignupBlock: false,
      searchValue: '',
      searchSuggestions: [],
      searchResults: [],
      userInfo: {},
      betSlipStore: {},
      currentPath: "",
      history: createBrowserHistory(),
      sportName: "",
      sportId: "",
      appProps: {},
      showTC: false,
      betSlipStatusChange: false,
      eventViewId: '',
      coinValuesChanged: false,
      showDeposit: false,
      betsProfitLoss: [],
      pLStatusChanged: false,
      leagueslist: [],
      selectedLeagueId: 0,
      eventsList: [],
      isLeagueLoading: false,
      isEventLoading: false,
      selectedLeftMenuSportId: '',
      selectedOpenBetsEvent: {
        eventId: '',
        eventName: ''
      },
      openBetsEventIds: [],
      showLogoutPopup: false,
      initialLoad: true,
      showLiveStreaming: false
    });
    var betSlipItems = JSON.parse(localStorage.getItem("betslip"));
    if (!betSlipItems) {
      betSlipItems = {
        backBets: [],
        layBets: []
      }
      localStorage.setItem("betslip", JSON.stringify(betSlipItems));
    }
  }
  showSignup() {
    this.showSignupPopup = true;
    this.showSignupBlock = true;
  }
  hideSignup() {
    this.showSignupPopup = false;
  }
  showDepositPopup() {
    this.showDeposit = true;
  }
  hideDepositPopup() {
    this.showDeposit = false;
  }
  showLogin() {
    this.showSignupPopup = true;
    this.showSignupBlock = false;
  }
  selectSport(sportName, sportId) {
    this.sportName = sportName;
    this.sportId = sportId;
  }
  selectSportFromMenu(itemId, itemName) {
    if (itemId === 6 || itemId === 7 || itemId === 8) {
      switch (itemName.toLowerCase()) {
        case 'football':
          this.sportName = itemName;
          this.sportId = 1;
          break;
        case 'cricket':
          this.sportName = itemName;
          this.sportId = 4;
          break;
        case 'tennis':
          this.sportName = itemName;
          this.sportId = 2;
          break;
        default:
          console.log()
      }
    }
  }
  selectLeague(leagueId) {
    if (this.selectedLeagueId !== leagueId) {
      this.selectedLeagueId = leagueId;
      this.getEvents()
    } else {
      this.selectedLeagueId = 0;
    }

  }
  selectLeftMenuSport(sportName, sportId) {
    if (this.selectedLeftMenuSportId !== sportId) {
      this.selectedLeftMenuSportId = sportId
      this.selectSport(sportName, sportId);
      this.getLeagues();
    } else {
      this.selectedLeftMenuSportId = '';
    }

  }
  getLeagues() {
    this.isLeagueLoading = true;
    this.leagueslist = [];
    apiCaller(masterPage.getLeagues + "?userToken=" + cookie.load('UTocken') + "&sportId=" + this.selectedLeftMenuSportId, 'GET').then(response => {
      this.isLeagueLoading = false;
      if (response && response.data && response.data.hasOwnProperty('leagues') && response.data.leagues.length > 0) {
        this.leagueslist = response.data.leagues;
      }
    });
  }
  clearBetslip() {
    var betSlipItems = {
      backBets: [],
      layBets: []
    }
    localStorage.setItem("betslip", JSON.stringify(betSlipItems));
    this.betSlipStore.betSlipItems = betSlipItems;
    this.betsProfitLoss = [];
    this.pLStatusChanged = !this.pLStatusChanged;
    this.getBetsProfitLoss();

  }
  getEvents() {
    this.eventsList = [];
    this.isEventLoading = true;
    apiCaller(masterPage.getEvents + "?userToken=" + cookie.load('UTocken') + "&leagueId=" + this.selectedLeagueId, 'GET').then(response => {
      this.isEventLoading = false;
      if (response && response.data && response.data.hasOwnProperty('events') && response.data.events.length > 0) {
        this.eventsList = response.data.events;
      }
    });
  }
  goToHome() {
    this.appProps.history.go('/')
  }
  navigateEventView(eventId) {
    // this.props.sportId = this.props.sportId;
    // this.props.sportName = this.props.sportName;
    // this.props.eventViewId = this.props.event.id;
    this.eventViewId = eventId;
    this.appProps.history.push('/sports/' + this.sportName + '/' + this.sportId + '/event/' + eventId);
  }
  validateLoginFields(data, callback) {
    var rules = {
      emailId: 'required|min:8',
      password: 'required|min:8'
    };
    var validation = new Validator(data, rules);
    if (validation.passes()) {
      callback(true);
    } else {
      callback(false);
    }
  }
  mainLogin(data, callback) {
    apiCaller(login.login, 'POST', data).then(response => {
      if (response?.data && response?.data?.isValidLogin && response?.data?.userLoginToken?.length > 0 &&
        response?.data?.userId > 0 &&
        response?.data?.userName?.length > 0 &&
        response?.data?.hasOwnProperty('userExposure') &&
        response?.data?.hasOwnProperty('userAccountBalance') &&
        response?.data?.hasOwnProperty('roleId') &&
        response?.data?.roleId === 4
      ) {
        cookie.save("UTocken", response.data.userLoginToken, { path: '/' });
        var userInfo = {
          userId: response.data.userId,
          userName: response.data.userName,
          userAccountBalance: response.data.userAccountBalance,
          userExposure: response.data.userExposure,
          currency: response.data.currencyCode
        }
        //this.showLiveStreaming=true;
        this.userInfo = userInfo;
        cookie.save("userInfo", userInfo, { path: '/' });
        // window.location.reload();
        this.getUserMenu();
        this.isLoggedIn = true;
        this.hideSignup();
        callback(true, response?.description);
      } else {
        callback(false, response?.description);
      }
    });
  }
  /**
  *
  */
  getUserBalanceDetails() {
    var token = "";
    var cookeTocken = cookie.load('UTocken');
    if (cookeTocken && cookeTocken.length > 0) {
      token = cookeTocken;
    }
    apiCaller(masterPage.getBalance + '?loginToken=' + token, 'GET').then(response => {
      if (
        response?.data?.hasOwnProperty('exposure') &&
        response?.data?.hasOwnProperty('userBalance')
      ) {
        var userInfo = cookie.load('userInfo') ? cookie.load('userInfo') : {};
        userInfo.userAccountBalance = response.data.userBalance;
        userInfo.userExposure = response.data.exposure;
        this.userInfo = userInfo;
        cookie.save("userInfo", userInfo, { path: '/' });
      }
      else {
        this.showLogoutPopup = true;
      }
    });
  }
  /**
  * This method is used to get the left menu data.
  *
  */
  getHeaderData() {
    apiCaller(masterPage.getHeader, 'GET').then(response => {
      if (response?.data?.headersList?.length > 0) {
        this.headerData = response.data.headersList;
      }
    });
  }
  /**
  * This method is used to get the user menu data.
  *
  */
  getUserMenu() {
    apiCaller(masterPage.userMenu, 'GET').then(response => {
      if (response?.data?.userMenu?.length > 0) {
        this.userMenu = response.data.userMenu;
      }
    });
  }
  /**
  * This method is called when user selects the user menu.
  * @param {menuId} - number, this number contains user menu id.
  */
  userMenuClick(menuId) {
    if (menuId === 8) {
      this.logout();
    }
  }
  /**
  * This mentod is used to logout user
  *
  */
  logout() {
    var param = {
      "loginToken": cookie.load('UTocken')
    };
    apiCaller(masterPage.logout, 'POST', param).then(response => {
      if (response?.data?.userLogoutStatus) {
        cookie.remove('UTocken', { path: '/' });
        cookie.remove('oneConfirm', { path: '/' });
        cookie.remove('selectedStake', { path: '/' });
        cookie.remove('showOneClick', { path: '/' });
        cookie.remove('userInfo', { path: '/' });
        localStorage.removeItem('defaultStake');
        this.isLoggedIn = false;
        window.location.href = '/login';
      }
    });
  }
  /**
  * This method is used to save the bets Profit and loss.
  */
  saveProfitLoss() {
    var params = {
      "userToken": cookie.load('UTocken'),
      "profitLossDetails": []
    }
    for (var bet of this.betsProfitLoss) {
      params.profitLossDetails.push({
        "eventId": bet.eventId,
        "marketId": bet.marketId,
        "homeTeamProfitLoss": bet.homeProfitLoss,
        "awayTeamProfitLoss": bet.awayProfitLoss,
        "drawTeamProfitLoss": bet.drawProfitLoss,
        "providerId": bet.providerId,
        "isFancy": bet.isFancy
      });
    }
    apiCaller(masterPage.saveProfitLoss, 'POST', params).then(response => {
      if (response?.data?.detailsUpdated) {
        //this.betsProfitLoss = [];
        this.getBetsProfitLoss();
      }
    });
  }
  /**
  * This method is used to get the bet profit loss of the user. 
  */
  getBetsProfitLoss() {
    var token = cookie.load('UTocken');
    var self = this;
    if (token && token.length > 0) {
      apiCaller(masterPage.getProfitLoss + "?userToken=" + cookie.load('UTocken'), 'GET').then(response => {
        if (response?.data?.profitLossDetails?.length > 0) {
          //this.betsProfitLoss = response.data.profitLossDetails;
          var betsProfitLoss = [];
          for (var bet of response.data.profitLossDetails) {
            betsProfitLoss.push({
              eventId: bet.eventId,
              marketId: bet.marketId,
              providerId: bet.providerId,
              subMarket: bet.subMarket,
              odds: 0,
              isFancy: bet.isFancy,
              homeTeamProfitLoss: bet.homeTeamProfitLoss,
              awayTeamProfitLoss: bet.awayTeamProfitLoss,
              drawTeamProfitLoss: bet.drawTeamProfitLoss,
              homeProfit: null,
              homeProfitStake: null,
              drawProfit: null,
              drawProfitStake: null,
              awayProfit: null,
              awayProfitStake: null,
              homeLoss: null,
              homeLossStake: null,
              drawLoss: null,
              drawLossStake: null,
              awayLoss: null,
              awayLossStake: null,
            })
          }
          for (var localBet of self.betsProfitLoss) {
            var isFound = false;
            for (var i = 0; i < betsProfitLoss.length; i++) {
              if (localBet.eventId === betsProfitLoss[i]['eventId'] &&
                localBet.marketId === betsProfitLoss[i]['marketId'] &&
                localBet.providerId === betsProfitLoss[i]['providerId'] &&
                localBet.subMarket === betsProfitLoss[i]['subMarket']) {
                isFound = true;
                // betsProfitLoss[i]['homeTeamProfitLoss']=localBet.homeTeamProfitLoss;
                // betsProfitLoss[i]['awayTeamProfitLoss']= localBet.awayTeamProfitLoss;
                // betsProfitLoss[i]['drawTeamProfitLoss']= localBet.drawTeamProfitLoss;

                betsProfitLoss[i]['homeProfit'] = localBet.homeProfit;
                betsProfitLoss[i]['homeProfitStake'] = localBet.homeProfitStake;

                betsProfitLoss[i]['drawProfit'] = localBet.drawProfit;
                betsProfitLoss[i]['drawProfitStake'] = localBet.drawProfitStake;

                betsProfitLoss[i]['awayProfit'] = localBet.awayProfit;
                betsProfitLoss[i]['awayProfitStake'] = localBet.awayProfitStake;

                betsProfitLoss[i]['homeLoss'] = localBet.homeLoss;
                betsProfitLoss[i]['homeLossStake'] = localBet.homeLossStake;

                betsProfitLoss[i]['drawLoss'] = localBet.drawLoss;
                betsProfitLoss[i]['drawLossStake'] = localBet.drawLossStake;

                betsProfitLoss[i]['awayLoss'] = localBet.drawLoss;
                betsProfitLoss[i]['awayLossStake'] = localBet.drawLossStake;

                betsProfitLoss[i]['homeProfitLoss'] = localBet.homeProfitLoss;
                betsProfitLoss[i]['awayProfitLoss'] = localBet.awayProfitLoss;
                betsProfitLoss[i]['drawProfitLoss'] = localBet.drawProfitLoss;

              }
            }
            if (!isFound) {
              betsProfitLoss.push(localBet)
            }
          }
          self.betsProfitLoss = betsProfitLoss;
          self.pLStatusChanged = !self.pLStatusChanged;
        }
        else {
          //self.betsProfitLoss = [];
          //self.pLStatusChanged = !self.pLStatusChanged;
        }
      });
    }
  }
  /**
  * This method is called when there is a change in the login fields(onchange event).
  * this method reads user typed value and assigns to respective state variable 
  * @param -field - {string}- this param contains the field name in which change occered 
  * @param -event- {js event object }- which contains user typed authentication number.
  */
  onLoginFiledChange(field, event) {
    switch (field) {
      case 'email':
        this.email = event.target.value;
        this.errorMsg = '';
        break;
      case 'password':
        this.password = event.target.value;
        this.errorMsg = '';
        break;
      case 'reAuthNumber':
        if (event.target.value === '' || re.test(event.target.value)) {
          this.reAuthNumber = event.target.value;
          this.errorMsg = '';
        }
        break;
      default:
        console.log("login default");
    }
  }
  /**
  * This method used to get the search data based on user input.
  * @param {data} - string - contains the search string.
  */
  getSearchData(searchString) {
    // console.log(searchString)
    if (searchString.length > 2) {
      var params = {
        "name": searchString
      }
      apiCaller(masterPage.search, 'POST', params).then(response => {
        if (response?.data?.event?.length > 0) {
          this.searchSuggestions = response.data.event;
        }
      });
    }

  }
  /**
  * This method is called when betslip is updated( add or remove bets)
  */
  updateBetSlipStatus() {
    this.betSlipStatusChange = !this.betSlipStatusChange;
  }
  calOpenBetsProfitLoss(bet, betType) {
    bet.selectionName = bet.outcomeName;
    bet.userOdds = Number(bet.changedOdds);
    bet.betType = betType === "back" ? "BO" : "LO";
    bet.userStake = bet.changedStake;
    bet.returns = bet.changedReturns;
    var newBetPL = this.findPLBets(bet);
    for (var betPL of this.betsProfitLoss) {
      var isFound = false
      if (betPL.eventId === newBetPL.eventId &&
        betPL.marketId === newBetPL.marketId &&
        betPL.providerId === newBetPL.providerId &&
        betPL.subMarket === newBetPL.subMarket) {
        isFound = true;
      }
    }

    if (!isFound) {
      this.betsProfitLoss.push(newBetPL)
    }
    this.calculatePL();
  }
  /**
  * This method is used to calculate the profit and loss
  */
  calProfitLoss() {
    var betsProfitLoss = [];
    for (var bet of this.betSlipStore.betSlipItems.backBets) {
      var findbet = this.findPLBets(bet)
      betsProfitLoss.push(findbet);
    }
    for (var bet of this.betSlipStore.betSlipItems.layBets) {
      var findbet = this.findPLBets(bet)
      betsProfitLoss.push(findbet);
    }
    for (var betPL of this.betsProfitLoss) {
      var isFound = false
      for (var bet of betsProfitLoss) {
        if (betPL.eventId === bet.eventId &&
          betPL.marketId === bet.marketId &&
          betPL.providerId === bet.providerId &&
          betPL.subMarket === bet.subMarket) {
          isFound = true;
        }
      }
      if (!isFound) {
        betsProfitLoss.push(betPL)
      }
    }
    this.betsProfitLoss = betsProfitLoss;
    this.calculatePL();
  }
  findPLBets(bet) {
    var isFound = false;
    for (var betPL of this.betsProfitLoss) {
      if (bet.marketId === 24 || bet.marketId === 2 || bet.marketId === 3 || bet.isFancy) {
        if (betPL.eventId === bet.eventId &&
          betPL.marketId === bet.marketId &&
          betPL.providerId === bet.providerId &&
          betPL.subMarket === bet.subMarket) {
          isFound = true;
          if (bet.selectionName.toLowerCase().includes('yes') || bet.homeTeam === bet.selectionName || bet.selectionName.toLowerCase().includes('under')) {
            if (bet.betType === 'BO') {
              betPL.homeProfit = bet.isFancy && bet.marketId !== 1 ? Number(bet.userStake) : Number(bet.returns) - Number(bet.userStake);
              betPL.homeProfitStake = Number(bet.userStake);
              betPL.homeLoss = 0;
              betPL.homeLossStake = 0;
              betPL.awayProfit = 0;
              betPL.awayProfitStake = 0;
              betPL.awayLoss = 0;
              betPL.awayLossStake = 0;
              betPL.drawProfit = 0;
              betPL.drawProfitStake = 0;
              betPL.drawLoss = 0;
              betPL.drawLossStake = 0;
            }
            if (bet.betType === 'LO') {
              betPL.homeLoss = Number(bet.returns) - Number(bet.userStake);
              betPL.homeLossStake = Number(bet.userStake);
              betPL.homeProfit = 0;
              betPL.homeProfitStake = 0;
              betPL.awayProfit = 0;
              betPL.awayProfitStake = 0;
              betPL.awayLoss = 0;
              betPL.awayLossStake = 0;
              betPL.drawProfit = 0;
              betPL.drawProfitStake = 0;
              betPL.drawLoss = 0;
              betPL.drawLossStake = 0;
            }
          } else if (bet.selectionName.toLowerCase().includes('no') || bet.awayTeam === bet.selectionName || bet.selectionName.toLowerCase().includes('over')) {
            if (bet.betType === 'BO') {
              betPL.homeProfit = 0;
              betPL.homeProfitStake = 0;
              betPL.homeLoss = 0;
              betPL.homeLossStake = 0;
              betPL.awayProfit = bet.isFancy && bet.marketId !== 1 ? Number(bet.userStake) : Number(bet.returns) - Number(bet.userStake);
              betPL.awayProfitStake = Number(bet.userStake);
              betPL.awayLoss = 0;
              betPL.awayLossStake = 0;
              betPL.drawProfit = 0;
              betPL.drawProfitStake = 0;
              betPL.drawLoss = 0;
              betPL.drawLossStake = 0;
            }
            if (bet.betType === 'LO') {
              betPL.homeProfit = 0;
              betPL.homeProfitStake = 0;
              betPL.homeLoss = 0;
              betPL.homeLossStake = 0;
              betPL.awayProfit = 0;
              betPL.awayProfitStake = 0;
              betPL.awayLoss = Number(bet.returns) - Number(bet.userStake);
              betPL.awayLossStake = Number(bet.userStake);
              betPL.drawProfit = 0;
              betPL.drawProfitStake = 0;
              betPL.drawLoss = 0;
              betPL.drawLossStake = 0;
            }
          } else if (bet.selectionName.toLowerCase().includes('draw')) {
            if (bet.betType === 'BO') {
              betPL.homeProfit = 0;
              betPL.homeProfitStake = 0;
              betPL.homeLoss = 0;
              betPL.homeLossStake = 0;
              betPL.awayProfit = 0;
              betPL.awayProfitStake = 0;
              betPL.awayLoss = 0;
              betPL.awayLossStake = 0;
              betPL.drawProfit = bet.isFancy && bet.marketId !== 1 ? Number(bet.userStake) : Number(bet.returns) - Number(bet.userStake);
              betPL.drawProfitStake = Number(bet.userStake);
              betPL.drawLoss = 0;
              betPL.drawLossStake = 0;
            }
            if (bet.betType === 'LO') {
              betPL.homeProfit = 0;
              betPL.homeProfitStake = 0;
              betPL.homeLoss = 0;
              betPL.homeLossStake = 0;
              betPL.awayProfit = 0;
              betPL.awayProfitStake = 0;
              betPL.awayLoss = 0;
              betPL.awayLossStake = 0;
              betPL.drawProfit = 0;
              betPL.drawProfitStake = 0;
              betPL.drawLoss = bet.isFancy && bet.marketId !== 1 ? Number(bet.userStake) : Number(bet.returns) - Number(bet.userStake);
              betPL.drawLossStake = Number(bet.userStake);
            }
          }
          return betPL;
        }
      } else {

        if (betPL.eventId === bet.eventId &&
          betPL.marketId === bet.marketId &&
          betPL.providerId === bet.providerId) {
          isFound = true;
          if (bet.homeTeam === bet.selectionName) {
            if (bet.betType === 'BO') {
              betPL.homeProfit = Number(bet.returns) - Number(bet.userStake);
              betPL.homeProfitStake = Number(bet.userStake);
              betPL.homeLoss = 0;
              betPL.homeLossStake = 0;
              betPL.awayProfit = 0;
              betPL.awayProfitStake = 0;
              betPL.awayLoss = 0;
              betPL.awayLossStake = 0;
              betPL.drawProfit = 0;
              betPL.drawProfitStake = 0;
              betPL.drawLoss = 0;
              betPL.drawLossStake = 0;
            }
            if (bet.betType === 'LO') {
              betPL.homeProfit = 0;
              betPL.homeProfitStake = 0;
              betPL.homeLoss = Number(bet.returns) - Number(bet.userStake);
              betPL.homeLossStake = Number(bet.userStake);
              betPL.awayProfit = 0;
              betPL.awayProfitStake = 0;
              betPL.awayLoss = 0;
              betPL.awayLossStake = 0;
              betPL.drawProfit = 0;
              betPL.drawProfitStake = 0;
              betPL.drawLoss = 0;
              betPL.drawLossStake = 0;
            }
          } else if (bet.awayTeam === bet.selectionName) {
            if (bet.betType === 'BO') {
              betPL.homeProfit = 0;
              betPL.homeProfitStake = 0;
              betPL.homeLoss = 0;
              betPL.homeLossStake = 0;
              betPL.awayProfit = Number(bet.returns) - Number(bet.userStake);
              betPL.awayProfitStake = Number(bet.userStake);
              betPL.awayLoss = 0;
              betPL.awayLossStake = 0;
              betPL.drawProfit = 0;
              betPL.drawProfitStake = 0;
              betPL.drawLoss = 0;
              betPL.drawLossStake = 0;
            }
            if (bet.betType === 'LO') {
              betPL.homeProfit = 0;
              betPL.homeProfitStake = 0;
              betPL.homeLoss = 0;
              betPL.homeLossStake = 0;
              betPL.awayProfit = 0;
              betPL.awayProfitStake = 0;
              betPL.awayLoss = Number(bet.returns) - Number(bet.userStake);
              betPL.awayLossStake = Number(bet.userStake);
              betPL.drawProfit = 0;
              betPL.drawProfitStake = 0;
              betPL.drawLoss = 0;
              betPL.drawLossStake = 0;
            }
          } else if (bet.selectionName.toLowerCase().includes('draw')) {
            if (bet.betType === 'BO') {
              betPL.homeProfit = 0;
              betPL.homeProfitStake = 0;
              betPL.homeLoss = 0;
              betPL.homeLossStake = 0;
              betPL.awayProfit = 0;
              betPL.awayProfitStake = 0;
              betPL.awayLoss = 0;
              betPL.awayLossStake = 0;
              betPL.drawProfit = Number(bet.returns) - Number(bet.userStake);
              betPL.drawProfitStake = Number(bet.userStake);
              betPL.drawLoss = 0;
              betPL.drawLossStake = 0;
            }
            if (bet.betType === 'LO') {
              betPL.homeProfit = 0;
              betPL.homeProfitStake = 0;
              betPL.homeLoss = 0;
              betPL.homeLossStake = 0;
              betPL.awayProfit = 0;
              betPL.awayProfitStake = 0;
              betPL.awayLoss = 0;
              betPL.awayLossStake = 0;
              betPL.drawProfit = 0;
              betPL.drawProfitStake = 0;
              betPL.drawLoss = Number(bet.returns) - Number(bet.userStake);
              betPL.drawLossStake = Number(bet.userStake);
            }
          }
          return betPL;
        }
      }

    }
    if (!isFound) {
      if (bet.marketId === 24 || bet.marketId === 2 || bet.marketId === 3 || bet.isFancy) {

        return {
          eventId: bet.eventId,
          marketId: bet.marketId,
          odds: bet.userOdds,
          providerId: bet.providerId,
          isFancy: bet.isFancy,
          homeTeamProfitLoss: null,
          awayTeamProfitLoss: null,
          drawTeamProfitLoss: null,
          subMarket: bet.subMarket ? bet.subMarket : null,
          homeProfit: (bet.selectionName.toLowerCase().includes('under') || bet.selectionName.toLowerCase().includes('yes') || bet.homeTeam === bet.selectionName) && bet.betType === 'BO' ? bet.isFancy && bet.marketId !== 1 ? Number(bet.userStake) : Number(bet.returns) - Number(bet.userStake) : 0,
          homeProfitStake: (bet.selectionName.toLowerCase().includes('under') || bet.selectionName.toLowerCase().includes('yes') || bet.homeTeam === bet.selectionName) && bet.betType === 'BO' ? Number(bet.userStake) : 0,
          drawProfit: bet.selectionName.toLowerCase().includes('draw') && bet.betType === 'BO' ? Number(bet.returns) - Number(bet.userStake) : 0,
          drawProfitStake: bet.selectionName.toLowerCase().includes('draw') && bet.betType === 'BO' ? Number(bet.userStake) : 0,
          awayProfit: (bet.selectionName.toLowerCase().includes('over') || bet.selectionName.toLowerCase().includes('no') || bet.awayTeam === bet.selectionName) && bet.betType === 'BO' ? Number(bet.returns) - Number(bet.userStake) : 0,
          awayProfitStake: (bet.selectionName.toLowerCase().includes('over') || bet.selectionName.toLowerCase().includes('no') || bet.awayTeam === bet.selectionName) && bet.betType === 'BO' ? Number(bet.userStake) : 0,
          homeLoss: (bet.selectionName.toLowerCase().includes('under') || bet.selectionName.toLowerCase().includes('yes') || bet.homeTeam === bet.selectionName) && bet.betType === 'LO' ? Number(bet.returns) - Number(bet.userStake) : 0,
          homeLossStake: (bet.selectionName.toLowerCase().includes('under') || bet.selectionName.toLowerCase().includes('yes') || bet.homeTeam === bet.selectionName) && bet.betType === 'LO' ? Number(bet.userStake) : 0,
          drawLoss: bet.selectionName.toLowerCase().includes('draw') && bet.betType === 'LO' ? Number(bet.returns) - Number(bet.userStake) : 0,
          drawLossStake: bet.selectionName.toLowerCase().includes('draw') && bet.betType === 'LO' ? Number(bet.userStake) : 0,
          awayLoss: (bet.selectionName.toLowerCase().includes('over') || bet.selectionName.toLowerCase().includes('no') || bet.awayTeam === bet.selectionName) && bet.betType === 'LO' ? Number(bet.returns) - Number(bet.userStake) : 0,
          awayLossStake: (bet.selectionName.toLowerCase().includes('over') || bet.selectionName.toLowerCase().includes('no') || bet.awayTeam === bet.selectionName) && bet.betType === 'LO' ? Number(bet.userStake) : 0,
        }
      } else {
        return {
          eventId: bet.eventId,
          marketId: bet.marketId,
          providerId: bet.providerId,
          odds: bet.userOdds,
          isFancy: bet.isFancy,
          homeTeamProfitLoss: null,
          awayTeamProfitLoss: null,
          drawTeamProfitLoss: null,
          subMarket: bet.subMarket ? bet.subMarket : null,
          homeProfit: bet.homeTeam === bet.selectionName && bet.betType === 'BO' ? Number(bet.returns) - Number(bet.userStake) : 0,
          homeProfitStake: bet.homeTeam === bet.selectionName && bet.betType === 'BO' ? Number(bet.userStake) : 0,
          drawProfit: bet.selectionName.toLowerCase().includes('draw') && bet.betType === 'BO' ? Number(bet.returns) - Number(bet.userStake) : 0,
          drawProfitStake: bet.selectionName.toLowerCase().includes('draw') && bet.betType === 'BO' ? Number(bet.userStake) : 0,
          awayProfit: bet.awayTeam === bet.selectionName && bet.betType === 'BO' ? Number(bet.returns) - Number(bet.userStake) : 0,
          awayProfitStake: bet.awayTeam === bet.selectionName && bet.betType === 'BO' ? Number(bet.userStake) : 0,
          homeLoss: bet.homeTeam === bet.selectionName && bet.betType === 'LO' ? Number(bet.returns) - Number(bet.userStake) : 0,
          homeLossStake: bet.homeTeam === bet.selectionName && bet.betType === 'LO' ? Number(bet.userStake) : 0,
          drawLoss: bet.selectionName.toLowerCase().includes('draw') && bet.betType === 'LO' ? Number(bet.returns) - Number(bet.userStake) : 0,
          drawLossStake: bet.selectionName.toLowerCase().includes('draw') && bet.betType === 'LO' ? Number(bet.userStake) : 0,
          awayLoss: bet.awayTeam === bet.selectionName && bet.betType === 'LO' ? Number(bet.returns) - Number(bet.userStake) : 0,
          awayLossStake: bet.awayTeam === bet.selectionName && bet.betType === 'LO' ? Number(bet.userStake) : 0,
        }
      }

    }
  }
  /**
  *
  */
  calculatePL() {
    for (var i = 0; i < this.betsProfitLoss.length; i++) {
      if (!this.betsProfitLoss[i]['isFancy']) {
        if (this.betsProfitLoss[i]['marketId'] === 1 && (this.betsProfitLoss[i]['awayProfitStake'] > 0 || this.betsProfitLoss[i]['homeProfitStake'] > 0 ||
          this.betsProfitLoss[i]['awayLossStake'] > 0 || this.betsProfitLoss[i]['homeLossStake'] > 0 ||
          this.betsProfitLoss[i]['drawProfitStake'] > 0 || this.betsProfitLoss[i]['drawLossStake'] > 0)) {
          //    this.betsProfitLoss[i]['homeProfitLoss'] = Number(this.betsProfitLoss[i]['homeTeamProfitLoss']) + ((this.betsProfitLoss[i]['homeProfit']+this.betsProfitLoss[i]['awayLossStake']) - 
          //                                             (this.betsProfitLoss[i]['awayProfitStake']+this.betsProfitLoss[i]['homeLoss']));
          // this.betsProfitLoss[i]['awayProfitLoss'] = this.betsProfitLoss[i]['awayTeamProfitLoss'] + ((this.betsProfitLoss[i]['awayProfit']+this.betsProfitLoss[i]['homeLossStake']) - 
          //                                             (this.betsProfitLoss[i]['homeProfitStake']+this.betsProfitLoss[i]['awayLoss']))
          this.betsProfitLoss[i]['homeProfitLoss'] = Number(this.betsProfitLoss[i]['homeTeamProfitLoss']) + ((this.betsProfitLoss[i]['homeProfit'] + this.betsProfitLoss[i]['awayLossStake'] + this.betsProfitLoss[i]['drawLossStake']) -
            (this.betsProfitLoss[i]['awayProfitStake'] + this.betsProfitLoss[i]['homeLoss'] + this.betsProfitLoss[i]['drawProfitStake']));
          this.betsProfitLoss[i]['awayProfitLoss'] = this.betsProfitLoss[i]['awayTeamProfitLoss'] + ((this.betsProfitLoss[i]['awayProfit'] + this.betsProfitLoss[i]['homeLossStake'] + this.betsProfitLoss[i]['drawLossStake']) -
            (this.betsProfitLoss[i]['homeProfitStake'] + this.betsProfitLoss[i]['awayLoss'] + this.betsProfitLoss[i]['drawProfitStake']))
          this.betsProfitLoss[i]['drawProfitLoss'] = this.betsProfitLoss[i]['drawTeamProfitLoss'] + ((this.betsProfitLoss[i]['drawProfit'] + this.betsProfitLoss[i]['awayLossStake'] + this.betsProfitLoss[i]['homeLossStake']) -
            (this.betsProfitLoss[i]['drawLoss'] + this.betsProfitLoss[i]['homeProfitStake'] + this.betsProfitLoss[i]['awayProfitStake']))
        } else if ((this.betsProfitLoss[i]['marketId'] === 2 || this.betsProfitLoss[i]['marketId'] === 3 || this.betsProfitLoss[i]['marketId'] === 24) && (this.betsProfitLoss[i]['awayProfitStake'] > 0 || this.betsProfitLoss[i]['homeProfitStake'] > 0 ||
          this.betsProfitLoss[i]['awayLossStake'] > 0 || this.betsProfitLoss[i]['homeLossStake'] > 0)) {
          //    this.betsProfitLoss[i]['homeProfitLoss'] = Number(this.betsProfitLoss[i]['homeTeamProfitLoss']) + ((this.betsProfitLoss[i]['homeProfit']+this.betsProfitLoss[i]['awayLossStake']) - 
          //                                             (this.betsProfitLoss[i]['awayProfitStake']+this.betsProfitLoss[i]['homeLoss']));
          // this.betsProfitLoss[i]['awayProfitLoss'] = this.betsProfitLoss[i]['awayTeamProfitLoss'] + ((this.betsProfitLoss[i]['awayProfit']+this.betsProfitLoss[i]['homeLossStake']) - 
          //                                             (this.betsProfitLoss[i]['homeProfitStake']+this.betsProfitLoss[i]['awayLoss']))
          this.betsProfitLoss[i]['homeProfitLoss'] = Number(this.betsProfitLoss[i]['homeTeamProfitLoss']) + ((this.betsProfitLoss[i]['homeProfit'] + this.betsProfitLoss[i]['awayLossStake'] + this.betsProfitLoss[i]['drawLossStake']) -
            (this.betsProfitLoss[i]['awayProfitStake'] + this.betsProfitLoss[i]['homeLoss'] + this.betsProfitLoss[i]['drawProfitStake']));
          this.betsProfitLoss[i]['awayProfitLoss'] = this.betsProfitLoss[i]['awayTeamProfitLoss'] + ((this.betsProfitLoss[i]['awayProfit'] + this.betsProfitLoss[i]['homeLossStake'] + this.betsProfitLoss[i]['drawLossStake']) -
            (this.betsProfitLoss[i]['homeProfitStake'] + this.betsProfitLoss[i]['awayLoss'] + this.betsProfitLoss[i]['drawProfitStake']))
        }
      } else {

        if (this.betsProfitLoss[i]['marketId'] === 1) {
          if (this.betsProfitLoss[i]['awayProfitStake'] > 0 || this.betsProfitLoss[i]['homeProfitStake'] > 0 ||
            this.betsProfitLoss[i]['awayLossStake'] > 0 || this.betsProfitLoss[i]['homeLossStake'] > 0 ||
            this.betsProfitLoss[i]['drawProfitStake'] > 0 || this.betsProfitLoss[i]['drawLossStake'] > 0) {
            //    this.betsProfitLoss[i]['homeProfitLoss'] = Number(this.betsProfitLoss[i]['homeTeamProfitLoss']) + ((this.betsProfitLoss[i]['homeProfit']+this.betsProfitLoss[i]['awayLossStake']) - 
            //                                             (this.betsProfitLoss[i]['awayProfitStake']+this.betsProfitLoss[i]['homeLoss']));
            // this.betsProfitLoss[i]['awayProfitLoss'] = this.betsProfitLoss[i]['awayTeamProfitLoss'] + ((this.betsProfitLoss[i]['awayProfit']+this.betsProfitLoss[i]['homeLossStake']) - 
            //                                             (this.betsProfitLoss[i]['homeProfitStake']+this.betsProfitLoss[i]['awayLoss']))
            this.betsProfitLoss[i]['homeProfitLoss'] = Number(this.betsProfitLoss[i]['homeTeamProfitLoss']) + ((this.betsProfitLoss[i]['homeProfit'] + this.betsProfitLoss[i]['awayLossStake'] + this.betsProfitLoss[i]['drawLossStake']) -
              (this.betsProfitLoss[i]['awayProfitStake'] + this.betsProfitLoss[i]['homeLoss'] + this.betsProfitLoss[i]['drawProfitStake']));
            this.betsProfitLoss[i]['awayProfitLoss'] = this.betsProfitLoss[i]['awayTeamProfitLoss'] + ((this.betsProfitLoss[i]['awayProfit'] + this.betsProfitLoss[i]['homeLossStake'] + this.betsProfitLoss[i]['drawLossStake']) -
              (this.betsProfitLoss[i]['homeProfitStake'] + this.betsProfitLoss[i]['awayLoss'] + this.betsProfitLoss[i]['drawProfitStake']))
            this.betsProfitLoss[i]['drawProfitLoss'] = this.betsProfitLoss[i]['drawTeamProfitLoss'] + ((this.betsProfitLoss[i]['drawProfit'] + this.betsProfitLoss[i]['awayLossStake'] + this.betsProfitLoss[i]['homeLossStake']) -
              (this.betsProfitLoss[i]['drawLoss'] + this.betsProfitLoss[i]['homeProfitStake'] + this.betsProfitLoss[i]['awayProfitStake']))
          }
        } else if (this.betsProfitLoss[i]['homeProfitStake'] > 0 ||
          this.betsProfitLoss[i]['awayLoss'] > 0) {
          this.betsProfitLoss[i]['homeProfitLoss'] = this.betsProfitLoss[i]['homeTeamProfitLoss'] + (this.betsProfitLoss[i]['homeProfitStake'] + this.betsProfitLoss[i]['awayLoss']);
          this.betsProfitLoss[i]['awayProfitLoss'] = ''
        }
      }
    }
    this.pLStatusChanged = !this.pLStatusChanged;
  }

  removePL(bet) {
    //removedBet = betSlipBets.splice(betIndex,1);
    var foundIndex = -1;
    var index = 0;
    var betsProfitLoss = []
    for (var betPL of this.betsProfitLoss) {
      if (betPL.eventId === bet.eventId &&
        betPL.marketId === bet.marketId &&
        betPL.providerId === bet.providerId &&
        betPL.subMarket === bet.subMarket) {
        betPL.homeProfitLoss = null;
        betPL.awayProfitLoss = null;
        betPL.drawProfitLoss = null;

        betPL.awayLoss = null;
        betPL.awayLossStake = null;
        betPL.awayProfit = null;
        betPL.awayProfitStake = null;

        betPL.drawLoss = null;
        betPL.drawLossStake = null;
        betPL.drawProfit = null;
        betPL.drawProfitStake = null;

        betPL.homeLoss = null;
        betPL.homeLossStake = null;
        betPL.homeProfit = null;
        betPL.homeProfitStake = null;

        betsProfitLoss.push(betPL)
      } else {
        betsProfitLoss.push(betPL)
      }
    }
    this.betsProfitLoss = [...betsProfitLoss];
    this.pLStatusChanged = !this.pLStatusChanged;
  }
  searchQuery(e, settingsStore) {
    var keyCode = e.which || e.keyCode;
    if (keyCode === 13) {
      this.login(settingsStore);
    };

  }
  /**
  * This method is called when user clicks login button.
    * this method validate fileds
  * first it validates the authentication number
  * and it calls main login method in the app store
  */
  login(settingsStore) {
    var data = {
      emailId: this.email,
      password: this.password
    };
    this.validateLoginFields(data, (isValid) => {
      if (isValid) {
        if (Number(this.authNumber) === Number(this.reAuthNumber)) {
          this.mainLogin(data, (isSuccess, des) => {
            if (isSuccess) {
              this.email = "";
              this.password = "";
              this.errorMsg = "";
              settingsStore.getSettingsValue();
            } else {
              this.errorMsg = des;
              this.authNumber = getAuthenticationNumber();
            }
            this.reAuthNumber = '';
          });
        } else {
          this.errorMsg = 'Invalid authentication number.';
          this.authNumber = getAuthenticationNumber();
          this.reAuthNumber = '';
        }
      } else {
        this.errorMsg = 'Invalid email id/username or password.';
        this.authNumber = getAuthenticationNumber();
      }
      this.reAuthNumber = '';
    });
  }
  /**
  * This method is used to get the left menu data.
  *
  */
  getLeftMenuData() {
    apiCaller(masterPage.getLeftMenu, 'GET').then(response => {
      if (response?.data?.categories?.length > 0) {
        this.leftMenuData = response.data.categories;
        for (var cat of this.leftMenuData) {
          for (var sport of cat.Sport) {
            if (this.sportId === "" || this.sportId === null) {
              this.sportId = sport.id;
              this.sportName = sport.name;
            }
            break;
          }
          if (this.sportId !== "" && this.sportName !== "") {
            break
          }
        }
        // this.sportId = this.leftMenuData[0].Sport.length>0?this.leftMenuData[0].Sport[0].id;
        // this.sportName = this.leftMenuData[0].Sport.length>0?this.leftMenuData[0].Sport[0].name;
      }
    });
  }
  /**
  * This method is used to add a bet to betslip
  * @param {object} selection- contains the selection with event and market id to add to betslip
  * @param {string} betType- contains BO and LO which tells the type of odds,
  * BO - back odds
  * LO - lay odds
  */
  addToBetSlip(selection, betType, isSelected) {
    if (cookie.load('showOneClick') && cookie.load("showOneClick") === "true") {
      this.betSlipStore.placeOneClickBet(selection);
      return false;
    }
    var betSlipItems = JSON.parse(localStorage.getItem("betslip"));
    var isLocalAdded = false;
    if (!betSlipItems) {
      betSlipItems = {
        backBets: [],
        layBets: []
      }
    }
    var cookieSelection = {
      eventId: selection.eventId,
      marketId: selection.marketId,
      selectionId: selection.selectionId,
      isFancy: selection.isFancy,
      odds: selection.odds,
      betType: selection.betType
    }
    if (selection.betType === "BO") {
      var BOBets = betSlipItems.backBets;
      var betIndex = this.findBetIndex(BOBets, selection);
      if (betIndex >= 0) {
        if (BOBets[betIndex].odds !== selection.odds) {
          BOBets[betIndex] = selection;
          isLocalAdded = true;
        } else {
          BOBets.splice(betIndex, 1);
          isLocalAdded = false;
        }
      } else {
        BOBets = [];
        betSlipItems.layBets = [];
        BOBets.push(cookieSelection);
        isLocalAdded = true;
      }
      betSlipItems.backBets = BOBets;
    } else {
      var LOBets = betSlipItems.layBets;
      var betIndex = this.findBetIndex(LOBets, selection);
      if (betIndex >= 0) {
        if (LOBets[betIndex].odds !== selection.odds) {
          LOBets[betIndex] = selection;
          isLocalAdded = true;
        } else {
          LOBets.splice(betIndex, 1);
          isLocalAdded = false;
        }
      } else {
        LOBets = [];
        betSlipItems.backBets = [];
        LOBets.push(cookieSelection);
        isLocalAdded = true;
      }
      betSlipItems.layBets = LOBets;
    }
    localStorage.setItem("betslip", JSON.stringify(betSlipItems));
    var addedBetSlip = this.betSlipStore.addBetSlipItem(selection);
    this.betSlipStatusChange = !this.betSlipStatusChange;
    return addedBetSlip
  }
  /**
  * This method used to find the index of the bet to remove.
  * @param {array} bets - contains the betslip bets,
  *
  */
  findBetIndex(bets, selection) {
    var betIndex = -1;
    for (var i = 0; i < bets.length; i++) {
      if (bets[i]['eventId'] === selection.eventId &&
        bets[i]['marketId'] === selection.marketId &&
        bets[i]['selectionId'] === selection.selectionId) {
        betIndex = i
      }
    }
    return betIndex;
  }
  /**
  * This method is called when search value is changed.
  */
  // searchChange(e){
  //   console.log("search change")
  // }
  /**
  * This method is used to save the event pin or unpin.
  * @param {eventId} - number- contains the eventId to be pinned
  */
  saveEventPin(eventId, marketId, subMarket, providerId, isPinned, callback) {
    if (this.isLoggedIn) {
      if (eventId && eventId > 0) {
        var param = {
          "userToken": cookie.load('UTocken'),
          "eventId": eventId,
          "marketGroupId": marketId,
          "subMarket": subMarket,
          "providerId": providerId
        }
        var serviceLink = !isPinned ? masterPage.savePin : masterPage.removePin
        apiCaller(serviceLink, 'POST', param).then(response => {
          if (response.data && (response.data.hasOwnProperty('pinDetailsSaved') || response.data.hasOwnProperty('pinDetailsDeleted'))) {
            var serviceSuccess = isPinned ? response.data.pinDetailsDeleted : response.data.pinDetailsSaved
            callback(serviceSuccess);
          } else {
            callback(false);
          }
        });
      }
    } else {
      this.showLogin();
    }

  }

  reLoadStakeCoins() {
    this.betSlipStore.loadStakeCoins();
  }
}

export default AppStore