import React, { Component } from 'react';
import Autocomplete from 'react-autocomplete';
import { observer } from 'mobx-react';
import { Route, NavLink, Switch } from "react-router-dom";

import { searchTimeFormat } from "./helper"

const searchRe = /^[A-Za-z0-9 ]+$/;
class AutoCompleteSearch extends Component {
  constructor(props) {
    super(props)
    this.handleInputChange=this.handleInputChange.bind(this);
    this.handleInputSelect=this.handleInputSelect.bind(this);
    this.state = {
      value: '',
      loading: false,
    }
    this.requestTimer = null

  }
  handleInputChange(event){
  // debugger;
    if(event.target.value==='' || searchRe.test(event.target.value)){
      this.setState({ loading:true })
      const searchString=event.target.value;
      this.setState({ value:searchString });
      this.props.appStore.searchSuggestions=[];
      if(searchString.length>0){
       this.props.appStore.getSearchData(searchString);
      }
      this.setState({ loading:false });
    }
 }
 handleInputSelect(value,item){
  // debugger;
 // console.log("Selected Item",item);
  this.setState({ loading:true })
  this.setState({ value:'' })
  // this.props.appStore.getSearcResults(value);
  this.setState({ loading:false });
  this.props.appStore.eventViewId = item.id;
  this.props.appStore.sportId = item.sportId;
  this.props.appStore.sportName = item.sportName;
  this.props.history.push('/sports/'+item.sportName+'/'+item.sportId+'/event/'+item.id);
 }
   
    render() {
      const{ appStore } =this.props;
      //console.log("search results",searchStore.searchSuggestions);
      return (
        <Autocomplete
          value={this.state.value}
          inputProps={{ className:"RDE-formControl", placeholder:"Search Events" }}
          items={appStore.searchSuggestions}
          getItemValue={(item) => item.name}
          onSelect={this.handleInputSelect}
        // onSelect={(value, state) => this.setState({ value, unitedStates: [state] }) }
          onChange={this.handleInputChange}
          renderItem={
            
            (item, isHighlighted) => ( 
              <li className={`RDE-searchItem ${isHighlighted ? 'RDE-searchItem-highlighted' : ''}`}
                key={"searchItem_"+item.id}
              >{searchTimeFormat(item.kickofftime)} {item.name}</li>
          )}
          renderMenu={(items, value) => (
            value !== '' ? (<div className="RDE-searchBoxMenu">
              <ul>
                {
                  this.state.loading ? (
                    <li className="RDE-searchItem">Loading...</li>
                  ) : items.length === 0 ? (
                    <li className="RDE-searchItem">No matches for "{value}"</li>
                  ) :
                      items
                }
              </ul>

            </div>
            ) : <span></span>
          )}
          isItemSelectable={(item) => !item.header}
        />
        
      )
    }
  }
  export default observer(AutoCompleteSearch);