import { extendObservable } from "mobx";
import cookie from 'react-cookies';

import { masterPage } from "../../sharedfiles/EndpointConfig";
import { apiCaller } from '../../sharedfiles/apiStore';
class SubHeaderStore {
  constructor(props) {
    extendObservable(this, {
      data: [],
      showOneClick:false,
      appStore:{},
      showSettings:false,
      settingsStore:{}
    });
  }
  /**
  * This method is used to get the sub header data
  *
  */
  getSubHeader(){
   	apiCaller(masterPage.getSubHeader,'GET').then(response => {
  	  if(response && response.data && response.data.hasOwnProperty('subHeadersList') &&
  	     response.data.subHeadersList && response.data.subHeadersList.length>0){
  	      this.data = response.data.subHeadersList;
  	  }
    });
  }
  /**
  * This method is used to toggle the one click popup.
  *
  */
  toggleOneClick(){
    //console.log("toggleOneClick")
    this.showOneClick = !this.showOneClick;
    cookie.save("showOneClick",this.showOneClick);
  }
  /**
  * this method is called when a item is selected in sub menu.
  * @param {menuId} - number this variable contains submenu Id.
  */
  subMenuClick(menuId){
   // console.log(this.appStore.isLoggedIn,"===",menuId)
    if(this.appStore.isLoggedIn){
      if(menuId === 1){
        this.toggleOneClick();
      } 
    }else{
      this.appStore.showLogin();
    }
  }
  /**
  * This method is show and hide the settings.
  *
  */
  toggleSettings(){
    //console.log("toggleSettings===")
    this.showSettings = !this.showSettings;
    this.settingsStore.getSettingsValue(this.settingsStore);
    this.settingsStore.isEdit = false;
    this.settingsStore.validCoinsErr = "";
    this.settingsStore.coinCountErr = "";
  }
}

export default SubHeaderStore;
