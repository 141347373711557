import { extendObservable } from "mobx";

class SportsPageComponentStore {
  constructor(props) {
    extendObservable(this, {
      selectedTab: "highlights",
      sportName: "",
      sportId: ""
    });
  }
  /**
  * this method is called when user selects the tab
  * @param {string} tabName- contains the tab name to select.
  */
  tabSelect(tabName) {
    this.selectedTab = tabName;
  }
}

export default SportsPageComponentStore;
