import React, { Component } from "react";
import { observer } from "mobx-react";
import { extendObservable } from "mobx";
import sortJsonArray from "sort-json-array";

import EventViewOddsComponent from "../eventviewoddscomponent/EventviewOddsComponent";
import EventViewOddsComponentStore from "../eventviewoddscomponent/EventViewOddsComponentStore";
import { masterPage } from "../../sharedfiles/EndpointConfig";
import { apiCaller } from "../../sharedfiles/apiStore";

const eventViewOddsComponentStore = new EventViewOddsComponentStore();
class OutcomeComponent extends Component {
  constructor(props) {
    super(props);
    extendObservable(this, {
      isMounted: true,
      showOdds: false,
      marketSuspended: 0,
    });
  }
  componentWillMount() {
    // this.props.eventComponentStore.appStore = this.props.appStore;
    this.marketSuspended = this.props.marketSuspended;
  }
  componentWillReceiveProps(nextProps) {
    // if(this.props.event.isBlocked !== nextProps.event.isBlocked){

    // }
    if (this.marketSuspended !== nextProps.marketSuspended) {
      this.marketSuspended = nextProps.marketSuspended;
    }
  }
  componentDidMount() {
    if (
      this.props.component === "matchodds" ||
      this.props.component === "multiview"
    ) {
      var self = this;
      setTimeout(function reCallService() {
        if (self.isMounted) {
          self.getOdds();
          setTimeout(reCallService, 1000);
        }
      }, 1000);
    }
  }
  componentWillUnmount() {
    this.isMounted = false;
  }
  GetSortOrder(prop) {
    return function (a, b) {
      if (a[prop] < b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    };
  }
  getOdds() {
    //+this.props.clMarketId
    const self = this;
    if (this.props.clMarketId) {
      apiCaller(
        masterPage.getUpdatedOdds + "?marketId=" + this.props.clMarketId,
        "GET"
      ).then((response) => {
        if (response && response.data) {
          this.showOdds = true;
          var market = response.data;
          this.props.appStore.betSlipStore.updateBetSlipOdds(response.data);
          if (market.marketId === self.props.clMarketId) {
            if (market.status.toLowerCase() === "suspended") {
              self.marketSuspended = 1;
            } else {
              self.marketSuspended = 0;
            }
            for (var outcome of market.runners) {
              for (var i = 0; i < self.props.outcomes.length; i++) {
                if (
                  outcome.selectionId ===
                  Number(self.props.outcomes[i]["clOutcomeId"])
                ) {
                  var backOddsIndex = 0;
                  var backOddsValues = [];
                  for (var backSelection of outcome.ex.availableToBack) {
                    if (backOddsIndex < 3) {
                      var oddsObj = {
                        odds: "",
                        size: "",
                        isSelected: false,
                      };

                      // if(self.props.outcomes[i]['backOdds'][backOddsIndex]['odds'] === backSelection.price){
                      //   debugger;
                      //   oddsObj.isSelected = self.props.outcomes[i]['backOdds'][backOddsIndex]['odds']['isSelected']
                      // }
                      // self.props.outcomes[i]['backOdds'][backOddsIndex]['odds'] = backSelection.price;
                      // self.props.outcomes[i]['backOdds'][backOddsIndex]['size'] =  Math.round(backSelection.size);
                      oddsObj.odds = backSelection.price;
                      oddsObj.size = Math.round(backSelection.size);
                      backOddsValues.push(oddsObj);
                    }
                    backOddsIndex++;
                  }
                  if (outcome.ex.availableToBack.length === 0) {
                    backOddsValues = [{}, {}, {}];
                  }
                  backOddsValues = sortJsonArray(backOddsValues, "odds", "asc");
                  for (var j = 0; j < backOddsValues.length; j++) {
                    if (self.props.outcomes[i]["backOdds"][j]) {
                      if (
                        self.props.outcomes[i]["backOdds"][j]["odds"] ===
                        backOddsValues[j]["price"]
                      ) {
                        backOddsValues[j]["isSelected"] =
                          self.props.outcomes[i]["backOdds"][j]["isSelected"];
                      }
                      self.props.outcomes[i]["backOdds"][j]["odds"] =
                        backOddsValues[j]["odds"];
                      self.props.outcomes[i]["backOdds"][j]["size"] =
                        backOddsValues[j]["size"];
                    }
                  }

                  // self.props.outcomes[i]['backOdds']=sortJsonArray( self.props.outcomes[i]['backOdds'],'odds','asc')
                  //self.props.outcomes[i]['backOdds']=sortJsonArray( backOddsValues,'odds','asc')
                  var layOddsIndex = 0;
                  for (var laySelection of outcome.ex.availableToLay) {
                    if (
                      layOddsIndex < 3 &&
                      self.props.outcomes[i]["layOdds"][layOddsIndex]
                    ) {
                      self.props.outcomes[i]["layOdds"][layOddsIndex]["odds"] =
                        laySelection.price;
                      self.props.outcomes[i]["layOdds"][layOddsIndex][
                        "size"
                      ] = Math.round(laySelection.size);
                    }
                    layOddsIndex++;
                  }
                  if (outcome.ex.availableToLay.length === 0) {
                    self.props.outcomes[i]["layOdds"] = [{}, {}, {}];
                  }
                }
              }
            }
          }
        }
      });
    }
  }
  render() {
    const {
      outcomes,
      eventSuspended,
      eventBlocked,
      marketBlocked,
      appStore,
      eventName,
      eventId,
      marketName,
      marketId,
      clMarketId,
      providerId,
      minStake,
      maxStake,
      component,
      homeTeam,
      awayTeam,
      homeTeamId,
      awayTeamId,
      isLive,
      profitLoss,
      kickOffTime,
      showMinMax
    } = this.props;
    return (
      <div className="RDE-OutcomeItemGroup">
        {outcomes.map((outcome, index) => {
          return (
            <div
              className="RDE-singleLineOutcomeItem"
              key={"eventView_outcome_" + index}
            >
              <div className="RDE-singleLineOutcomeItem__OutcomeContainer">
                <div className="RDE-singleLineOutcomeItem__outcomeStats">
                  <i className="RDE-icon-stats" />
                </div>
                <div className="RDE-singleLineOutcomeItem__outComeDetails">
                  <div className="RDE-singleLineOutcomeItem__outComeName">
                    {outcome.name}
                  </div>
                  {homeTeam === outcome.name ? (
                    <div className="RDE-singleLineOutcomeItem__result">
                      {typeof profitLoss.homeTeamProfitLoss === "number" ? (
                        <div
                          className={
                            profitLoss.homeTeamProfitLoss <= 0
                              ? "loss"
                              : "profit"
                          }
                        >
                          {profitLoss &&
                            profitLoss.homeTeamProfitLoss &&
                            Math.abs(profitLoss.homeTeamProfitLoss).toFixed(
                              2
                            )}{" "}
                        </div>
                      ) : (
                        ""
                      )}
                      {typeof profitLoss.homeTeamProfitLoss === "number" &&
                        typeof profitLoss.homeProfitLoss === "number" ? (
                        <i className="RDE-icon-right-arrow" />
                      ) : (
                        ""
                      )}
                      <div
                        className={
                          profitLoss.homeProfitLoss <= 0 ? "loss" : "profit"
                        }
                      >
                        {profitLoss &&
                          profitLoss.homeProfitLoss &&
                          Math.abs(profitLoss.homeProfitLoss).toFixed(2)}
                      </div>
                    </div>
                  ) : awayTeam === outcome.name ? (
                    <div className="RDE-singleLineOutcomeItem__result">
                      {typeof profitLoss.awayTeamProfitLoss === "number" ? (
                        <div
                          className={
                            profitLoss.awayTeamProfitLoss <= 0
                              ? "loss"
                              : "profit"
                          }
                        >
                          {profitLoss &&
                            typeof (
                              profitLoss.awayTeamProfitLoss === "number"
                            ) &&
                            Math.abs(profitLoss.awayTeamProfitLoss).toFixed(
                              2
                            )}{" "}
                        </div>
                      ) : (
                        ""
                      )}
                      {typeof profitLoss.awayTeamProfitLoss === "number" &&
                        typeof profitLoss.awayProfitLoss === "number" ? (
                        <i className="RDE-icon-right-arrow" />
                      ) : (
                        ""
                      )}
                      <div
                        className={
                          profitLoss.awayProfitLoss <= 0 ? "loss" : "profit"
                        }
                      >
                        {profitLoss &&
                          typeof profitLoss.awayProfitLoss === "number" &&
                          Math.abs(profitLoss.awayProfitLoss).toFixed(2)}
                      </div>
                    </div>
                  ) : outcome.name.toLowerCase().includes("draw") ? (
                    <div className="RDE-singleLineOutcomeItem__result">
                      {profitLoss.drawTeamProfitLoss ? (
                        <div
                          className={
                            profitLoss.drawTeamProfitLoss <= 0
                              ? "loss"
                              : "profit"
                          }
                        >
                          {profitLoss &&
                            typeof profitLoss.drawTeamProfitLoss === "number" &&
                            Math.abs(profitLoss.drawTeamProfitLoss).toFixed(
                              2
                            )}{" "}
                        </div>
                      ) : (
                        ""
                      )}
                      {typeof profitLoss.drawTeamProfitLoss === "number" &&
                        typeof profitLoss.drawProfitLoss === "number" ? (
                        <i className="RDE-icon-right-arrow" />
                      ) : (
                        ""
                      )}
                      <div
                        className={
                          profitLoss.drawProfitLoss <= 0 ? "loss" : "profit"
                        }
                      >
                        {profitLoss &&
                          typeof profitLoss.drawProfitLoss === "number" &&
                          Math.abs(profitLoss.drawProfitLoss).toFixed(2)}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="RDE-singleLineOutcomeItem__BackAllLayAll">
                <ul>
                  <li>
                    <div className="RDE-backOddsGroup">
                      {!eventSuspended && !this.marketSuspended ? (
                        outcome.backOdds.map((data, outcomeIndex) => {
                          return (
                            <EventViewOddsComponent
                              eventSuspended={eventSuspended}
                              eventBlocked={eventBlocked}
                              marketSuspended={this.marketSuspended}
                              marketBlocked={marketBlocked}
                              appStore={appStore}
                              outcome={data}
                              outcomes={outcome}
                              outcomeIndex={outcomeIndex}
                              key={"eventview_outcome_" + outcomeIndex}
                              eventViewOddsComponentStore={
                                eventViewOddsComponentStore
                              }
                              eventName={eventName}
                              eventId={eventId}
                              marketName={marketName}
                              marketId={marketId}
                              clMarketId={clMarketId}
                              betSlipStatusChange={appStore.betSlipStatusChange}
                              providerId={providerId}
                              isLive={isLive}
                              kickOffTime={kickOffTime}
                              minStake={minStake}
                              maxStake={maxStake}
                              homeTeam={homeTeam}
                              awayTeam={awayTeam}
                              homeTeamId={homeTeamId}
                              awayTeamId={awayTeamId}
                              showOdds={
                                component == "matchodds1" ? true : this.showOdds
                              }
                              oddsType="BO"
                            />
                          );
                        })
                      ) : (
                        <div className="RDE-btnOddsSuspended">
                          <button className="btn RDE-btnOdds">
                            <i className="RDE-icon-lock" /> Suspended
                          </button>
                        </div>
                      )}
                    </div>
                  </li>
                  <li>
                    <div className="RDE-layOddsGroup">
                      {!eventSuspended && !this.marketSuspended ? (
                        outcome.layOdds.map((data, outcomeIndex) => {
                          return (
                            <EventViewOddsComponent
                              eventSuspended={eventSuspended}
                              eventBlocked={eventBlocked}
                              marketSuspended={this.marketSuspended}
                              marketBlocked={marketBlocked}
                              appStore={appStore}
                              outcome={data}
                              outcomes={outcome}
                              outcomeIndex={outcomeIndex}
                              key={"eventview_outcome_" + outcomeIndex}
                              eventViewOddsComponentStore={
                                eventViewOddsComponentStore
                              }
                              eventName={eventName}
                              eventId={eventId}
                              marketName={marketName}
                              marketId={marketId}
                              clMarketId={clMarketId}
                              betSlipStatusChange={appStore.betSlipStatusChange}
                              providerId={providerId}
                              isLive={isLive}
                              kickOffTime={kickOffTime}
                              minStake={minStake}
                              maxStake={maxStake}
                              homeTeam={homeTeam}
                              awayTeam={awayTeam}
                              homeTeamId={homeTeamId}
                              awayTeamId={awayTeamId}
                              showOdds={
                                component == "matchodds1" ? true : this.showOdds
                              }
                              oddsType="LO"
                            />
                          );
                        })
                      ) : (
                        <div className="RDE-btnOddsSuspended">
                          <button className="btn RDE-btnOdds">
                            <i className="RDE-icon-lock" /> Suspended
                          </button>
                        </div>
                      )}
                    </div>
                  </li>
                  {(component === "matchodds1" && !showMinMax) ? (
                    <li>
                      <div className="RDE-minMax">
                        <div className="RDE-minMax__label">Min/Max</div>
                        <div className="RDE-minMax__value">
                          {minStake > 0 ? minStake : "-"} /{" "}
                          {maxStake > 0 ? maxStake : "-"}
                        </div>
                      </div>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}
export default observer(OutcomeComponent);
