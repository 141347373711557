import { extendObservable } from "mobx";
import { homePage } from "../../sharedfiles/EndpointConfig";
import { apiCaller } from '../../sharedfiles/apiStore';
import cookie from 'react-cookies';

class BannerStore {
  constructor(props) {
    extendObservable(this, {
      bannerData: [],
      appStore:{},
      pageId:"",
      loginBannerData:[]
    });
  }
  /**
  * This method is called from component will mount when component is loaded,
  * in this method we will get the banners data.
  */
  getBannerData(){
    var token="";
    var cookeTocken = cookie.load('UTocken');
    if(cookeTocken && cookeTocken.length>0){
      token = cookeTocken;
    }
    apiCaller(homePage.getBanners+"?pageId="+this.pageId+'&userToken='+token,'GET').then(response => {
          if( response?.data?.bannersList?.length>0){
              if(token.length>0){
                this.loginBannerData = response.data.bannersList;
              }else{
                this.bannerData = response.data.bannersList;
              }
          }
    });
  }
  /**
  * This method is called when user clicks button in the banner
  * @param buttonType- {number} - this variables contains button type ids
  * Buttontype =1 is registration and 2 for internal sports navigation
  */
  bannerButtonClick(buttonType){
      // code for sport navigation
  }
  /**
  * This method is called when user clicks banner image.
  *
  */
  clickBanner(){
    //console.log("banner click")
  }
}

export default BannerStore;
