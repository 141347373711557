import React, { Component } from 'react';
import { observer } from 'mobx-react';
import cookie from 'react-cookies';
import { Dropdown } from "react-bootstrap";

class Settings extends Component {
  componentWillMount() {
    this.props.settingsStore.appStore = this.props.appStore;
   this.props.settingsStore.getSettingsValue.bind(this.props.settingsStore);
   this.props.settingsStore.subHeaderStore = this.props.subHeaderStore;
  
  }
  componentDidMount(){
    var body = document.getElementsByTagName('body')[0];
    var except = document.getElementsByClassName('RDE-settingsDropdownMenu')[0];

    body.addEventListener("click", (ev)=>{
    //  console.log(ev.target.id,"==",ev.target.id==="allowClick")
      if(ev.target.id==="allowClick"|| ev.target.id=== "OddsHighlights"){
          
      }else{
        this.props.subHeaderStore.showSettings = false;
      }
      
    }, false);
    except.addEventListener("click", function(ev){
       // console.log(ev.target.id,"==",ev.target.id==="allowClick")
        if(ev.target.id==="allowClick" || ev.target.id=== "OddsHighlights"){

        }else{
          ev.stopPropagation();
        }
    }, false);
  }
  componentWillReceiveProps(nextProps){}
  render() {
    const {settingsStore} = this.props;
      return (<div>
        <ul className="RDE-settingsDropdownMenu">                                          
          <li>
            <div className="grid-middle">
              <div className="col-6">
                <div className="RDE-settingsDropdown__title">Default Stake :</div>
              </div>
              <div className="col-5">
                <input className="RDE-formControl" type="text"  value={settingsStore.defaultStake} onChange={settingsStore.defaultStakeChange.bind(settingsStore)}/>
              </div>
            </div>
          </li>
          <li>
            <div className="RDE-settingsDropdown__title">Stake :</div>
            <div className="RDE-settingsDropdown__stakeSet">
              <ul className="RDE-settingsDropdown__stakeSetGroup">
                {
                  settingsStore.userCoins.map((coin, index)=>{
                    return <li className="">
                                {!settingsStore.isEdit?<div className={"RDE-item "+(coin.isSelected?"active":"")} onClick={settingsStore.selectCoin.bind(settingsStore,coin.coinId)} id="allowClick">{coin.coinValue}</div>:
                                <input className="RDE-formControl" type="text" onChange={settingsStore.changeValue.bind(settingsStore,coin.coinId)}value={coin.coinValue}/>}
                            </li>
                  })
                }                  
              </ul>
            </div>
            {settingsStore.validCoinsErr.length>0?<div className="RDE-dropDownErrorMsg">{settingsStore.validCoinsErr}</div>:""}
            {settingsStore.isEdit?<button className="btn RDE-btn-primary RDE-btn-small mt-5" onClick={settingsStore.updateCoins.bind(settingsStore)} id="allowClick">Ok</button>:
            <button className="btn RDE-btn-primary RDE-btn-small mt-5" onClick={settingsStore.editMode.bind(settingsStore)} id="allowClick">Edit</button>}
          </li>
          <li>
            <div className="RDE-settingsDropdown__title">Odds :</div>
            <div className="RDE-checkbox">
              <input id="OddsHighlights" type="checkbox" checked={settingsStore.isOddsHighlight} onChange={settingsStore.oddsHighlightsChange.bind(settingsStore)}/>
              <label htmlFor="OddsHighlights"></label>
              <span className="RDE-checkboxLabel">Highlight when odds change</span>
            </div>
          </li>          
        </ul>
        <div className="space-5"></div>
        {settingsStore.coinCountErr.length>0?<div className="RDE-dropDownErrorMsg">{settingsStore.coinCountErr}</div>:""}
        <div className="grid-noGutter-middle-center">
              
              <div className="col-6">
                <button className={"btn RDE-btn-primary mb-5"+(settingsStore.isEdit?" RDE-btnOddsDisabled":"")} onClick={settingsStore.saveSettings.bind(settingsStore)} id="allowClick">Save</button>
              </div>
            </div>
        </div>
        )
    }
    
}
export default observer(Settings);
