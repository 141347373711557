import { extendObservable } from "mobx";
import cookie from 'react-cookies';
import Validator from 'validatorjs';

import { profile } from "../../../../sharedfiles/EndpointConfig";
import { apiCaller } from '../../../../sharedfiles/apiStore';

class ChangePasswordStore {
  constructor(props) {
    extendObservable(this, {
      stakes: [],
      password: "",
      updateFields: {
        password: "",
        newPassword: "",
        confirmPassword: ""
      },
      updateFieldErrors: {
        password: "",
        newPassword: "",
        confirmPassword: ""
      },
      updateError: "",
      updateSuccess: ""
    });
  }

  /**
  * This method is called when there is a change in the login fields(onchange event).
  * this method reads user typed value and assigns to respective state variable 
  * @param -field - {string}- this param contains the field name in which change occered 
  * @param -event- {js event object }- which contains user typed authentication number.
  */
  onFiledChange(field, event) {
    switch (field) {
      case 'password':
        this.updateFields.password = event.target.value;
        this.updateFieldErrors.password = "";
        this.updateError = "";
        break;
      case 'newPassword':
        this.updateFields.newPassword = event.target.value;
        this.updateFieldErrors.newPassword = "";
        this.updateError = "";
        break;
      case 'confirmPassword':
        this.updateFields.confirmPassword = event.target.value;
        this.updateFieldErrors.confirmPassword = "";
        this.updateError = "";
        break;
      default:
        console.log("signup default");
    }
    //     setTimeout(()=>{

    //     var updateFieldErrors={
    //       password:"",
    //       newPassword:"",
    //       confirmPassword:""
    //     }
    // this.updateFieldErrors = updateFieldErrors;


  }
  /**
  *	This method is used to update the password.
  *
  */
  updatePassword() {
    var rules = {
      password: 'required|min:8',
      newPassword: 'required|max:16|min:8',
      confirmPassword: 'required|same:newPassword',
    };
    var validation = new Validator(this.updateFields, rules, {
      "required.password": "Please enter password.",
      "required.newPassword": "Please enter new password.",
      "required.confirmPassword": "Please enter confirm password.",
      "same.confirmPassword": "New Password and Confirm Password should match",
      "min.password": "Password must be at least 8 characters."
    });
    var token = "";
    var cookeTocken = cookie.load('UTocken');
    if (cookeTocken && cookeTocken.length > 0) {
      token = cookeTocken;
    }
    var param = {
      currentPassword: this.updateFields.password,
      newPassword: this.updateFields.newPassword,
      userToken: token,
      isUser: true
    }
    if (validation.passes()) {
      apiCaller(profile.changePassword, 'POST', param).then(response => {
        if (response &&
          response.hasOwnProperty('data') &&
          response.data &&
          response.data.hasOwnProperty('updated') &&
          response.data.updated) {
          var updateFields = {
            password: "",
            newPassword: "",
            confirmPassword: ""
          }
          this.updateFields = updateFields;
          if (response.data.message) {
            this.updateSuccess = response.data.message;
          }
          else {
            this.updateSuccess = "Password updated successfully."
          }
        } else {
          if (response.data.message) {
            this.updateError = response.data.message;
          }
          else {
            this.updateError = "please enter valid details.";
          }
        }

      });
    } else {
     // console.log(validation.errors)
      if (validation.errors.has('password') && validation.errors.get("password").length > 0) {
        this.updateFieldErrors.password = validation.errors.get("password")[0]
      }
      if (validation.errors.has('newPassword') && validation.errors.get("newPassword").length > 0) {
        this.updateFieldErrors.newPassword = validation.errors.get("newPassword")[0]
      }
      if (validation.errors.has('confirmPassword') && validation.errors.get("confirmPassword").length > 0) {
        this.updateFieldErrors.confirmPassword = validation.errors.get("confirmPassword")[0]
      }
    }
    setTimeout(() => {
      if (this.updateSuccess) {
        this.updateSuccess = "";
      }
      if (this.updateError) {
        this.updateError = "";
      }
      var updateFieldErrors = {
        password: "",
        newPassword: "",
        confirmPassword: ""
      }
      this.updateFieldErrors = updateFieldErrors;

    }, 6000)
  }
  clearFields() {
    if (this.updateSuccess) {
      this.updateSuccess = "";
    }
    if (this.updateError) {
      this.updateError = "";
    }
    var updateFieldErrors = {
      password: "",
      newPassword: "",
      confirmPassword: ""
    }
    this.updateFieldErrors = updateFieldErrors;
    var updateFields = {
      password: "",
      newPassword: "",
      confirmPassword: ""
    }
    this.updateFields = updateFields;
  }

}

export default ChangePasswordStore;
