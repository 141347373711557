import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PlacedBetEventDetails from '../placedbeteventdetails/placedbeteventdetails';
import PlacedBetEventDetailsStore from '../placedbeteventdetails/placedbeteventdetailsstore';

const placedBetEventDetailsStore = new PlacedBetEventDetailsStore();
//import BalanceOverviewStore from './BalanceOverviewTableStore';

class BalanceOverview extends Component {
  constructor(props) {
    super(props);
    this.handleEventClick = this.handleEventClick.bind(this);
  }
  componentWillMount() {
  }
  componentWillReceiveProps(nextProps) { }
  handleEventClick(transaction) {
    this.props.balanceOverviewTableStore.showEventDetails = true;
    this.props.balanceOverviewTableStore.selectedEvent = transaction;
  }
  componentWillUnmount(){
    this.props.balanceOverviewTableStore.showEventDetails = false;
  }
  render() {
    const { data, component, balanceOverviewTableStore,  selectedSport } = this.props;
    var self = this;
    var formatedData = balanceOverviewTableStore.formatData(data);
    return (
      <div className="RDE-DB-table">

        {/*         
        <table className="RDE-DB-table-default d-none">
          <thead>
            <tr>
              <th>Date</th>
              {component === 'BO' ? <th>Transaction</th> : ""}
              <th>Debits</th>
              <th>Credits</th>
              <th>Balance</th>
              <th>Remarks</th>
              <th>From/To</th>
            </tr>
          </thead>
          <tbody>
            {
              data.map((item, index) => {
                return <tr>
                  <td>{getLocalDate(item.transactionDate)} </td>
                  {component === 'BO' ? <td>{item.transactionId}</td> : ""}
                  <td>{item.debitAmount > 0 ? item.debitAmount : '-'}</td>
                  <td>{item.creditAmount > 0 ? item.creditAmount : '-'}</td>
                  <td>{item.balance}</td>
                  <td>{item.transactionRemarks.length > 0 ? item.transactionRemarks : '-'}</td>
                  <td>{item.fromTo}</td>
                </tr>
              })
            }
          </tbody>
        </table>

         */}

        {!balanceOverviewTableStore.showEventDetails ? <div className="">


          <table className="RDE-DB-table-default">
            <thead>
              <tr>
                {component !== 'PL' ? <th>Date</th> : ''}
                {component !== 'PL' ? <th>Transaction Type</th> : ''}
                <th>Description</th>
                {component === 'PL' ? <th>Start time</th> : ''}
                {component === 'PL' ? <th>Settled time</th> : ''}
                <th>{component === 'PL' ? 'Comm' : "P&L"}</th>
                <th>{component === 'PL' ? 'Net Win' : "Balance"}</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(formatedData).map(function (day, dayIndex) {
                return (
                  <>
                    <tr>
                      <td className="text-left" colSpan="5"><b>{day}</b></td>
                    </tr>
                    {formatedData[day].map(function (transaction, transactionIndex) {
                      return (
                        <tr>
                          {component !== 'PL' ? <td>{transaction.time}</td> : ""}
                          {component !== 'PL' ? <td>{transaction.transactionType}</td> : ""}
                          <td onClick={() => self.handleEventClick(transaction)}>{transaction.eventName}</td>
                          {component === 'PL' ? <td>{transaction.betPlacedDate}</td> : ""}
                          {component === 'PL' ? <td>{transaction.transactionDate}</td> : ""}
                          <td><span className={"RDE-DB-txtHighlight " + (transaction.profitLossAmount.toString().indexOf('-') ? "RDE-DB-profit" : "RDE-DB-loss")}>{transaction.profitLossAmount}</span></td>
                          {component === 'PL' ? <td><span className={"RDE-DB-txtHighlight " + (transaction.balance.toString().indexOf('-') ? "RDE-DB-profit" : "RDE-DB-loss")}>{transaction.balance}</span></td> : <td>{transaction.balance}</td>}
                        </tr>
                      )
                    })}
                  </>
                )
              })}
            </tbody>
          </table>


        </div> :
          <PlacedBetEventDetails balanceOverviewTableStore={balanceOverviewTableStore}
            selectedEvent={balanceOverviewTableStore.selectedEvent} component={component} placedBetEventDetailsStore={placedBetEventDetailsStore} selectedSport={selectedSport} />}

      </div>
    )
  }
}
export default observer(BalanceOverview);
