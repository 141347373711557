import { extendObservable } from "mobx";

import { sportsPage } from "../../../sharedfiles/EndpointConfig";
import { apiCaller } from '../../../sharedfiles/apiStore';
import { getTimeZoneOffset } from '../../../sharedfiles/helper';

class SportsAllEventsStore {
  constructor(props) {
    extendObservable(this, {
      leagues: [],
      accordionOpenIndex:[0],
      selectedLeagueId:-1,
      appStore:{},
      sportName:"",
      sportId:"",
      showLoader:true,
      showErrorMsg:false,
      initialItemsShow:5,
      itemsPerClick:5
    });
  }
  /**
  * This method is used to get the league data.
  */
  getLeagueData(){
    this.showLoader=true;
    this.showErrorMsg=false;
    this.leagues=[]
    apiCaller(sportsPage.getCountryLeagues+"?sportId="+this.sportId+"&timeInterval="+getTimeZoneOffset(),'GET').then(response => {
          this.showLoader=false;
          if(response.data && response.data.hasOwnProperty('countries') &&
             response.data.countries && response.data.countries.length>0){
              this.leagues = response.data.countries;
              for(var league of this.leagues){
                league.itemShow = this.initialItemsShow;
              }
          }else{
            this.showErrorMsg=true;
          }
    });
  }
  /**
  * this method is used to open the accordion.
  * @param {Number} index- contains index to open the accordion.
  */
  openAccordion(index){
   // console.log(index)
      if(index>=0){
        var eventIndex = this.accordionOpenIndex.indexOf(index);
        if(eventIndex===-1){
            this.accordionOpenIndex.push(index);
        }else{
            this.accordionOpenIndex.splice(eventIndex,1);
        }
        
      }
  }
  /**
  * This method is called when user selects league
  * @param {Number} leagueId- contains the league id to select;
  */
  leagueSelect(leagueId){
      if(leagueId && leagueId>0){
          this.selectedLeagueId= leagueId;
          this.getLeagueEvent();
      }
  }
  /**
  * This method is used to show all events.
  * @param {number} index- contains on which index all leagues should show
  */
  showAllLeagues(index){
    if(this.leagues.length >= index){
      this.leagues[index].itemShow  = this.leagues[index].leagues.length;
    }
  }
  /**
  * This method is used to show less events.
  * @param {number} index- contains on which index all leagues should show
  */
  showLessLeagues(index){
    if(this.leagues.length >= index){
      this.leagues[index].itemShow  = this.initialItemsShow;
    }
  }
}

export default SportsAllEventsStore;
