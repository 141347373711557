import { extendObservable } from "mobx";

class PopularStore {
  constructor(props) {
    extendObservable(this, {
      appStore:{},
      parentStore:{},
      component:""
    });
  }
  /**
  * This method is used to pin the event
  * @param {eventId} - (number) - contains the event id to pin.
  */
  savePin(eventId, marketId, subMarket, index , providerId, isPinned, eventProps){
    this.appStore.saveEventPin(eventId, marketId, subMarket,providerId,isPinned, (success)=>{
      eventProps.data[index].isPinned = !eventProps.data[index].isPinned;
      this.parentStore.updateData();
    })
  }
}

export default PopularStore;
