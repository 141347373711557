import React, { Component } from 'react';
import { observer } from 'mobx-react';
import OddsComponent from '../oddscomponent/OddsComponent';
import { formatDate } from '../../sharedfiles/helper';

import MarketOutcomes from '../marketoutcomes/MarketOutcomes';
import MarketOutcomesStore from '../marketoutcomes/MarketOutcomesStore';
import FancyOutcomes from '../fancyoutcomes/FancyOutcomes';
import FancyOutcomesStore from '../fancyoutcomes/FancyOutcomesStore';
import Popular from '../popular/Popular';
import PopularStore from '../popular/PopularStore';
import dataLoader from '../../assets/images/loader-orange.svg';
import { preMatchRefreshTime } from '../../sharedfiles/helper';
import ScoreCard from '../scorecard/ScoreCard';
import ScoreCardStore from '../scorecard/ScoreCardStore';

const marketOutcomesStore = new MarketOutcomesStore();
const fancyOutcomesStore = new FancyOutcomesStore();
const popularStore = new PopularStore();
const scoreCardStore = new ScoreCardStore();
class EventView extends Component {
  componentWillMount() {
    this.props.eventViewStore.betslipComponentStore = this.props.betslipComponentStore;
    this.props.eventViewStore.appStore = this.props.appStore;
    this.props.appStore.clearBetslip();
    this.props.eventViewStore.mounted = true;
    this.props.appStore.showLiveStreaming = true;
    // console.log("showLiveStreaming==",this.props.appStore.showLiveStreaming)
    if (this.props.eventId &&
      this.props.eventId !== '' &&
      this.props.eventId !== null) {
      this.props.eventViewStore.eventId = this.props.eventId;
      this.props.eventViewStore.isInitialLoad = true;
      this.props.eventViewStore.getEventView(this.props.appStore);
    } else {
      if (this.props.match && this.props.match.params.eventId &&
        this.props.match.params.eventId !== '' &&
        this.props.match.params.eventId !== null) {
        this.props.eventViewStore.eventId = Number(this.props.match.params.eventId);
        this.props.eventViewStore.isInitialLoad = true;
        this.props.eventViewStore.getEventView(this.props.appStore);
      }
    }
    if (this.props.sportName &&
      this.props.sportName !== '' &&
      this.props.sportName !== null) {
      this.props.eventViewStore.sportName = this.props.sportName;
    } else {
      if (this.props.match && this.props.match.params.sportName &&
        this.props.match.params.sportName !== '' &&
        this.props.match.params.sportName !== null) {
        //this.props.eventViewStore.sportName = this.props.match.params.sportName;
      }
    }
    if (this.props.sportId &&
      this.props.sportId !== '' &&
      this.props.sportId !== null) {
      this.props.eventViewStore.sportId = Number(this.props.sportId);
      this.props.appStore.sportId = this.props.match.params.sportId;
      this.props.appStore.sportName = this.props.match.params.sportName;
      this.props.appStore.getLeagues();
    } else {
      if (this.props.match && this.props.match.params.sportId &&
        this.props.match.params.sportId !== '' &&
        this.props.match.params.sportId !== null) {
        this.props.eventViewStore.sportId = Number(this.props.match.params.sportId);
      }
    }

  }
  componentWillReceiveProps(nextProps) {
    if (this.props.match) {
      if (this.props.eventId &&
        this.props.eventId !== '' &&
        this.props.eventId !== null && this.props.eventViewStore.eventId !== nextProps.eventId) {
        this.props.eventViewStore.eventId = nextProps.eventId;
        this.props.eventViewStore.isInitialLoad = true;
        this.props.appStore.clearBetslip();
        this.props.eventViewStore.getEventView(nextProps.appStore);
        window.scrollTo(0,0)

      }
      if (this.props.sportName &&
        this.props.sportName !== '' &&
        this.props.sportName !== null && this.props.eventViewStore.sportName !== nextProps.sportName) {
        //this.props.eventViewStore.sportName = nextProps.sportName;
      }
      if (this.props.sportId &&
        this.props.sportId !== '' &&
        this.props.sportId !== null && this.props.eventViewStore.sportId !== nextProps.sportId) {
        this.props.eventViewStore.sportId = Number(nextProps.sportId);
        this.props.appStore.sportId = this.props.match.params.sportId;
        this.props.appStore.sportName = this.props.match.params.sportName;
        this.props.appStore.getLeagues();
      }
      if (nextProps.isLoggedIn !== this.props.isLoggedIn) {
        this.props.eventViewStore.isInitialLoad = true;
        this.props.appStore.clearBetslip();
        this.props.eventViewStore.getEventView(nextProps.appStore);
      }
    }
    // if(this.props.betSlipStatusChange !== nextProps.betSlipStatusChange){
    //   this.props.eventViewStore.getProfitLoss();
    // }
    if (this.props.pLStatusChanged !== nextProps.pLStatusChanged) {
      this.props.eventViewStore.getEventPL();
      if (this.props.eventViewStore.matchOdds && Object.keys(this.props.eventViewStore.matchOdds).length > 0) {
        this.props.eventViewStore.marchOddsPL = {}
        this.props.eventViewStore.marchOddsPL = this.props.eventViewStore.getMarketPL(this.props.eventViewStore.matchOdds.marketGroupId, this.props.eventViewStore.matchOdds.providerId, null)
        //console.log('MPLS0 ',this.props.eventViewStore.marchOddsPL)
      }
      if (this.props.eventViewStore.matchOdds1 && Object.keys(this.props.eventViewStore.matchOdds1).length > 0) {
        this.props.eventViewStore.marchOdds1PL = {}
        this.props.eventViewStore.marchOdds1PL = this.props.eventViewStore.getMarketPL(this.props.eventViewStore.matchOdds1.marketGroupId, this.props.eventViewStore.matchOdds1.providerId, null)
        //  console.log('MOPL0== ',this.props.eventViewStore.marchOdds1PL)
      }
    }
  }
  componentDidMount() {
    var self = this;
    setTimeout(function reCallService() {
      if (self.props.eventViewStore.mounted) {
        self.props.eventViewStore.getEventView(self.props.appStore);
        setTimeout(reCallService, 5000);
      }
    }, preMatchRefreshTime);
  }
  componentWillUnmount() {
    this.props.eventViewStore.mounted = false;
    //this.props.appStore.showLiveStreaming = false;
  }
  /**
  * This method is used to navigate to other pages.
  * @param {string} page- contains page to navigate. 
  */
  navigateTo(page) {
    switch (page) {
      case 'home':
        this.props.history.push('/');
        // this.props.appStore.goToHome();
        break;
      case 'sport':
        this.props.history.push('/sports/' + this.props.eventViewStore.eventInfo.sportName + '/' + this.props.eventViewStore.eventInfo.sportId);
        break;
      case 'league':
        this.props.history.push('/sports/' + this.props.eventViewStore.eventInfo.sportName + '/'
          + this.props.eventViewStore.eventInfo.sportId + '/league/'
          + this.props.eventViewStore.eventInfo.leagueId + '/'
          + this.props.eventViewStore.eventInfo.leagueName);
    }
  }
  render() {
    const { eventViewStore, appStore, betSlipStatusChange, pLStatusChanged } = this.props;
    return (
      <div className="RDE-mainContent">
        {(eventViewStore.isMatchOdds ||
          eventViewStore.isFancy ||
          eventViewStore.isPopular ||
          eventViewStore.isGoals) && !eventViewStore.showLoader ?
          <div className="RDE-eventView RDE-cricket">
            <div className="RDE-sportsPage-breadcrumb">
              <span onClick={this.navigateTo.bind(this, 'home')}>Home</span>&nbsp;/&nbsp;
              <span onClick={this.navigateTo.bind(this, 'sport')}>{eventViewStore.eventInfo ? eventViewStore.eventInfo.sportName : ''}</span>&nbsp;/&nbsp;
              <span onClick={this.navigateTo.bind(this, 'league')}>{eventViewStore.eventInfo ? eventViewStore.eventInfo.leagueName : ''}</span>
            </div>
            <div className="RDE-eventView-scoreBoardContainer d-none">
              <div className="RDE-eventView-scoreBoardBackgroundImage RDE-eventView-backgroundImage_cricket">
                <div className="RDE-eventView-scoreBoard-content">
                  <div className="RDE-eventView-scoreBoard">
                    <div className="RDE-eventView-scoreBoard-left RDE-homeTeam text-right">
                      <div className="RDE-eventView-scoreBoard-teamDetails">
                        <div className="RDE-eventView-scoreBoard-teamName">{eventViewStore.eventInfo ? eventViewStore.eventInfo.homeTeamName : ""} </div>
                        {/*eventViewStore.sportId === 4 && eventViewStore.eventInfo && eventViewStore.eventInfo.isLive ? <div className="RDE-eventView-cricketLiveScore">243/10 (50)</div> : ''*/}
                      </div>
                      <div className="RDE-eventView-teamLogo">
                        {/* <img src="images/team-logo-default.png" className="RDE-team-logo-default" /> */}
                      </div>
                    </div>
                    <div className="RDE-eventView-scoreBoard-middle">
                      {/*eventViewStore.sportId === 1 && eventViewStore.eventInfo && eventViewStore.eventInfo.isLive === 1 ? <div className="RDE-eventView-soccerLiveScore">
                       1 : 0
                        </div> : ""*/}
                      {/*eventViewStore.sportId === 4 && eventViewStore.eventInfo && eventViewStore.eventInfo.isLive ? <div className="RDE-eventView-cricketInningsTxt">Innings 2</div> : ""*/}

                      {eventViewStore.sportId === 1 && eventViewStore.eventInfo && eventViewStore.eventInfo.isLive ? <div className="RDE-eventView-soccerMatchMinute">{/*69'*/}</div> : ""}
                      {eventViewStore.eventInfo && !eventViewStore.eventInfo.isLive ? <div className="RDE-eventView-kickOff">{eventViewStore.eventInfo && eventViewStore.eventInfo.kickOffTime ? formatDate(eventViewStore.eventInfo.kickOffTime) : ''}</div> : ""}
                    </div>
                    <div className="RDE-eventView-scoreBoard-right RDE-awayTeam">
                      <div className="RDE-eventView-teamLogo">
                        {/* <img src="images/team-logo-default.png" className="RDE-team-logo-default" /> */}
                      </div>
                      <div className="RDE-eventView-scoreBoard-teamDetails">
                        <div className="RDE-eventView-scoreBoard-teamName">{eventViewStore.eventInfo ? eventViewStore.eventInfo.awayTeamName : ""}</div>
                        {/*eventViewStore.sportId === 4 && eventViewStore.eventInfo && eventViewStore.eventInfo.isLive ? <div className="RDE-eventView-cricketLiveScore">38/0 (5.5)</div> : ""*/}
                      </div>
                    </div>
                  </div>
                </div>
                {eventViewStore.sportId === 2 && eventViewStore.eventInfo && eventViewStore.eventInfo.isLive && false ? <div className="RDE-eventView-scoreBoard-content">
                  <div className="RDE-eventView-scoreBoard RDE-eventView-tennisLiveScoreBoard">
                    <div className="RDE-eventView-scoreBoard-left text-right">
                      <div className="RDE-eventView-scoreBoard-teamDetails">
                        <div className="RDE-eventView-scoreBoard-teamName RDE-eventView-serving">{eventViewStore.eventInfo ? eventViewStore.eventInfo.homeTeamName : ""}</div>
                        <div className="space-5"></div>
                        <div className="RDE-eventView-scoreBoard-teamName">{eventViewStore.eventInfo ? eventViewStore.eventInfo.awayTeamName : ""}</div>
                      </div>
                    </div>
                    {/*<div className="RDE-eventView-scoreBoard-right">
                    <div className="RDE-eventView-tennisLiveScore">
                      <ul className="RDE-eventView-tennisLiveScore__headerList">
                        <li>1</li>
                        <li>2</li>
                        <li>3</li>
                        <li>4</li>
                        <li>5</li>
                        <li className="RDE-space"></li>
                        <li>P</li>
                        <li>A</li>
                        <li>F</li>
                        <li>B</li>
                      </ul>
                      <div className="RDE-eventView-line"></div>
                      <ul className="RDE-eventView-tennisLiveScore__scoreList">
                        <li><b> 4</b></li>
                        <li className="active"><b> 0 </b></li>
                        <li><b> 0</b></li>
                        <li><b> 0</b></li>
                        <li><b> 0</b></li>
                        <li className="RDE-space"></li>
                        <li className="active"><b> 0</b></li>
                        <li>4</li>
                        <li>1</li>
                        <li>1</li>
                      </ul>
                      <div className="space-5"></div>
                      <ul className="RDE-eventView-tennisLiveScore__scoreList">
                        <li><b> 6</b></li>
                        <li className="active"><b> 3 </b></li>
                        <li><b> 0</b></li>
                        <li><b> 0</b></li>
                        <li><b> 0</b></li>
                        <li className="RDE-space"></li>
                        <li className="active"><b> 15</b></li>
                        <li>1</li>
                        <li>0</li>
                        <li>3</li>
                      </ul>
                    </div>

                  </div>*/}
                  </div>
                </div> : ""}
              </div>

            </div>
            <div className="RDE-eventView-scoreBoardContainer">
              <div className="RDE-eventView-scoreBoardBackgroundImage RDE-eventView-backgroundImage_cricket">
                {eventViewStore.eventInfo.isLive === 1 ?
                  <div className="RDE-eventView-scoreBoard-content RDE-eventView-scoreBoard-info">
                    <ScoreCard scoreCardStore={scoreCardStore} sportId={eventViewStore.sportId} eventId={eventViewStore.eventId} eventInfo={eventViewStore.eventInfo} />
                  </div> :
                  <div class="RDE-eventView-scoreBoard RDE-eventview-preMatch">
                    <div class="RDE-eventView-scoreBoard-left RDE-homeTeam text-right">
                      <div class="RDE-eventView-scoreBoard-teamDetails">
                        <div class="RDE-eventView-scoreBoard-teamName">{eventViewStore.eventInfo.homeTeamName}</div>
                      </div>
                    </div>
                    <div class="RDE-eventView-scoreBoard-middle">
                      <div class="RDE-eventView-kickOff">{formatDate(eventViewStore.eventInfo.kickOffTime)}</div>
                    </div>
                    <div class="RDE-eventView-scoreBoard-right RDE-awayTeam">
                      <div class="RDE-eventView-scoreBoard-teamDetails">
                        <div class="RDE-eventView-scoreBoard-teamName">{eventViewStore.eventInfo.awayTeamName}</div>
                      </div>
                    </div>
                  </div>
                }

                <div className="RDE-eventView-scoreBoard-content d-none">
                  <div className="RDE-eventView-scoreBoard">
                    <div className="RDE-eventView-scoreBoard-left RDE-homeTeam text-right">
                      <div className="RDE-eventView-scoreBoard-teamDetails">
                        <div className="RDE-eventView-scoreBoard-teamName">{eventViewStore.eventInfo ? eventViewStore.eventInfo.homeTeamName : ""} </div>
                        {/*eventViewStore.sportId === 4 && eventViewStore.eventInfo && eventViewStore.eventInfo.isLive ? <div className="RDE-eventView-cricketLiveScore">243/10 (50)</div> : ''*/}
                      </div>
                      <div className="RDE-eventView-teamLogo">
                        {/* <img src="images/team-logo-default.png" className="RDE-team-logo-default" /> */}
                      </div>
                    </div>
                    <div className="RDE-eventView-scoreBoard-middle">
                      {/*eventViewStore.sportId === 1 && eventViewStore.eventInfo && eventViewStore.eventInfo.isLive === 1 ? <div className="RDE-eventView-soccerLiveScore">
                       1 : 0
                        </div> : ""*/}
                      {/*eventViewStore.sportId === 4 && eventViewStore.eventInfo && eventViewStore.eventInfo.isLive ? <div className="RDE-eventView-cricketInningsTxt">Innings 2</div> : ""*/}

                      {eventViewStore.sportId === 1 && eventViewStore.eventInfo && eventViewStore.eventInfo.isLive ? <div className="RDE-eventView-soccerMatchMinute">{/*69'*/}</div> : ""}
                      {eventViewStore.eventInfo && !eventViewStore.eventInfo.isLive ? <div className="RDE-eventView-kickOff">{eventViewStore.eventInfo && eventViewStore.eventInfo.kickOffTime ? formatDate(eventViewStore.eventInfo.kickOffTime) : ''}</div> : ""}
                    </div>
                    <div className="RDE-eventView-scoreBoard-right RDE-awayTeam">
                      <div className="RDE-eventView-teamLogo">
                        {/* <img src="images/team-logo-default.png" className="RDE-team-logo-default" /> */}
                      </div>
                      <div className="RDE-eventView-scoreBoard-teamDetails">
                        <div className="RDE-eventView-scoreBoard-teamName">{eventViewStore.eventInfo ? eventViewStore.eventInfo.awayTeamName : ""}</div>
                        {/*eventViewStore.sportId === 4 && eventViewStore.eventInfo && eventViewStore.eventInfo.isLive ? <div className="RDE-eventView-cricketLiveScore">38/0 (5.5)</div> : ""*/}
                      </div>
                    </div>
                  </div>
                </div>


                {/*  {eventViewStore.sportId === 2 && eventViewStore.eventInfo && eventViewStore.eventInfo.isLive && false ?
                  <div className="RDE-eventView-scoreBoard-content">
                  <div className="RDE-eventView-scoreBoard RDE-eventView-tennisLiveScoreBoard">
                    <div className="RDE-eventView-scoreBoard-left text-right">
                      <div className="RDE-eventView-scoreBoard-teamDetails">
                        <div className="RDE-eventView-scoreBoard-teamName RDE-eventView-serving">{eventViewStore.eventInfo ? eventViewStore.eventInfo.homeTeamName : ""}</div>
                        <div className="space-5"></div>
                        <div className="RDE-eventView-scoreBoard-teamName">{eventViewStore.eventInfo ? eventViewStore.eventInfo.awayTeamName : ""}</div>
                      </div>
                    </div>
                    <div className="RDE-eventView-scoreBoard-right">
                    <div className="RDE-eventView-tennisLiveScore">
                      <ul className="RDE-eventView-tennisLiveScore__headerList">
                        <li>1</li>
                        <li>2</li>
                        <li>3</li>
                        <li>4</li>
                        <li>5</li>
                        <li className="RDE-space"></li>
                        <li>P</li>
                        <li>A</li>
                        <li>F</li>
                        <li>B</li>
                      </ul>
                      <div className="RDE-eventView-line"></div>
                      <ul className="RDE-eventView-tennisLiveScore__scoreList">
                        <li><b> 4</b></li>
                        <li className="active"><b> 0 </b></li>
                        <li><b> 0</b></li>
                        <li><b> 0</b></li>
                        <li><b> 0</b></li>
                        <li className="RDE-space"></li>
                        <li className="active"><b> 0</b></li>
                        <li>4</li>
                        <li>1</li>
                        <li>1</li>
                      </ul>
                      <div className="space-5"></div>
                      <ul className="RDE-eventView-tennisLiveScore__scoreList">
                        <li><b> 6</b></li>
                        <li className="active"><b> 3 </b></li>
                        <li><b> 0</b></li>
                        <li><b> 0</b></li>
                        <li><b> 0</b></li>
                        <li className="RDE-space"></li>
                        <li className="active"><b> 15</b></li>
                        <li>1</li>
                        <li>0</li>
                        <li>3</li>
                      </ul>
                    </div>

                  </div>
                  </div>
                </div>
                  
                  
                  : ""}
                */}
              </div>
            </div>
            <div className="space-5"></div>

            {eventViewStore.eventInfo && eventViewStore.eventInfo.matchOdds && Object.keys(eventViewStore.eventInfo.matchOdds).length > 0 ? <MarketOutcomes data={eventViewStore.eventInfo && eventViewStore.eventInfo.matchOdds ? eventViewStore.eventInfo.matchOdds : {}}
              eventSuspended={eventViewStore.eventInfo.eventSuspended}
              eventBlocked={eventViewStore.eventInfo.eventBlocked}
              eventName={eventViewStore.eventInfo.eventName}
              eventId={eventViewStore.eventId}
              homeTeam={eventViewStore.eventInfo.homeTeamName}
              awayTeam={eventViewStore.eventInfo.awayTeamName}
              homeTeamId={eventViewStore.eventInfo.homeTeamId}
              awayTeamId={eventViewStore.eventInfo.awayTeamId}
              kickOffTime={eventViewStore.eventInfo.kickOffTime}
              isLive={eventViewStore.eventInfo.isLive}
              appStore={appStore}
              appStoreProfitLoss={appStore.betsProfitLoss}
              parentStore={eventViewStore}
              component='matchodds'
              profitLoss={eventViewStore.marchOddsPL}
              marketOutcomesStore={marketOutcomesStore}
              betSlipStatusChange={betSlipStatusChange} /> : ''}

            {eventViewStore.eventInfo && eventViewStore.eventInfo.matchOdds1 && Object.keys(eventViewStore.eventInfo.matchOdds1).length > 0 ? <MarketOutcomes data={eventViewStore.eventInfo && eventViewStore.eventInfo.matchOdds1 ? eventViewStore.eventInfo.matchOdds1 : {}}
              eventSuspended={eventViewStore.eventInfo.eventSuspended}
              eventBlocked={eventViewStore.eventInfo.eventBlocked}
              eventName={eventViewStore.eventInfo.eventName}
              eventId={eventViewStore.eventId}
              homeTeam={eventViewStore.eventInfo.homeTeamName}
              awayTeam={eventViewStore.eventInfo.awayTeamName}
              homeTeamId={eventViewStore.eventInfo.homeTeamId}
              awayTeamId={eventViewStore.eventInfo.awayTeamId}
              kickOffTime={eventViewStore.eventInfo.kickOffTime}
              isLive={eventViewStore.eventInfo.isLive}
              appStore={appStore}
              appStoreProfitLoss={appStore.betsProfitLoss}
              parentStore={eventViewStore}
              component='matchodds1'
              profitLoss={eventViewStore.marchOdds1PL}
              marketOutcomesStore={marketOutcomesStore}
              betSlipStatusChange={betSlipStatusChange} /> : ''}

            {eventViewStore.eventInfo && eventViewStore.eventInfo.fancyBets ? <FancyOutcomes data={eventViewStore.eventInfo && eventViewStore.eventInfo.fancyBets ? eventViewStore.eventInfo.fancyBets : {}}
              eventSuspended={eventViewStore.eventInfo.eventSuspended}
              eventBlocked={eventViewStore.eventInfo.eventBlocked}
              eventName={eventViewStore.eventInfo.eventName}
              eventId={eventViewStore.eventId}
              homeTeam={eventViewStore.eventInfo.homeTeamName}
              awayTeam={eventViewStore.eventInfo.awayTeamName}
              homeTeamId={eventViewStore.eventInfo.homeTeamId}
              awayTeamId={eventViewStore.eventInfo.awayTeamId}
              kickOffTime={eventViewStore.eventInfo.kickOffTime}
              isLive={eventViewStore.eventInfo.isLive}
              marketGroupId={eventViewStore.eventInfo.fancyBets.marketGroupId}
              providerId={eventViewStore.eventInfo.fancyBets.providerId}
              backHeader='Yes'
              layHeader='No'
              mainHeader='Fancy Bets'
              component='fancy'
              showPin={true}
              appStore={appStore}
              parentStore={eventViewStore}
              fancyOutcomesStore={fancyOutcomesStore}
              betSlipStatusChange={betSlipStatusChange} /> : ""}

            {eventViewStore.eventInfo && eventViewStore.eventInfo.fancyBets1 ? <FancyOutcomes data={eventViewStore.eventInfo && eventViewStore.eventInfo.fancyBets1 ? eventViewStore.eventInfo.fancyBets1 : {}}
              eventSuspended={eventViewStore.eventInfo.eventSuspended}
              eventBlocked={eventViewStore.eventInfo.eventBlocked}
              eventName={eventViewStore.eventInfo.eventName}
              eventId={eventViewStore.eventId}
              homeTeam={eventViewStore.eventInfo.homeTeamName}
              awayTeam={eventViewStore.eventInfo.awayTeamName}
              homeTeamId={eventViewStore.eventInfo.homeTeamId}
              awayTeamId={eventViewStore.eventInfo.awayTeamId}
              kickOffTime={eventViewStore.eventInfo.kickOffTime}
              isLive={eventViewStore.eventInfo.isLive}
              marketGroupId={eventViewStore.eventInfo.fancyBets.marketGroupId}
              providerId={eventViewStore.eventInfo.fancyBets.providerId}
              backHeader='Back'
              layHeader='Lay'
              mainHeader='Fancy Bets1'
              component='adminfancy'
              showPin={true}
              appStore={appStore}
              parentStore={eventViewStore}
              fancyOutcomesStore={fancyOutcomesStore}
              betSlipStatusChange={betSlipStatusChange} /> : ""}


            {eventViewStore.isPopular || eventViewStore.isGoals ? <div className="RDE-singleLineOutcomeMarketHeader"><div className="RDE-sports-nav-tabs"><ul className="RDE-sports-nav-tabs-menu">
              {eventViewStore.isPopular ? <li>
                <div className={"RDE-sports-nav-tabs-menu__item" + (eventViewStore.showTab === "popular" ? ' active' : '')} onClick={eventViewStore.selectTab.bind(eventViewStore, 'popular')}>
                  <span className="RDE-skewTxt">Popular</span>
                </div>
              </li> : ''}
              {eventViewStore.isGoals ? <li>
                {eventViewStore.sportId === 1 || eventViewStore.sportId === 4 ?
                  <div className={"RDE-sports-nav-tabs-menu__item" + (eventViewStore.showTab === "goal" ? ' active' : '')} onClick={eventViewStore.selectTab.bind(eventViewStore, 'goal')}>
                    <span className="RDE-skewTxt">{eventViewStore.sportId === 1 ? 'Goals' : 'Tied match'}</span>
                  </div> : ''}
              </li> : ''}
            </ul></div></div> : ''}

            {eventViewStore.showTab === "popular" ? <Popular data={eventViewStore.eventInfo && eventViewStore.eventInfo.popular ? eventViewStore.eventInfo.popular : []}
              eventSuspended={eventViewStore.eventInfo.eventSuspended}
              eventName={eventViewStore.eventInfo.eventName}
              eventId={eventViewStore.eventId}
              homeTeam={eventViewStore.eventInfo.homeTeamName}
              awayTeam={eventViewStore.eventInfo.awayTeamName}
              homeTeamId={eventViewStore.eventInfo.homeTeamId}
              awayTeamId={eventViewStore.eventInfo.awayTeamId}
              kickOffTime={eventViewStore.eventInfo.kickOffTime}
              isLive={eventViewStore.eventInfo.isLive}
              appStore={appStore}
              parentStore={eventViewStore}
              popularStore={popularStore}
              betSlipStatusChange={betSlipStatusChange}
              pLStatusChanged={pLStatusChanged} /> :
              <Popular data={eventViewStore.eventInfo && eventViewStore.eventInfo.goals ? eventViewStore.eventInfo.goals : []}
                eventSuspended={eventViewStore.eventInfo.eventSuspended}
                eventName={eventViewStore.eventInfo.eventName}
                eventId={eventViewStore.eventId}
                homeTeam={eventViewStore.eventInfo.homeTeamName}
                awayTeam={eventViewStore.eventInfo.awayTeamName}
                homeTeamId={eventViewStore.eventInfo.homeTeamId}
                awayTeamId={eventViewStore.eventInfo.awayTeamId}
                isLive={eventViewStore.eventInfo.isLive}
                kickOffTime={eventViewStore.eventInfo.kickOffTime}
                appStore={appStore}
                parentStore={eventViewStore}
                popularStore={popularStore}
                betSlipStatusChange={betSlipStatusChange}
                pLStatusChanged={pLStatusChanged} />}
          </div> : ''}
        {eventViewStore.showErrorMsg ? <div className="RDE-noDataMessageBox">
          Currently data is not available.
        </div> : ''}
        {eventViewStore.showLoader ? <div className="RDE-loader">
          <img src={dataLoader} alt="loading...!" />
        </div> : ''}
      </div>
    )
  }
}
export default observer(EventView);
