import { extendObservable } from "mobx";
import cookie from 'react-cookies';

import { profile } from "../../../../sharedfiles/EndpointConfig";
import { apiCaller } from '../../../../sharedfiles/apiStore';

class BalanceOverviewStore {
  constructor(props) {
    extendObservable(this, {
      stakes: [],
      showEventDetails: false,
      selectedEvent: {}
    });
  }
  /**
  * This method is used to get the balance overview data
  *
  */
  getBalanceOverview() {
    var token = "";
    var cookeTocken = cookie.load('UTocken');
    if (cookeTocken && cookeTocken.length > 0) {
      token = cookeTocken;
    }
    apiCaller(profile.betBalanceOverview + "?userToken=" + token + "&transactionType=bo", 'GET').then(response => {
      // if(response.data && response.data.hasOwnProperty('sportDetailsList') &&
      //    response.data.sportDetailsList && response.data.sportDetailsList.length>0){
      //     this.sports = response.data.sportDetailsList;
      //     this.selectedSport = this.sports[0];
      //     this.getHighlightsEventData();
      // }
    });
  }

  /**
  * this 
  *
  */
  formatData(data) {
    var filteredData = data.reduce(function (r, a) {
      r[a.date] = r[a.date] || [];
      r[a.date].push(a);
      return r;
    }, Object.create(null));
    return filteredData;
  }
}

export default BalanceOverviewStore;
