import { extendObservable } from "mobx";

class FancyOutcomesStore {
  constructor(props) {
    extendObservable(this, {
      data: [],
      appStore: {},
      parentStore: {},
      component: ""
    });
  }
  /**
  * This method is used to pin the event
  * @param {eventId} - (number) - contains the event id to pin.
  */
  savePin(eventId, marketId, providerId, isPinned, eventProps) {
    this.appStore.saveEventPin(eventId, marketId, '', providerId, isPinned, (success) => {
      eventProps.data.isPinned = !eventProps.data.isPinned;
      this.parentStore.updateData();
    })
  }
}

export default FancyOutcomesStore;
