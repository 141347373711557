import { extendObservable } from "mobx";

class EventViewOddsComponentStore {
  constructor(props) {
    extendObservable(this, {
      data: [],
    });
  }
  displayOdds(odds) {
    return odds.toFixed(2);
  }
}

export default EventViewOddsComponentStore;
