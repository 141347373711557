import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Route, Switch } from "react-router-dom";

import Highlights from '../../sharedCcomponents/highlights/Highlights';
import HighlightsStore from '../../sharedCcomponents/highlights/HighlightsStore';
import EventView from '../../sharedCcomponents/eventview/EventView';
import EventViewStore from '../../sharedCcomponents/eventview/EventViewStore';

const eventViewStore = new EventViewStore();
const highlightsStore = new HighlightsStore();
class InplaypageLayout extends Component {
  componentWillMount() {
    this.props.appStore.currentPath = "inplay";
    this.props.appStore.clearBetslip();
  }
  componentDidMount() {

  }

  componentWillUnmount() {
  }

  onInitialFetch(groups) { }

  componentWillReceiveProps(nextProps) {
  }

  render() {
    const { appStore, isLoggedIn } = this.props;
    return (
      <div className="RDE-mainContent">
        <Switch>
          <Route path="/inplay" exact render={(props) => <Highlights  {...props} appStore={appStore}
            highlightsStore={highlightsStore}
            isLoggedIn={isLoggedIn}
            componentType="inplay"
            pageId={2} />} />
          <Route path="/inplay/:sportName/:sportId/event/:eventId" exact render={(props) => <EventView {...props} appStore={appStore} eventViewStore={eventViewStore} />} />
        </Switch>
      </div>
    );
  }
}

export default observer(InplaypageLayout);
