import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Collapse } from "react-bootstrap";
import dataLoader from '../../../assets/images/loader-orange.svg';

class SportsAllEvents extends Component {
    componentWillMount() {
      if(this.props){
          if(this.props.sportId && this.props.sportId !=='' && this.props.sportId!==null){
            this.props.sportsAllEventsStore.sportId= this.props.sportId;
            this.props.sportsAllEventsStore.getLeagueData();
          }
          if(this.props.sportName && this.props.sportName !=='' && this.props.sportName!==null){
            this.props.sportsAllEventsStore.sportName= this.props.sportName;
          }
        } 
      this.props.sportsAllEventsStore.appStore=this.props.appStore;
      
    }
    componentWillReceiveProps(nextProps) { 
     // console.log("nextProps===",nextProps)
      if(this.props){
        if(nextProps.sportId && 
           nextProps.sportId !=='' && 
           nextProps.sportId!==null && 
           nextProps.sportId !==this.props.sportsAllEventsStore.sportId){
            this.props.sportsAllEventsStore.sportId= nextProps.sportId;
            this.props.sportsAllEventsStore.getLeagueData();
        }
        if(nextProps.sportName && 
           nextProps.sportName !=='' && 
           nextProps.sportName!==null &&
           nextProps.sportName !==this.props.sportsAllEventsStore.sportName){
            this.props.sportsAllEventsStore.sportName= this.props.match.params.sportName;
        }
      } 
    }
    /**
    * This method is called when user selects league
    * @param {Number} leagueId- contains the league id to select;
    */
    leagueSelect(league){
        if(league.id && league.id>0){
            //this.selectedLeagueId= leagueId;
            this.props.history.push('/sports/'+this.props.sportsAllEventsStore.sportName+'/'+this.props.sportsAllEventsStore.sportId+'/league/'+league.id+"/"+league.name);
        }
    }
    
    render() {
        const {sportsAllEventsStore} = this.props;
        return (
            <div className="RDE-sportsPage-All">
              <div className="RDE-accordion-group">
                {
                  sportsAllEventsStore.leagues.map((country,index)=>{
                    return  <div className={"RDE-accordion"+(sportsAllEventsStore.accordionOpenIndex.indexOf(index)===-1?" collapsed":"")} key={"countrywiseleagues_"+index}>
                              <div className="RDE-accordion-header RDE-singleLineEventMarketHeader">
                                <div className="RDE-singleLineEventMarketHeader__left" onClick={sportsAllEventsStore.openAccordion.bind(sportsAllEventsStore,index)}>
                                  <div className="RDE-singleLineEventMarketHeader-title">
                                    {country.countryName}
                                  </div>
                                </div>
                                <div className="RDE-singleLineEventMarketHeader__right">
                                  {sportsAllEventsStore.accordionOpenIndex.indexOf(index)!==-1 && country.leagues.length>sportsAllEventsStore.initialItemsShow?country.itemShow<country.leagues.length?<div className="RDE-singleLineEventMarketHeader__showAll" onClick={sportsAllEventsStore.showAllLeagues.bind(sportsAllEventsStore,index)}>Show All</div>:<div className="RDE-singleLineEventMarketHeader__showAll" onClick={sportsAllEventsStore.showLessLeagues.bind(sportsAllEventsStore,index)}>Show Less</div>:""}
                                </div>
                              </div>
                              <Collapse in={sportsAllEventsStore.accordionOpenIndex.indexOf(index)!==-1}>
                              <div className="RDE-accordion-body">
                                <div className="RDE-nav-pills RDE-leagueList">
                                  <ul className="RDE-nav-pills-menu">
                                    {
                                      country.leagues.map((league,leagueIndex)=>{
                                        if(leagueIndex < country.itemShow){
                                          return <li key={"leaguewise_leagues_"+leagueIndex} onClick={this.leagueSelect.bind(this,league)}><div className="RDE-nav-pills-menu-item"><span className="RDE-country">{league.name}</span></div></li>
                                        }
                                      })
                                    }
                                  </ul>
                                </div>
                              </div>
                              </Collapse>
                              
                            </div>
                  })
                }
              </div>
              {sportsAllEventsStore.showErrorMsg?<div className="RDE-noDataMessageBox">
                                Currently data is not available for this Sport.
                                              </div>:''}
                                {sportsAllEventsStore.showLoader?<div className="RDE-loader">
                                                  <img src={dataLoader} alt="loading...!" />
                                              </div>:''}
            </div>
        )
    }
}
export default observer(SportsAllEvents);
