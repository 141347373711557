import { extendObservable } from "mobx";
import { profile } from "../../../../sharedfiles/EndpointConfig";
import { apiCaller } from '../../../../sharedfiles/apiStore';
import { utcToTimeZone } from '../../../../sharedfiles/helper';
import cookie from 'react-cookies';

class PlacedBetEventDetailsStore {
    constructor(props) {
        extendObservable(this, {
            eventDetails: [],
            component: '',
            data: [],
            backSubTotal: 0,
            laySubTotal: 0,
            marketSubTotal: 0,
            commission: 0,
            netTotal: 0,
            showLoader:false,
            showErrMsg:false
        });
    }

    getDetails(selectedEvent) {
       this.showLoader=true;
        this.showErrMsg=false;
        var token = "";
        var cookietoken = cookie.load('UTocken');
        if (cookietoken && cookietoken.length > 0) {
            token = cookietoken;
        }
        var params = {
            "userToken": token,
            "transactionType": this.component === "PL" ? 'ps' : 'as',
            "transactionDate": selectedEvent.transactionDate1,
            "eventId": selectedEvent.eventId,
            "outcomeName": selectedEvent.outcomeName,
            "betPlaced": selectedEvent.betPlacedDate1
        }
        var self = this;
        apiCaller(profile.gettransdetailseventview, 'POST', params).then(response => {
            this.showLoader=false;
            // console.log("date===", response);
            if ( response?.data?.length > 0) {
                //self.data = response.data;
                var resData = response.data;
                for (var i = 0; i < resData.length; i++) {
                    //resdata[i]['transactionDate'] = 
                    var fdate = utcToTimeZone(resData[i].transactionDate);
                    resData[i]['transactionDate1'] = fdate.date + '|' + fdate.time;

                }
                self.data = response.data;
                this.calculateTotals()
            }else{
                this.showErrMsg=true
            }
        }).catch(exception => {
            this.showLoader=false;

        })
    }
    calculateTotals() {
        var backSubTotal = 0, laySubTotal = 0, marketSubTotal = 0, commission = 0;
        for (var bet of this.data) {
            if (bet.oddType === 'Back') {
                backSubTotal = backSubTotal + Number(bet.profitLossAmount)
            } else if (bet.oddType === 'Lay') {
                laySubTotal = laySubTotal + Number(bet.profitLossAmount)
            }
            commission = commission + Number(bet.commission)
        }
        marketSubTotal = (backSubTotal + laySubTotal).toFixed(2);
        this.netTotal = (marketSubTotal - commission).toFixed(2);
        this.backSubTotal = (backSubTotal).toFixed(2);
        this.laySubTotal = (laySubTotal).toFixed(2);
        this.marketSubTotal = Number(marketSubTotal).toFixed(2);
        this.commission = Number(commission).toFixed(2);
    }

}
export default PlacedBetEventDetailsStore;