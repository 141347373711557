import { extendObservable } from "mobx";

class EventComponentStore {
  constructor(props) {
    extendObservable(this, {
      data: [],
      appStore: {}
    });
  }
  /**
  * This method is used to pin the event
  * @param {eventId} - (number) - contains the event id to pin.
  */
  savePin(eventId, isPinned, eventProps) {
    this.appStore.saveEventPin(eventId, '', '', '', isPinned, (success) => {
      eventProps.event.isPinned = !eventProps.event.isPinned;
    })
  }
}

export default EventComponentStore;
