import { extendObservable } from "mobx";
import { multiMarket } from "../../../sharedfiles/EndpointConfig";
import { apiCaller } from '../../../sharedfiles/apiStore';
import cookie from 'react-cookies';
import { filterApiResponse } from '../../../sharedfiles/helper';

class MultiViewComponentStore {
  constructor(props) {
    extendObservable(this, {
      sportName: "",
      sportId: "",
      accordionOpenIndex: [],
      data: [],
      showErrorMsg: false,
      showLoader: false,
      mounted: true,
      eventPL: [],
      marchOddsPL: {},
      marchOdds1PL: {},
      appStore: {}
    });
  }
  /**
  * this method is used to open the accordion.
  * @param {Number} index- contains index to open the accordion.
  */
  openAccordion(index) {
    if (index >= 0) {
      var eventIndex = this.accordionOpenIndex.indexOf(index);
      if (eventIndex === -1) {
        this.accordionOpenIndex.push(index);
      } else {
        this.accordionOpenIndex.splice(eventIndex, 1);
      }
    }
  }
  /**
  * This method is used to get the multi market data.
  */
  getData(val) {
    this.showLoader = val ? false : true;
    this.showErrorMsg = false;
    var token = "";
    var cookeTocken = cookie.load('UTocken');
    if (cookeTocken && cookeTocken.length > 0) {
      token = cookeTocken;
    }
    apiCaller(multiMarket.getEvents + "?userToken=" + token, 'GET').then(response => {
      this.showLoader = false;
      if (response.data && response.data.hasOwnProperty('multiMarkets') &&
        response.data.multiMarkets && response.data.multiMarkets.length > 0) {
        if (this.data.length > 0) {
          const oldData = this.data;
          const updatedData = filterApiResponse(this.data, response.data.multiMarkets, "eventId");
          updatedData.forEach((ud) => {
            ud.showTab = "";
            oldData.forEach((od) => {

              if (od?.eventId === ud?.eventId) {
                ud.showTab = od.showTab;
              }

            })
          })
          this.data = updatedData

        }
        else {
          this.data = this.getFilteredResponse(response.data.multiMarkets);

        }
      } else {
        this.showErrorMsg = true;
        this.data = [];
      }
    }).catch(ex => {

    });
  }
  updateData() {
    this.getData();
  }

  getFilteredResponse(data) {
    const goals = data.filter(item => item.goals.length > 0);
    const popular = data.filter(item => item.popular.length > 0);
    data.forEach((item) => {
      if (item?.popular?.length > 0 && !item.showTab) {
        item.showTab = 'popular'
        item.popular.forEach((item) => {
          item.outcomes = item.outcomes.filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i)
        })

      } else if (item?.goals?.length > 0 && !item.showTab) {
        item.showTab = 'goal'
      } else {
        if (!goals && !popular) {
          item.showTab = ''
        }
      }
    })
    return data
  }
  /**
  * this method is used to selected the tab
  */
  selectTab(tabName, index) {
    this.data[index].showTab = tabName;

    //this.showTab=tabName;
  }
  /**
  * This method is used to get the PL for market.
  */
  getMarketPL(eventId, marketId, providerId, subMarket) {
    var marketPL = {};
    for (var bet of this.appStore.betsProfitLoss) {
      if (eventId === bet.eventId && marketId === bet.marketId && providerId === bet.providerId && subMarket === bet.subMarket) {
        marketPL = bet
      }
    }
    return marketPL;
  }
  /**
  * This method is used to get the market profit loss.
  */
  getEventPL() {
    this.eventPL = [];
    for (var bet of this.appStore.betsProfitLoss) {
      if (this.eventId === bet.eventId) {
        this.eventPL.push(bet)
      }
    }
  }
}

export default MultiViewComponentStore;
