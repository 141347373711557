import React, { Component } from 'react';
import { observer } from 'mobx-react';
import cookie from 'react-cookies';
import DatePicker from 'react-datepicker';

import BalanceOverviewTable from '../balanceoverviewtable/BalanceOverviewTable';
import dataLoader from '../../../../assets/images/loader-orange.svg';
import ReactPaginate from 'react-paginate';
import BalanceOverviewTableStore from '../balanceoverviewtable/BalanceOverviewTableStore';

const balanceOverviewTableStore = new BalanceOverviewTableStore();
class AccountStatement extends Component {
  componentWillMount() {
    // this.props.accountStatementStore.pageNumber = 0;
    this.props.accountStatementStore.resetFields();
  }
  componentWillReceiveProps(nextProps) { }
  render() {
    const { accountStatementStore } = this.props;

    return (
      <div className="RDE-DB-accountStatements">
        <div className="space-5"></div>
        {!balanceOverviewTableStore.showEventDetails ? <div className="RDE-DB-myBets-filters">
          <div className="RDE-DB-myBets-filters__left">
            <span className="RDE-DB-myBets-filters__label">Period From :</span>
            <div className="RDE-DB-myBets-filters__datePicker">
              <DatePicker selected={accountStatementStore.fromDate}
                dateFormat="dd/MM/yyyy"
                onChange={accountStatementStore.selectFromDate.bind(accountStatementStore)}
                className="RDE-DB-datePickerInput"
                maxDate={new Date()} />

              <span className="RDE-DB-myBets-filters__label">To :</span>
              <DatePicker selected={accountStatementStore.toDate}
                selectsEnd
                dateFormat="dd/MM/yyyy"
                onChange={accountStatementStore.selectToDate.bind(accountStatementStore)}
                className="RDE-DB-datePickerInput"
                maxDate={new Date()} />
              <span className="RDE-DB-datePickerErrorMessage">{accountStatementStore.dateErrMsg}</span>
            </div>
            <button className="btn RDE-btn-primary RDE-btn-small RDE-btn-auto" onClick={accountStatementStore.getSearchData.bind(accountStatementStore)}>Search</button>
          </div>
          <div className="RDE-DB-myBets-filters__right">

          </div>
        </div> : ""}
        {accountStatementStore.showLoader ? <div className="RDE-loader">
          <img src={dataLoader} alt="loading...!" />
        </div> : <>
          <div className="RDE-DB-totalValue">
            <div className="col-6">
              <span><b>Total Balance: PH</b>
                <span className={"RDE-DB-txtHighlight " + (accountStatementStore.balance > 0 ? "RDE-DB-profit" : "RDE-DB-loss")}><b>({Number(accountStatementStore.balance).toFixed(2)})</b></span></span>
            </div>
            <div className="col-6 text-right">
            </div>
          </div>
          <BalanceOverviewTable data={accountStatementStore.data} component="AS" balanceOverviewTableStore={balanceOverviewTableStore} balance={accountStatementStore.balance} />
          {accountStatementStore.showErrorMsg ? <div className="RDE-noDataMessageBox">
            Currently data is not available.
          </div> : ''}
        </>
        }
        {accountStatementStore.showPagination && <div className="RDE-DB-pagination">
          <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            // breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={accountStatementStore.totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={1}
            onPageChange={accountStatementStore.handlePageChange.bind(accountStatementStore)}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />
        </div>}
      </div>
    )
  }
}
export default observer(AccountStatement);
