import { extendObservable } from "mobx";
import cookie from 'react-cookies';

import { profile } from "../../../../sharedfiles/EndpointConfig";
import { apiCaller } from '../../../../sharedfiles/apiStore';
import { utcToTimeZone } from '../../../../sharedfiles/helper';

class AccountStatementStore {
  constructor(props) {
    extendObservable(this, {
      data: [],
      showErrorMsg: false,
      showLoader: false,
      itemsPerPage: 10,
      totalPages: 0,
      pageNumber: 0,
      fromDate: new Date(),
      toDate: new Date(),
      dateErrMsg: "",
      balance: 0,
      showPagination: false,
    });
  }

  /**
  * This method is used to clear the error messages and reset the values on itial Load.
  *
  * */
  resetFields() {
    this.dateErrMsg = '';
    this.fromDate = new Date();
    this.toDate = new Date();
    this.pageNumber = 0;
    this.getAccountStatement();
  }
  /**
   * This method is used to get the balance overview data
   *
   */
  getAccountStatement() {
    this.totalPages = 0;
    this.data = [];
    this.showErrorMsg = false;

    var fromDate = new Date(this.fromDate);
    var toDate = new Date(this.toDate);
    var fDate = fromDate.getFullYear() + "-" + (fromDate.getMonth() + 1) + "-" + fromDate.getDate();
    var tDate = toDate.getFullYear() + "-" + (toDate.getMonth() + 1) + "-" + toDate.getDate();
    if (new Date(fDate) > new Date(tDate)) {
      this.dateErrMsg = "From date should less than to date";
      this.showErrorMsg = true
      return;
    }
    var token = "";
    this.showLoader = true;
    var cookeTocken = cookie.load('UTocken');
    if (cookeTocken && cookeTocken.length > 0) {
      token = cookeTocken;
    }
    apiCaller(profile.betBalanceOverview + "?userToken=" + token + "&transactionType=as&fromDate=" + fDate + "&toDate=" + tDate + "&sportId=0&pageNumber=" + this.pageNumber + "&pageSize=" + this.itemsPerPage, 'GET').then(response => {
      this.showLoader = false;
      if (response && response.data && response.data.hasOwnProperty('userBalance')) {
        this.balance = response.data.userBalance;
      }
      if (response && response.data && response.data.hasOwnProperty('userTransList') &&
        response.data.userTransList && response.data.userTransList.length > 0) {
        this.showPagination = true;
        this.totalPages = Math.ceil(response.data.userTransactionsCount / this.itemsPerPage);
        //this.data = response.data.userTransList;
        var resdata = response.data.userTransList;
        for (var i = 0; i < resdata.length; i++) {
          //resdata[i]['transactionDate'] = 
          resdata[i]['transactionDate1'] = resdata[i]['transactionDate'];
          var fdate = utcToTimeZone(resdata[i]['transactionDate']);
          resdata[i]['transactionDate'] = fdate.transactionDate;
          resdata[i]['date'] = fdate.date;
          resdata[i]['time'] = fdate.time;
        }
        this.data = resdata;
        // this.data = data.reduce(function (r, a) {
        //   r[a.date] = r[a.date] || [];
        //   r[a.date].push(a);
        //   return r;
        // }, Object.create(null));
      } else {
        this.showErrorMsg = true;
        this.showPagination = false;
      }
    });
  }
  handlePageChange(pager) {
    //debugger;
    //alert(pageNumber);
    this.pageNumber = pager.selected;
    this.getAccountStatement();
  }
  /**
  * This method is called when user selects the from date.
  */
  selectFromDate(selectedDate) {
    this.fromDate = new Date(selectedDate);
    this.dateErrMsg = "";
  }
  /**
  * This method is called when user selects the to date.
  */
  selectToDate(selectedDate) {
    this.toDate = new Date(selectedDate);
    this.dateErrMsg = "";
  }

  getSearchData() {
    this.showPagination = false;
    this.pageNumber = 0;
    this.getAccountStatement();
  }
}

export default AccountStatementStore;
