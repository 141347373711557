import React, { Component } from 'react';
import { extendObservable } from "mobx";
import { observer } from 'mobx-react';
import OddsComponent from '../oddscomponent/OddsComponent';
import { formatDate } from '../../sharedfiles/helper';
import fancyIcon from '../../assets/images/fancy.svg';
import { apiCaller } from '../../sharedfiles/apiStore';
import { masterPage } from "../../sharedfiles/EndpointConfig";

class EventComponent extends Component {
  constructor(props) {
    super(props)
    extendObservable(this, {
      updatedOdds: {},
      isMounted: true,
      oddsUpdated: false,
      showOdds: false
    });
  }
  componentWillMount() {
    this.props.eventComponentStore.appStore = this.props.appStore;
    this.props.appStore.currentPath = "eventview";
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.event.isBlocked !== nextProps.event.isBlocked) {

    }
    if (this.props.event.isSuspended !== nextProps.event.isSuspended) {

    }
    if (this.props.betSlipStatusChange !== nextProps.betSlipStatusChange) {
      // console.log("betslip status changed======Event component")
    }
  }
  componentWillUnmount() {
    this.isMounted = false;
  }
  componentDidMount() {
    var self = this;
    setTimeout(function reCallService() {
      if (self.isMounted) {
        self.getOdds(self);
        setTimeout(reCallService, 1000);
      }
    }, 1000);
  }
  getOdds() {
    const self = this;
    //+this.props.clMarketId
    this.testCounter = this.testCounter + 1
    apiCaller(masterPage.getUpdatedOdds + "?marketId=" + this.props.event.markets[0].clMarketId, 'GET').then(response => {
      if (response && response.data) {
        this.updatedOdds = response.data;
        this.props.appStore.betSlipStore.updateBetSlipOdds(response.data);
        this.showOdds = true;
        this.oddsUpdated = !this.oddsUpdated;
        // if(market.marketId === self.props.clMarketId){
        //   for(var outcome of market.runners){
        //     if(outcome.selectionId === Number(self.props.outcome.clOutcomeId)){
        //       var changedBackOdds = 0;
        //       var backOddsIndex=0
        //       for(var backSelection of outcome.ex.availableToBack){
        //         if(backOddsIndex<3){
        //           if(backSelection.price > changedBackOdds){
        //             changedBackOdds = backSelection.price
        //           }
        //         }
        //         backOddsIndex++;
        //       }
        //       var changedLayOdds = 0;
        //       var layOddsIndex=0
        //       for(var laySelection of outcome.ex.availableToLay){
        //         if(layOddsIndex<3){
        //           if(layOddsIndex === 0){
        //             changedLayOdds = laySelection.price
        //           }else if(laySelection.price < changedLayOdds){
        //             changedLayOdds = laySelection.price
        //           }
        //         }
        //         layOddsIndex++;
        //       }
        //       if(changedBackOdds>1){
        //         self.checkOddsUpdate(self.props.outcome.backOdds,changedBackOdds,'BO');
        //         self.props.outcome.backOdds = changedBackOdds;
        //       }else{
        //         self.props.outcome.backOdds = '-';
        //       }
        //       if(changedLayOdds>1){
        //         self.checkOddsUpdate(self.props.outcome.layOdds,changedLayOdds,'LO');
        //         self.props.outcome.layOdds = changedLayOdds;
        //       }else{
        //         self.props.outcome.layOdds = '-';
        //       }
        //     }
        //   }
        // }
      }
    });

  }
  /**
  * This method is used to highlight the pin
  */
  pinSuccess() {
    this.props.event.isPinned = !this.props.event.isPinned;
  }
  navigateEventView() {
    this.props.appStore.sportId = this.props.sportId;
    this.props.appStore.sportName = this.props.sportName;
    this.props.appStore.eventViewId = this.props.event.id;
    this.props.history.push('/sports/' + this.props.sportName + '/' + this.props.sportId + '/event/' + this.props.event.id);
  }
  render() {
    const { event,
      eventComponentStore,
      appStore } = this.props;
    //event.kickOffTime
    // console.log("event===",event)
    return (
      <div className="RDE-singleLineEventItem">
        <div className="RDE-singleLineEventItem__eventDetails">
          <div className="RDE-singleLineEventItem__scoreAndKickOff">
            <div>
              {event.isLive ? <div className="RDE-singleLineEventItem__liveScore">Live</div>
                : formatDate(event.kickOffTime, this.props.componentType)}
            </div>
            <div className={"RDE-singleLineEventItem__pin" + (event.isPinned ? " active" : "")} onClick={eventComponentStore.savePin.bind(eventComponentStore, event.id, event.isPinned, this.props)}>
              <i className="RDE-icon-pin"></i>
            </div>
          </div>
          <div className="RDE-singleLineEventItem__fixtureDetails" onClick={this.navigateEventView.bind(this)}>
            <div className="RDE-singleLineEventItem__fixture">{event.homeTeamName} v {event.awayTeamName}
            </div>
            {event.isFancy ? <div className="RDE-singleLineEventItem__fancy">
              <img src={fancyIcon} alt="Fancy" />
            </div> : ""}
          </div>
        </div>
        <div className="RDE-singleLineEventItem__outcomeDetails">
          <ul>
            {
              event.markets && event.markets.length > 0 && event.markets[0].outcomes.map((outcome, OCIndex) => {
                return (
                  <OddsComponent key={"EC_OC_" + event.id + '_' + OCIndex} outcome={outcome} eventSuspended={event.isSuspended}
                    marketSuspended={event.markets[0].isSuspended}
                    updatedOdds={this.updatedOdds}
                    oddsUpdated={this.oddsUpdated}
                    eventBlocked={event.isBlocked}
                    marketBlocked={event.markets[0].isBlocked}
                    eventId={event.id}
                    clEventId={event.clEventId}
                    marketId={event.markets[0].marketGroupId}
                    clMarketId={event.markets[0].clMarketId}
                    eventName={event.homeTeamName + " v " + event.awayTeamName}
                    isLive={event.hasOwnProperty('isLive') ? event.isLive : 0}
                    providerId={event.providerId}
                    kickOffTime={event.kickOffTime}
                    homeTeam={event.homeTeamName}
                    awayTeam={event.awayTeamName}
                    homeTeamId={event.homeTeamId}
                    awayTeamId={event.awayTeamId}
                    marketName={event.markets[0].marketName}
                    appStore={appStore}
                    maxStake={event.markets[0].maxStake}
                    minStake={event.markets[0].minStake}
                    showOdds={this.showOdds}
                    betSlipStatusChange={appStore.betSlipStatusChange} />
                )
              })
            }
          </ul>
        </div>
      </div>
    )
  }
}
export default observer(EventComponent);
