import React, { Component } from 'react';
import { observer } from 'mobx-react';
import dataLoader from '../../../../assets/images/loader-orange.svg';
class PlacedBetEventDetails extends Component {
    componentDidMount() {
        this.props.placedBetEventDetailsStore.component = this.props.component;
        this.props.placedBetEventDetailsStore.getDetails(this.props.selectedEvent);
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.selectedEvent !== nextProps.selectedEvent) {
            this.props.placedBetEventDetailsStore.getDetails(this.props.selectedEvent);
        }
    }
    handleEventClick() {
        this.props.balanceOverviewTableStore.showEventDetails = false;
    }
    render() {
        const { selectedEvent, component, placedBetEventDetailsStore, selectedSport } = this.props;
        return (
            <div>
                {!placedBetEventDetailsStore.showLoader && !placedBetEventDetailsStore.showErrMsg && 
                <div className="RDE-DB-accountStatement__marketSummary">
                    <div className="RDE-sportsPage-breadcrumb mt-5"><span onClick={() => this.handleEventClick()}>{component === "AS" ? "Account Satatement" : selectedSport.sportName}</span>&nbsp;/&nbsp;<span>{selectedEvent.eventName}</span></div>
                    <table className="RDE-DB-table-default">
                        <thead>
                            <tr>
                                <th>Placed</th>
                                <th>Selection</th>
                                <th>Bet ID</th>
                                <th>Type</th>
                                <th>Odds</th>
                                <th>Stake</th>
                                <th>Profit/Loss</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                placedBetEventDetailsStore.data.map(function (item, index) {
                                    return (<tr>
                                        <td>{item.transactionDate ? item.transactionDate1 : "-"}</td>
                                        <td>{item.selectName ? item.selectName : "-"}</td>
                                        <td>{item.betId ? item.betId : "-"}</td>
                                        <td>{item.oddType ? item.oddType : "-"}</td>
                                        <td>{item.odds ? item.odds : "-"}</td>
                                        <td>{item.betStake ? item.betStake : "-"}</td>
                                        <td><span className={"RDE-DB-txtHighlight " + (item.profitLossAmount > 0 ? "RDE-DB-profit" : "RDE-DB-loss")}>{item.profitLossAmount ? item.profitLossAmount : "-"}</span></td>
                                        <td><span className={"RDE-DB-txtHighlight " + (item.betStatus.toLowerCase() === "won" || item.betStatus.toLowerCase() === "win" ? "RDE-DB-profit" : "RDE-DB-loss")}>{item.betStatus ? item.betStatus : "-"}</span></td>
                                    </tr>)
                                })
                            }

                        </tbody>
                    </table>

                    <div className="space-5"></div>
                    {placedBetEventDetailsStore.data.length > 0 &&
                        <div className="grid-noGutter-right">
                            <div className="col-3 text-right">
                                <table className="table RDE-DB-table-marketSummary">
                                    <tbody>
                                        <tr>
                                            <td>Back subtotal:</td>
                                            <td>{placedBetEventDetailsStore.backSubTotal}</td>
                                        </tr>
                                        <tr>
                                            <td>Lay subtotal:</td>
                                            <td>{placedBetEventDetailsStore.laySubTotal}</td>
                                        </tr>
                                        <tr>
                                            <td>Market subtotal:</td>
                                            <td>{placedBetEventDetailsStore.marketSubTotal}</td>
                                        </tr>
                                        <tr>
                                            <td>Commission:</td>
                                            <td>{placedBetEventDetailsStore.commission}</td>
                                        </tr>
                                        <tr>
                                            <th>Net Market Total:</th>
                                            <th>{placedBetEventDetailsStore.netTotal}</th>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                </div>}
                {placedBetEventDetailsStore.showErrMsg ? <div className="RDE-noDataMessageBox">
                    Currently data is not available for your selection.
                </div> : ''}
                {placedBetEventDetailsStore.showLoader ? <div className="RDE-loader">
                    <img src={dataLoader} alt="loading...!" />
                </div> : ''}
            </div>
        );
    }
}

export default observer(PlacedBetEventDetails);