import React, { Component } from 'react';
import { observer } from 'mobx-react';
import cookie from 'react-cookies';
import { Collapse } from "react-bootstrap";

import BetSlipEventComponent from './betslipeventcomponent/BetSlipEventComponent';
import BetSlipEventComponentStore from './betslipeventcomponent/BetSlipEventComponentStore'
import dataLoader from '../../assets/images/loader-orange.svg';
import { openBetsFormatDate } from '../../sharedfiles/helper'
import { Dropdown, DropdownButton } from "react-bootstrap";
import Sticky from 'react-sticky-el';

const betSlipEventComponentStore = new BetSlipEventComponentStore()
class BetSlipComponent extends Component {
  constructor(props) {
    super(props);
    this.getBetslipInfoClassName = this.getBetslipInfoClassName.bind(this);
  }
  componentWillMount() {
    // if(cookie.load("showOneClick") && cookie.load("showOneClick")==="true"){
    //      this.props.subHeaderStore.showOneClick = true;
    // }
    // if (this.props.appStore.isLoggedIn && localStorage && localStorage.getItem('stakeCoins')) {
    //   this.props.betslipComponentStore.stakeCoins = JSON.parse(localStorage.getItem('stakeCoins')).coins;
    // } else {
    //   this.props.betslipComponentStore.stakeCoins = [
    //     { "coinValue": 10 },
    //     { "coinValue": 50 },
    //     { "coinValue": 100 },
    //     { "coinValue": 200 },
    //     { "coinValue": 300 },
    //     { "coinValue": 400 }
    //   ]
    // }
    this.props.betslipComponentStore.appStore = this.props.appStore;
    // this.props.betslipComponentStore.getBets();
    this.props.betslipComponentStore.getOpenbets(this.props.appStore);
    this.props.betslipComponentStore.loadStakeCoins();
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.coinValueChange !== nextProps.coinValueChange) {
      this.props.betslipComponentStore.stakeCoins = JSON.parse(localStorage.getItem('stakeCoins')).coins;
    }
  }
  getBetslipInfoClassName() {
    let className = "RDE-betslip-infoMsg ";

    if (this.props.betslipComponentStore.placedBetInfo.isSingleBet) {
      let type = this.props.betslipComponentStore.placedBetInfo.type;
      let isMatched = this.props.betslipComponentStore.placedBetInfo.isMatched;
      let msgType = this.props.betslipComponentStore.msgType;
      if (msgType === "success") {
        if (type == "B" && isMatched) {
          className += "RDE-success";
        }
        else if (type === "L" && isMatched) {
          className += "RDE-lay";
        }
        else if (!isMatched) {
          className += "RDE-error";
        }
      }
      else {
        className += "RDE-error";
      }
    } else {
      if (this.props.betslipComponentStore.msgType !== "error") {
        className += "RDE-success";
      }
    }

    return className;
  }
  naviGateTOEventView(appStore) {
    this.props.history.push('/sports/' + appStore.sportName + '/' + appStore.sportId + '/event/' + appStore.eventViewId);
  }
  onToggleSettings() { }
  /**
      * 
      * @param {object holds the category information} categry 
      * @param {string holds the name of the category} categoryName 
      */
  renderCategory(categry, categoryName) {
    let category = '';
    if (categry && categry.bets && categry.bets.length > 0) {
      if (categoryName === "matchedBets") {
        category = <div className="RDE-openbets-categoryHeader"> Matched </div>;
      }
      else if (categoryName === "unMatchedBets") {
        category = <div className="RDE-openbets-categoryHeader"> UnMatched </div>;
      }
    }
    return category;
  }
  render() {
    var self = this
    const { betslipComponentStore, appStore } = this.props;
    return (
      <Sticky>
        <div className="RDE-betslip">
          <div className="RDE-betslip-header">
            <ul>
              <li className={betslipComponentStore.showTab === 'betslip' ? "active" : ''} onClick={betslipComponentStore.selectTab.bind(betslipComponentStore, 'betslip', appStore)}>Betslip</li>
              <li className={betslipComponentStore.showTab === 'openbets' ? "active" : ''} onClick={betslipComponentStore.selectTab.bind(betslipComponentStore, 'openbets', appStore)}>Open Bets</li>
            </ul>
            {/* <div className="RDE-betslip-header__txt"></div> */}
            {/* {(betslipComponentStore.betSlipItems.layBets.length > 0 ||
              betslipComponentStore.betSlipItems.backBets.length > 0) &&
              !betslipComponentStore.showLoader && betslipComponentStore.showTab === 'betslip' ?
              <div className="RDE-betslip-header__clearAll"
                onClick={betslipComponentStore.clearBetslip.bind(betslipComponentStore)}>Clear All</div> : ""} */}
          </div>
          {betslipComponentStore.showTab === 'betslip' ? !betslipComponentStore.showLoader ?
            <>{betslipComponentStore.msgInfo.length > 0 ? <div className={this.getBetslipInfoClassName()}
            // {betslipComponentStore.msgType === "error" ?
            // "RDE-betslip-infoMsg " : "RDE-betslip-infoMsg RDE-success"}
            >{betslipComponentStore.msgInfo}
              {betslipComponentStore.msgType === "success" && betslipComponentStore.placedBetInfo.isSingleBet && <div className="RDE-betslip-infoMsg__oddsStakeInfo">
                <div><span>&nbsp;Odds:</span><span>&nbsp;{betslipComponentStore.placedBetInfo.odds}</span></div>
                <div><span>&nbsp;Stake:</span><span>&nbsp;{betslipComponentStore.placedBetInfo.stake}</span></div>
              </div>}</div> : ""}
              {betslipComponentStore.showTab === 'betslip' ? betslipComponentStore.betSlipItems.backBets.length === 0 && betslipComponentStore.betSlipItems.layBets.length === 0 ?
                <div className="RDE-betslip-noBets">
                  <i class="RDE-icon-betslip"></i>
                  <div>
                    <b>Your Betslip is empty</b>
                    {/* <b>Please login to see your Open Bets</b> */}
                  </div>
                  <div className='space-2'></div>
                  <div>Please click on odds to add selections to the betslip.</div>
                </div> : !betslipComponentStore.confirmBets ?
                  <div className="RDE-betslip-content">
                    {betslipComponentStore.betSlipItems.backBets.length > 0 ? <div className="RDE-betslip__backOdds-header">Back (Bet For)</div> : ""}
                    {
                      betslipComponentStore.betSlipItems.backBets.map((item, index) => {
                        if (Object.keys(item).length > 0) {
                          return <BetSlipEventComponent key={'betslipItem_' + index} betSlipEventComponentStore={betSlipEventComponentStore}
                            appStore={appStore}
                            selection={item}
                            odds={item.userOdds}
                            stakeCoins={betslipComponentStore.stakeCoins}
                            betslipComponentStore={betslipComponentStore}
                            betType='BO'
                            betIndex={index} />
                        } else {
                          return '';
                        }

                      })
                    }
                    {betslipComponentStore.betSlipItems.layBets.length > 0 ? <div className="RDE-betslip__layOdds-header">Lay (Bet For)</div> : ""}
                    {
                      betslipComponentStore.betSlipItems.layBets.map((item, index) => {
                        if (Object.keys(item).length > 0) {
                          return <BetSlipEventComponent betSlipEventComponentStore={betSlipEventComponentStore}
                            appStore={appStore}
                            selection={item}
                            odds={item.userOdds}
                            stakeCoins={betslipComponentStore.stakeCoins}
                            betslipComponentStore={betslipComponentStore}
                            betType='LO'
                            betIndex={index} />
                        } else {
                          return '';
                        }

                      })
                    }
                    <div className="RDE-betslip-footer">
                      <div className="RDE-betslip__footer-infoHighlight text-left">
                        <div className="RDE-checkbox">
                          <input id="confirmbets" type="checkbox" name="Betslip2" checked={betslipComponentStore.showConfirm} onChange={betslipComponentStore.confirmBetSlip.bind(betslipComponentStore)} />
                          <label htmlFor="confirmbets"></label>
                          <span className="RDE-checkboxLabel">Please confirm your bets</span>
                        </div>
                      </div>
                      <div className="RDE-betslip__stakeReturnsBox">
                        <div className="RDE-betslip__stakeReturns">
                          <span className="RDE-betslip__stakeReturns-txt">Total Stake:</span>
                          <span className="RDE-betslip__stakeReturns-value">$ {betslipComponentStore.totalStake}</span>
                        </div>
                        <div className="RDE-betslip__stakeReturns">
                          <span className="RDE-betslip__stakeReturns-txt">Total {betslipComponentStore.totalTitle}:</span>
                          <span className="RDE-betslip__stakeReturns-value">$ {betslipComponentStore.totalReturns}</span>
                        </div>
                        {/* <div className="RDE-betslip__footer-infoHighlight">
                        <div className="RDE-checkbox">
                          <input id="acceptOdds" type="checkbox" name="Betslip2" checked={betslipComponentStore.acceptOddsChange} onChange={betslipComponentStore.changeAcceptOddsChange.bind(betslipComponentStore)} />
                          <label htmlFor="acceptOdds"></label>
                          <span className="RDE-checkboxLabel">Accept Odds Change</span>
                        </div>
                      </div> */}
                        <div className="RDE-betslip__placeBet text-center">
                          <button className={"btn RDE-btn-secondary RDE-btn-large RDE-btnPlaceBet" + (!betslipComponentStore.isValidBets ? " RDE-btnOddsDisabled" : "")} onClick={betslipComponentStore.placeBet.bind(betslipComponentStore)}>
                            Place Bet
                          </button>
                        </div>
                      </div>
                    </div>
                  </div> :
                  <div className="RDE-betslip-content">
                    {betslipComponentStore.betSlipItems.backBets.length > 0 ? <div className="RDE-betslip__backOdds-header">Back (Bet For)</div> : ""}
                    {
                      betslipComponentStore.betSlipItems.backBets.map((item, index) => {
                        return <div className="RDE-betslipConfirmationBox">
                          <div className="RDE-betslipConfirmationBox__content">
                            <div className="RDE-betslipConfirmationBox__matchDetails">
                              <div className="RDE-betslipConfirmationBox__left">
                                <div className="RDE-betslipConfirmationBox__fixture truncate">{item.eventName}</div>
                                <div className="RDE-betslipConfirmationBox__marketOutcome">
                                  <span>{item.marketName} - </span>
                                  <span className="RDE-betslipConfirmationBox__outcome">{item.selectionName}</span>
                                </div>
                              </div>
                              <div className="RDE-betslipConfirmationBox__right">
                                {item.userOdds}
                              </div>
                            </div>
                            <div className="RDE-betslipConfirmationBox__stakeReturns">
                              <div className="RDE-betslipConfirmationBox__stake">
                                <span>Stake: </span>
                                <span className="RDE-betslipConfirmationBox__stakeValue">{item.userStake}</span>
                              </div>
                              <div className="RDE-betslipConfirmationBox__returns text-right">
                                <span>Profit: </span>
                                <span className="RDE-betslipConfirmationBox__returnsValue">{(item.returns - item.userStake).toFixed(2)}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      })
                    }
                    {betslipComponentStore.betSlipItems.layBets.length > 0 ? <div className="RDE-betslip__layOdds-header">Lay (Bet For)</div> : ""}
                    {
                      betslipComponentStore.betSlipItems.layBets.map((item, index) => {
                        return <div className="RDE-betslipConfirmationBox">
                          <div className="RDE-betslipConfirmationBox__content">
                            <div className="RDE-betslipConfirmationBox__matchDetails">
                              <div className="RDE-betslipConfirmationBox__left">
                                <div className="RDE-betslipConfirmationBox__fixture truncate">{item.eventName}</div>
                                <div className="RDE-betslipConfirmationBox__marketOutcome">
                                  <span>{item.marketName} - </span>
                                  <span className="RDE-betslipConfirmationBox__outcome">{item.selectionName}</span>
                                </div>
                              </div>
                              <div className="RDE-betslipConfirmationBox__right">
                                {item.userOdds}
                              </div>
                            </div>
                            <div className="RDE-betslipConfirmationBox__stakeReturns">
                              <div className="RDE-betslipConfirmationBox__stake">
                                <span>Stake: </span>
                                <span className="RDE-betslipConfirmationBox__stakeValue">{item.userStake}</span>
                              </div>
                              <div className="RDE-betslipConfirmationBox__returns text-right">
                                <span>Liability: </span>
                                <span className="RDE-betslipConfirmationBox__returnsValue">{(item.returns - item.userStake).toFixed(2)}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      })
                    }
                    <div className="RDE-betslip-footer">
                      <div className="RDE-betslip__stakeReturnsBox">
                        <div className="text-center">
                          A commission is charged on your net profit <br /> from each market.
                        </div>
                        <div className="space-5"></div>
                        <div className="RDE-betslip__footer-infoHighlight">
                          Please confirm your bets
                        </div>
                        <div className="RDE-betslip__placeBet text-center">
                          <div className="grid-middle-noGutter">
                            <div className="col-6 text-center">
                              <span className="RDE-betslip-backToEdit" onClick={betslipComponentStore.goBackToBetSlip.bind(betslipComponentStore)}>Back to Edit Bets</span>
                            </div>
                            <div className="col-6">
                              <button className="btn RDE-btn-secondary RDE-btn-large RDE-btnConfirmBets" onClick={betslipComponentStore.saveBets.bind(betslipComponentStore)}>Confirm Bets</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> : ""
              }

            </> : <div className="RDE-loader">
              <div className="text-center">
                <img src={dataLoader} alt="loading...!" />
                <div><strong>{betslipComponentStore.countDownText} {betslipComponentStore.countDown > 0 ? betslipComponentStore.countDown + " sec" : ""}</strong></div>
              </div>
            </div>
            : ""}
          {betslipComponentStore.showTab === 'openbets' ? betslipComponentStore.showOpenBetLoader ? <div className="RDE-loader">
            <div className="text-center">
              <img src={dataLoader} alt="loading...!" />
            </div>
          </div>
            :
            <>
              {betslipComponentStore.msgInfo.length > 0 ? <div className={this.getBetslipInfoClassName()}
              // {betslipComponentStore.msgType === "error" ?
              // "RDE-betslip-infoMsg " : "RDE-betslip-infoMsg RDE-success"}
              >{betslipComponentStore.msgInfo}</div> : ""}

              {betslipComponentStore.openbetsData.length <= 0 ? <div className="RDE-betslip-noBets">
                <div>
                  <b>Open bets are not available for you</b>
                  {/* <b>Please login to see your Open Bets</b> */}
                </div>
                <div>Please place a bet for open bets.</div>
              </div> :
                <div className="RDE-openbets-content">
                  <Dropdown className="RDE-DB-dropdownDefault">
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      {appStore.selectedOpenBetsEvent.eventName}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <ul>
                        {betslipComponentStore.openbetsData.map((event, eventIndex) => {
                          //if (event.eventId != appStore.selectedOpenBetsEvent.eventId) {
                          return <li key={"openbets_events_" + eventIndex}
                            onClick={betslipComponentStore.selectOpenbetsEvent.bind(betslipComponentStore, event, appStore, self)}>
                            <Dropdown.Item eventKey={event.eventId}>{event.eventName}</Dropdown.Item></li>
                          // }
                        })}
                      </ul>
                    </Dropdown.Menu>
                  </Dropdown>
                  {
                    betslipComponentStore.openbetsData.map((openBet) => {
                     // console.log("openbets step1===", appStore.selectedOpenBetsEvent.eventName);
                      if (openBet.eventId === appStore.selectedOpenBetsEvent.eventId && openBet.eventName === appStore.selectedOpenBetsEvent.eventName) {
                       // console.log("openbets step2===", appStore.selectedOpenBetsEvent.eventName);
                        return <>
                          {/* {event.matchedBets && event.matchedBets.backBets && event.matchedBets.backBets.length > 0 ||
                          event.matchedBets && event.matchedBets.layBets && event.matchedBets.layBets.length > 0 ?
                          <div className="RDE-openbets-categoryHeader">
                            Matched
                          </div> : ""} */}
                          {/* {event.matchedBets && event.matchedBets.backBets && event.matchedBets.backBets.length > 0 ? <div className="RDE-betslip__backOdds-header">Back (Bet For)</div> : ''} */}
                          {/* {event.matchedBets && event.matchedBets.backBets.map((bet, betIndex) => {
                          return <div className="RDE-betslipConfirmationBox" key={"matched_back_" + event.eventId + "_" + bet.betId}> */}
                          {/* <div className="RDE-betslipConfirmationBox__content">
                               <div className="RDE-openbets-refNumberDetails">
                                <span>Ref: {bet.betId}</span>
                                <span className="text-right">{openBetsFormatDate(bet.betPlacedTime)}</span>
                              </div>
                              <div className="RDE-hr"></div> 
                              <div className="RDE-betslipConfirmationBox__matchDetails">
                                <div className="RDE-betslipConfirmationBox__left">
                                  <div className="RDE-betslipConfirmationBox__marketOutcome">
                                    <span>{bet.marketName} - </span>
                                    <span className="RDE-betslipConfirmationBox__outcome">{bet.outcomeName}</span>
                                  </div>
                                </div>
                                <div className="RDE-betslipConfirmationBox__right">
                                  {bet.odds}
                                </div>
                              </div>
                              <div className="RDE-betslipConfirmationBox__stakeReturns">
                                <div className="RDE-betslipConfirmationBox__stake">
                                  <span>Stake: </span>
                                  <span className="RDE-betslipConfirmationBox__stakeValue">{bet.stake}</span>
                                </div>
                                <div className="RDE-betslipConfirmationBox__returns text-right">
                                  <span>Profit: </span>
                                  <span className="RDE-betslipConfirmationBox__returnsValue">{(bet.returns - bet.stake).toFixed(2)}</span>
                                </div>
                              </div>
                            </div> */}

                          {/*<div class="RDE-openbets-singleBet__close"><i class="RDE-icon-close"></i></div>*/}
                          {/* </div> */}
                          {Object.keys(openBet).map(function (categoryName, categoryIndex) {
                            let category = openBet[categoryName];
                            if (category && typeof category === 'object' && category.constructor === Object
                              && Object.keys(category).length > 0) {
                              return (
                                <>
                                  {self.renderCategory(category, categoryName)}
                                  {((category && category.bets && category.bets.length > 0)) &&
                                    <table className="RDE-openbets-table">
                                      <thead>
                                        <tr>
                                          <th>Market Name</th>
                                          <th>Odds</th>
                                          <th>Stake</th>
                                          <th>Profit / Liability</th>
                                          <th></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {Object.keys(category).map(function (betTypeName, betTypeIndex) {
                                          let betData = category[betTypeName];
                                          if (Array.isArray(betData) && betData.length > 0) {
                                            return (
                                              betData.map((bet, betIndex) => {
                                                return (
                                                  <tr className={bet.oddType.toLowerCase() === "back" ? "RDE-openbets-backBet" : "RDE-openbets-layBet"}
                                                    key={"matched_back_" + bet.eventId + "_" + bet.betId}>
                                                    <td title={bet.marketName + "-" + bet.outcomeName}>{bet.marketName}-{bet.outcomeName}</td>
                                                    <td>{betslipComponentStore.editOpenBets && categoryName === "unMatchedBets" ? <div className="RDE-betslipBox__oddsBox">
                                                      <input readOnly={bet.isOddsEdited ? false : true} type="text" className="RDE-formControl" value={bet.changedOdds}
                                                        onChange={betslipComponentStore.oddsChanged.bind(betslipComponentStore, bet, bet.oddType.toLowerCase())}
                                                      />
                                                      <i className="RDE-icon-plus" onClick={betslipComponentStore.oddsInc.bind(betslipComponentStore, bet, bet.oddType.toLowerCase())}></i>
                                                      <i className="RDE-icon-minus" onClick={betslipComponentStore.oddsDec.bind(betslipComponentStore, bet, bet.oddType.toLowerCase())}></i>
                                                    </div> : bet.odds}</td>
                                                    <td>{betslipComponentStore.editOpenBets && categoryName === "unMatchedBets" ?
                                                      <input type="text" readOnly className="RDE-formControl" placeholder="Stake" value={bet.changedStake}
                                                        onChange={betslipComponentStore.stakeChanged.bind(betslipComponentStore, bet, bet.oddType.toLowerCase())}
                                                      /> : bet.stake}</td>
                                                    <td>{categoryName === "matchedBets" ? (bet.returns - bet.stake).toFixed(2) : (bet.changedReturns - bet.changedStake).toFixed(2)}</td>

                                                    <td>{betslipComponentStore.editOpenBets && categoryName === "unMatchedBets" ?
                                                      <div className="RDE-openbets-closeIcon" onClick={() => betslipComponentStore.cancelBets(bet.betId, appStore)} >
                                                        <i className="RDE-icon-close"></i></div> : ""}</td>

                                                  </tr>
                                                )
                                              })
                                            )
                                          }
                                        })}
                                      </tbody>
                                    </table>
                                  }
                                </>
                              )
                            }
                          })}
                          {/* {openBet.matchedBets && openBet.matchedBets.backBets && openBet.matchedBets.backBets.length > 0 ||
                          openBet.matchedBets && openBet.matchedBets.layBets && openBet.matchedBets.layBets.length > 0 ?
                          <div className="RDE-openbets-categoryHeader">
                            Matched
                          </div> : ""}
                        <div className="RDE-openbets-matchUnmatchedMatches">
                          
                          {openBet.matchedBets && (openBet.matchedBets.backBets.length > 0 || openBet.matchedBets.layBets.length > 0) &&
                            <table className="RDE-openbets-table">
                            <thead>
                              <tr>
                                <th>Market Name</th>
                                <th>Odds</th>
                                <th>Stake</th>
                                <th>Profit</th>
                              </tr>
                            </thead>
                            <tbody>
                              
                              {openBet.matchedBets && openBet.matchedBets.backBets.map((bet, betIndex) => {
                                return <tr className="RDE-openbets-backBet" key={"matched_back_" + openBet.eventId + "_" + bet.betId}>
                                  <td>{bet.marketName}-{bet.outcomeName}</td>
                                  <td>{bet.odds}</td>
                                  <td>{bet.stake}</td>
                                  <td>{(bet.returns - bet.stake).toFixed(2)}</td>
                                </tr>
                              })}
                               {openBet.matchedBets && openBet.matchedBets.layBets.map((bet, betIndex) => {
                                return <tr className="RDE-openbets-layBet" key={"matched_back_" + openBet.eventId + "_" + bet.betId}>
                                  <td>{bet.marketName}-{bet.outcomeName}</td>
                                  <td>{bet.odds}</td>
                                  <td>{bet.stake}</td>
                                  <td>{(bet.returns - bet.stake).toFixed(2)}</td>
                                </tr>
                              })}

                            </tbody>
                          </table>
                        
                          }
                        </div> */}

                          {/* {event.matchedBets && event.matchedBets.layBets && event.matchedBets.layBets.length > 0 ? <div className="RDE-betslip__layOdds-header">Lay (Bet For)</div> : ''} */}
                          {/* {event.matchedBets && event.matchedBets.layBets.map((bet, betIndex) => {
                          return <div className="RDE-betslipConfirmationBox" key={"matched_back_" + event.eventId + "_" + bet.betId}>
                            <div className="RDE-betslipConfirmationBox__content">
                              <div className="RDE-openbets-refNumberDetails">
                                <span>Ref: {bet.betId}</span>
                                <span className="text-right">{openBetsFormatDate(bet.betPlacedTime)}</span>
                              </div>
                              <div className="RDE-hr"></div>
                              <div className="RDE-betslipConfirmationBox__matchDetails">
                                <div className="RDE-betslipConfirmationBox__left">
                                  <div className="RDE-betslipConfirmationBox__marketOutcome">
                                    <span>{bet.marketName} - </span>
                                    <span className="RDE-betslipConfirmationBox__outcome">{bet.outcomeName}</span>
                                  </div>
                                </div>
                                <div className="RDE-betslipConfirmationBox__right">
                                  {bet.odds}
                                </div>
                              </div>
                              <div className="RDE-betslipConfirmationBox__stakeReturns">
                                <div className="RDE-betslipConfirmationBox__stake">
                                  <span>Stake: </span>
                                  <span className="RDE-betslipConfirmationBox__stakeValue">{bet.stake}</span>
                                </div>
                                <div className="RDE-betslipConfirmationBox__returns text-right">
                                  <span>Liability: </span>
                                  <span className="RDE-betslipConfirmationBox__returnsValue">{(bet.returns - bet.stake).toFixed(2)}</span>
                                </div>
                              </div>
                            </div>
                            <div class="RDE-openbets-singleBet__close"><i class="RDE-icon-close"></i></div>
                          </div>
                        })} */}
                          {/****unMatched****/}
                          {/* {event.unMatchedBets && event.unMatchedBets.backBets && event.unMatchedBets.backBets.length > 0 ||
                          event.unMatchedBets && event.unMatchedBets.layBets && event.unMatchedBets.layBets.length > 0 ?
                          <div className="RDE-openbets-categoryHeader">
                            Unmatched
                                         </div> : ""} */}
                          {/* {event.unMatchedBets && event.unMatchedBets.backBets && event.unMatchedBets.backBets.length > 0 ? <div className="RDE-betslip__backOdds-header">Back (Bet For)</div> : ''} */}



                          {/* {event.unMatchedBets && event.unMatchedBets.backBets.map((bet, betIndex) => {
                          return <div className="RDE-betslipConfirmationBox" key={"matched_back_" + event.eventId + "_" + bet.betId}>
                            <div className="RDE-betslipConfirmationBox__content">
                              <div className="RDE-openbets-refNumberDetails">
                                <span>Ref: {bet.betId}</span>
                                <span className="text-right">{openBetsFormatDate(bet.betPlacedTime)}</span>
                              </div>
                              <div className="RDE-hr"></div>
                              <div className="RDE-betslipConfirmationBox__matchDetails">
                                <div className="RDE-betslipConfirmationBox__left">
                                  <div className="RDE-betslipConfirmationBox__marketOutcome">
                                    <span>{bet.marketName} - </span>
                                    <span className="RDE-betslipConfirmationBox__outcome">{bet.outcomeName}</span>
                                  </div>
                                </div>

                                <div className="RDE-betslipConfirmationBox__right">
                                  {betslipComponentStore.editOpenBets ?
                                    <div className="RDE-betslipBox__oddsBox">
                                      <input readOnly={betslipComponentStore.isOddsEdited?false:true} type="text" className="RDE-formControl" value={bet.changedOdds}
                                        onChange={betslipComponentStore.oddsChanged.bind(betslipComponentStore, bet)}
                                       />
                                      <i className="RDE-icon-up-arrow" onClick={betslipComponentStore.oddsInc.bind(betslipComponentStore, bet)}></i>
                                      <i className="RDE-icon-down-arrow" onClick={betslipComponentStore.oddsDec.bind(betslipComponentStore, bet)}></i>
                                    </div> :
                                    <>{bet.odds}</>}
                                </div>
                              </div>
                              <div className="RDE-betslipConfirmationBox__stakeReturns">
                                <div className="RDE-betslipConfirmationBox__stake">
                                  {betslipComponentStore.editOpenBets ?
                                    <input type="text" readOnly={betslipComponentStore.isStakeEdited?false:true} className="RDE-formControl" placeholder="Stake" value={bet.changedStake}
                                      onChange={betslipComponentStore.stakeChanged.bind(betslipComponentStore, bet)}
                                     /> :
                                    <><span>Stake: </span>
                                      <span className="RDE-betslipConfirmationBox__stakeValue">{bet.stake}</span></>}
                                </div>
                                <div className="RDE-betslipConfirmationBox__returns text-right">
                                  <span>Profit: </span>
                                  <span className="RDE-betslipConfirmationBox__returnsValue">{betslipComponentStore.editOpenBets ? (bet.changedReturns-bet.changedStake).toFixed(2) : (bet.returns - bet.stake).toFixed(2)}</span>
                                </div>
                              </div>
                            </div>
                            <div className="RDE-openbets-singleBet__close" onClick={betslipComponentStore.cancelBets.bind(betslipComponentStore, bet.betId,appStore)}><i className="RDE-icon-close"></i></div>
                          </div>
                        })} */}


                          {/* {event.unMatchedBets && event.unMatchedBets.layBets && event.unMatchedBets.layBets.length > 0 ? <div className="RDE-betslip__layOdds-header">Lay (Bet For)</div> : ''} */}



                          {/* {event.unMatchedBets && event.unMatchedBets.layBets.map((bet, betIndex) => {
                          return <div className="RDE-betslipConfirmationBox" key={"matched_back_" + event.eventId + "_" + bet.betId}>
                            <div className="RDE-betslipConfirmationBox__content">
                              <div className="RDE-openbets-refNumberDetails">
                                <span>Ref: {bet.betId}</span>
                                <span className="text-right">{openBetsFormatDate(bet.betPlacedTime)}</span>
                              </div>
                              <div className="RDE-hr"></div>
                              <div className="RDE-betslipConfirmationBox__matchDetails">
                                <div className="RDE-betslipConfirmationBox__left">
                                  <div className="RDE-betslipConfirmationBox__marketOutcome">
                                    <span>{bet.marketName} - </span>
                                    <span className="RDE-betslipConfirmationBox__outcome">{bet.outcomeName}</span>
                                  </div>
                                </div>
                                <div className="RDE-betslipConfirmationBox__right">
                                  {betslipComponentStore.editOpenBets ?
                                    <div className="RDE-betslipBox__oddsBox">
                                      <input type="text" readOnly={betslipComponentStore.isOddsEdited?false:true} className="RDE-formControl" value={bet.changedOdds}
                                        onChange={betslipComponentStore.oddsChanged.bind(betslipComponentStore, bet)}  />
                                      <i className="RDE-icon-up-arrow" onClick={betslipComponentStore.oddsInc.bind(betslipComponentStore, bet)}></i>
                                      <i className="RDE-icon-down-arrow" onClick={betslipComponentStore.oddsDec.bind(betslipComponentStore, bet)}></i>
                                    </div> :
                                    <>{bet.odds}</>}
                                </div>
                              </div>
                              <div className="RDE-betslipConfirmationBox__stakeReturns">
                                <div className="RDE-betslipConfirmationBox__stake">
                                  {betslipComponentStore.editOpenBets ?
                                    <input type="text" readOnly={betslipComponentStore.isStakeEdited?false:true} className="RDE-formControl" placeholder="Stake" value={bet.changedStake}
                                      onChange={betslipComponentStore.stakeChanged.bind(betslipComponentStore, bet)}/> :
                                    <><span>Stake: </span>
                                      <span className="RDE-betslipConfirmationBox__stakeValue">{bet.stake}</span></>}
                                </div>
                                <div className="RDE-betslipConfirmationBox__returns text-right">
                                  <span>Liability: </span>
                                  <span className="RDE-betslipConfirmationBox__returnsValue">{betslipComponentStore.editOpenBets ? (bet.changedReturns-bet.changedStake).toFixed(2) : (bet.returns - bet.stake).toFixed(2)}</span>
                                </div>
                              </div>
                            </div>
                            <div className="RDE-openbets-singleBet__close" onClick={betslipComponentStore.cancelBets.bind(betslipComponentStore, bet.betId,appStore)}><i className="RDE-icon-close"></i></div>
                          </div>
                        })} */}


                          <div>
                            {betslipComponentStore.openBetsErr.length > 0 ? <div className="RDE-betslip-errorMsgBox">{betslipComponentStore.openBetsErr}</div> : ''}
                            {(openBet.unMatchedBets && openBet.unMatchedBets.bets && openBet.unMatchedBets.bets.length > 0) ? <div className="RDE-betslip__stakeReturnsBox">
                              {!betslipComponentStore.editOpenBets ? <button className="btn RDE-btn-primary RDE-btn-auto RDE-btn-md bg-red" onClick={betslipComponentStore.cancelAllBets.bind(betslipComponentStore, appStore)}>Cancel Bets</button> :
                                <button className="btn RDE-btn-primary RDE-btn-auto RDE-btn-md RDE-betslip-header__clearAll" onClick={betslipComponentStore.resetOpen.bind(betslipComponentStore)}>Reset</button>}
                              {!betslipComponentStore.editOpenBets ? <button className="btn RDE-btn-primary RDE-btn-auto RDE-btn-md" onClick={()=>betslipComponentStore.editOpenBet(openBet)}>Edit Bets</button> :
                                <button className="btn RDE-btn-secondary RDE-btn-auto RDE-btn-md RDE-betslip-header__clearAll" onClick={betslipComponentStore.updateOpenbets.bind(betslipComponentStore, appStore)}>Update Bets</button>}
                            </div> : ''}
                          </div>
                        </>
                      }

                    })
                  }

                </div>
              }
            </>

            : ""}
        </div>
      </Sticky>
    )
  }
}
export default observer(BetSlipComponent);
