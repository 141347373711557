import React, { Component } from 'react';
import { observer } from 'mobx-react';
import cookie from 'react-cookies';

class OneClickBet extends Component {
  componentWillMount() {
    this.props.oneClickBetStore.isEdit = false;
   // console.log(!cookie.load("oneConfirm"))
    if (cookie.load("oneConfirm") && cookie.load("oneConfirm") === 'false') {
      this.props.oneClickBetStore.showConfirmation = false;
    }
    this.props.oneClickBetStore.getOneClickValues();
  }
  render() {
    const { subHeaderStore, oneClickBetStore } = this.props;
    return (
      <div className="RDE-oneClickBet-popUp">
        <div className="RDE-oneClickBet">
          <span className="RDE-oneClickBet__txt">One Click Bet Stake</span>
          <ul className="RDE-oneClickBet__stakeList">
            {
              oneClickBetStore.stakes.map((stake, index) => {
                return (<li key={`oneclickbet${index}`}>
                  {!oneClickBetStore.isEdit ? <div onClick={oneClickBetStore.stakeSelected.bind(oneClickBetStore, stake)} className={!oneClickBetStore.isEdit && oneClickBetStore.selectedStake.coinId === stake.coinId ? "active RDE-oneClickBet__stakeList__item" : "RDE-oneClickBet__stakeList__item"}> <div className="RDE-skewTxt RDE-oneClickBet__stakeValue">{stake.coinValue}</div></div> :
                    <div className="RDE-oneClickBet__stakeList__item RDE-edit"><div className="RDE-skewTxt"><input type="text" className="RDE-formControl" onChange={oneClickBetStore.changeValue.bind(oneClickBetStore, stake.coinId)} value={stake.coinValue} /></div></div>}
                </li>)
              })
            }
          </ul>
          {oneClickBetStore.isEdit ? <button className="btn RDE-btnOneClick-edit" onClick={oneClickBetStore.updateCoins.bind(oneClickBetStore)}>Submit</button> :
            <button className="btn RDE-btnOneClick-edit" onClick={oneClickBetStore.clickEdit.bind(oneClickBetStore)}>Edit</button>}
          <div className="RDE-btnOneClick-close" onClick={subHeaderStore.toggleOneClick.bind(subHeaderStore)}><i className="RDE-icon-close"></i></div>
        </div>
        {oneClickBetStore.showConfirmation ? <div className="RDE-oneClickBet-infoMsg RDE-arrow">
          <div className="RDE-oneClickBet-infoMsg__header">
            One Click Bet ON
          </div>
          <div className="RDE-oneClickBet-infoMsg__content">
            <div className="">Stake selected will be placed immediately once you click on the market odds.</div>
            <div className="RDE-oneClickBet-infoMsg__attentionTxt">Attention: Back / Lay at your own risk</div>
          </div>
          <div className="RDE-oneClickBet-infoMsg__footer">
            <button className="btn RDE-btn-primary" onClick={oneClickBetStore.clickConfirmation.bind(oneClickBetStore)}>OK</button>
          </div>
        </div> : ""}
      </div>
    )
  }
}
export default observer(OneClickBet);
